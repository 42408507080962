import { Button, message } from "antd";
import { useEffect, useState } from "react";
import { API_organList, API_bindMyBindOrgan, API_reBindMyBindOrgan } from "@/service/common";
const ChooseOrgan = (props) => {
    const { type, invoiceOrgan } = props;
    const [organList, setOrganList] = useState([]);
    const [loading, setLoading] = useState(false)
    useEffect(e => {
        API_organList({ onlyvalid: true }).then((res) => {
            setOrganList(res.data);
        });
        console.log(props.invoiceOrgan)
    }, []);
    const choose = async (data) => {
        setLoading(true);
        await API_bindMyBindOrgan({ organid: data.organid, type: type }).then(
            (res) => {
                message.success("已申请");
                props.setShowChoose(false);
                setLoading(false);
            }
        ).catch(e => {
            setLoading(false);
        });
        // if(props.invoiceOrgan){
        //  await API_reBindMyBindOrgan({ organid: data.organid, type: type }).then(
        //      (res) => {
        //          message.success("已申请");
        //          props.setShowChoose(false);
        //          setLoading(false);
        //      }
        //  ).catch(e => {
        //      setLoading(false);
        //  });
        // }else{
        //     await API_bindMyBindOrgan({ organid: data.organid, type: type }).then(
        //         (res) => {
        //             message.success("已申请");
        //             props.setShowChoose(false);
        //             setLoading(false);
        //         }
        //     ).catch(e => {
        //         setLoading(false);
        //     });
        // }
       
    }
    return (
        <div className="organList-content flex-start">
            {organList.map(data => {
                return (
                    <div className="organ-item">
                        <div className="organ-title">{data.organName}</div>
                        <div className="organ-phone">联系电话:{data.phone}</div>
                        <div className="organ-btn">
                            <Button loading={loading} onClick={e => choose(data)} type="primary">
                                申请
                            </Button>
                        </div>
                    </div>
                );
            })}
        </div>
    )
}

export default ChooseOrgan;