import React, { useEffect } from "react";
import { API_getStatistics } from "../../../service/common";
import { DatePicker, Table } from "antd";
import { useState } from 'react';
import ReactECharts from 'echarts-for-react';
import dayjs from 'dayjs';
const InvoiceStatistics = (props) => {
  const { RangePicker } = DatePicker;
  const [startDate, setStartDate] = useState(dayjs().add(-1, 'year'));
  const [endDate, setEndDate] = useState(dayjs());
  const [chart1Loaded,setChart1Loaded]=useState(false)
  const [tableData,setTableData]=useState([])
 const [chartData,setChartData]=useState({
  title: {
    text: '发票各状态数量统计',
    left: 'center'
  },
  tooltip: {
    trigger: 'item'
  },
  legend: {
    orient: 'vertical',
    left: 'left'
  },
  series: [
    {
      name: 'Access From',
      type: 'pie',
      radius: '50%',
      data: [
        { value: 0, name: '未受理' },
        { value: 0, name: '已受理' },
        { value: 0, name: '已处置' },
        { value: 0, name: '已拒绝' }
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
});

const columns = [
  {
      title: "发票状态",
      dataIndex: "explain",
      key: "explain",
  },
  {
    title: "发票数量",
    dataIndex: "num",
    key: "num",
},
{
  title: "发票总金额",
  dataIndex: "money",
  key: "money",
},
{
  title: "发票总税额",
  dataIndex: "tax",
  key: "tax",
},
 ]
  useEffect((e) => {
    getData1([startDate,endDate]);
  }, []);

  useEffect(e=>{
    console.log(chartData)
  },[chartData])

  const getData1 = (date) => {
    setChart1Loaded(false)
    let reqData = {
      begin: date[0].format('YYYY-MM-DD')+" 00:00:00",
      end: date[1].format('YYYY-MM-DD')+" 23:59:59",
      organid: localStorage.getItem('organID'),
    };
    API_getStatistics(reqData).then(res=>{
      let list=res.data;
      setTableData(list)
      chartData.series[0].data[0].value=list[3].num;
      chartData.series[0].data[0].name='未受理:'+list[3].num;
      chartData.series[0].data[1].value=list[1].num;
      chartData.series[0].data[1].name='已受理:'+list[1].num;
      chartData.series[0].data[2].value=list[2].num;
      chartData.series[0].data[2].name='已处置:'+list[2].num;
      chartData.series[0].data[3].value=list[0].num;
      chartData.series[0].data[3].name='已拒绝:'+list[0].num;
      setChartData({...chartData});
      setChart1Loaded(true)
    });
  };
  return (
    <div className="custom_auto">
      <div className="custom_pack">
        <RangePicker
           defaultValue={[startDate,endDate]}
          format="YYYY-MM-DD"
          onChange={getData1}
        />
        <div style={{marginTop:"30px"}}>
          {chart1Loaded&&<ReactECharts
            option={chartData}
            style={{ height: "300px", width: "100%" }}
          />}
        </div>
        <div style={{marginTop:"30px"}}>
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={false}
          />
        </div>
      </div>
    </div>
  );
};

export default InvoiceStatistics;
