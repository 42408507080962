import './Tab.less'
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { CloseOutlined } from "@ant-design/icons"
import routerKey from '../config/routerKey'
import { useThrottle } from '../utils/utils'
export default function TabPage(props) {
    const location = useLocation();
    const navigate = useNavigate()
    const [tabList, setTabList] = useState([])
    const [tabActive, setTabActive] = useState()
    useEffect(() => {
        let { pathname } = location
        let tabIndex = tabList.findIndex(data => data.pathname === pathname)
        if (tabIndex === -1) {
            if (routerKey[pathname]) { // pathname对应 未配置参数不加入tab
                setTabList([...tabList, {
                    pathname: pathname,
                    title: routerKey[pathname]
                }])
                setTabActive(pathname)
            }
        } else {
            setTabActive(pathname)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])
    const onClickTabItem = (pathname) => {
        setTabActive(pathname)
        navigate(pathname)
    }
    const onRemoveTabItem = (pathname, index) => {
        tabList.splice(index, 1)
        setTabList([...tabList])
        console.log(tabList)
        let preLenght = tabList.length
        console.log(pathname, index, preLenght, tabActive)
        if (index) {
            let newPathname = ''
            if (index === preLenght) {
                newPathname = tabList[preLenght - 1].pathname
            } else {
                newPathname = tabList[index].pathname
            }
            setTabActive(newPathname)
            navigate(newPathname)
        }
    }
    const onListenMouse = useThrottle(() => {
        let tab = document.querySelector('.tab_main')
        tab.addEventListener('wheel', (e) => {
            e.preventDefault()
            tab.scrollLeft += e.deltaY
        })
    }, 100)
    return (
        <div className="tab_main" onMouseOver={() => onListenMouse()}>
            {
                tabList.map((data, index) => <div onClick={() => onClickTabItem(data.pathname)} className={tabActive === data.pathname ? 'tab_item_cked tab_item' : 'tab_item'} key={data.pathname}>
                    <span className="tab_item_label">{data.title}</span>
                    <div className='tab_item_icon'><CloseOutlined onClick={(e) => { e.stopPropagation(); onRemoveTabItem(data.pathname, index) }} style={{ fontSize: 12, color: '#343433' }} /></div>
                </div>)
            }
        </div>
    )
}