import React, { useEffect, useState } from "react";
import './antdCell.less'
import { Image } from 'antd';
import FileContent from "./fileContent";
const AntdCell = (props) => {
    const { dataSource, columns } = props;
    console.log(dataSource)
    return (
        <div className='flex-content antdCell-content'>
            {columns.map(data => {
                if (data.render) {
                    return (
                        <div className="flex" key={data.key}>
                            <div className="lable">{data.title}:</div>
                            <div className="value">
                                {data.render(dataSource[data.key], dataSource)}
                            </div>
                        </div>
                    );
                } else if (data.type == "text") {
                    return (
                        <div className='flex' key={data.key}>
                            <div className="lable">{data.title}:</div>
                            <div className="value">{dataSource[data.key]}</div>
                        </div>
                    )
                } else if (data.type == 'image') {
                    return (
                        <div className='flex' key={data.key}>
                            <div className="lable">{data.title}:</div>
                            <div className="value">
                                <Image width={100} height={100} src={dataSource[data.key]}></Image>
                            </div>
                        </div>
                    )
                } else if (data.type == 'file') {
                    return (
                        <div className='flex' key={data.key}>
                            <div className="lable">{data.title}:</div>
                            <div className="value">
                                <FileContent url={dataSource[data.key]}></FileContent>
                            </div>
                        </div>
                    )
                }else if (data.type == 'fileList') {
                    return (
                        <div className='flex-start' key={data.key}>
                            <div className="lable">{data.title}:</div>
                            <div className="value">
                                { console.log(dataSource,data.key,'row')}
                                {dataSource[data.key]&&dataSource[data.key].map(row=>{
                                   
                                    return <FileContent url={row}></FileContent>
                                })}
                            </div>
                        </div>
                    )
                }
            })}
        </div>
    )
}

export default AntdCell