import "./App.less";
import { HashRouter as Router, useRoutes } from "react-router-dom";
import { routes } from "./config/router";
import { Suspense } from "react";
import dayjs from 'dayjs';
import { AliveScope } from 'react-activation'
import 'dayjs/locale/zh-cn';

import { config as AmapReactConfig } from '@amap/amap-react';

AmapReactConfig.version = '2.0'; // 默认2.0，这里可以不修改
AmapReactConfig.key = '8b693679a8d5187f1eaa6bb0c38d80d5';
AmapReactConfig.plugins = [
  'AMap.ToolBar',
  'AMap.MoveAnimation',
  // 在此配置你需要预加载的插件，如果不配置，在使用到的时候会自动异步加载
];


dayjs.locale('zh-cn');
const RouteApp = () => useRoutes(routes)
function App() {
  return (

    <Suspense>
      <Router>
        <AliveScope>
          <RouteApp />
        </AliveScope>
      </Router>

    </Suspense>
  );
}

export default App;
