import { Table, Input, Button, Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import { API_getApplyCompany, API_companyVerify, API_refuseCompanyApply, API_companyVerify2, API_getCompanyDetail, } from "@/service/common";
import AntdCell from "@/components/antdCell";
import { useParams } from "react-router-dom";
const CompanyManage = () => {
    const { type } = useParams();
    const columns = [
        {
            title: "联系电话",
            dataIndex: "phone",
            key: "phone",
        },
        {
            title: "公司名称",
            dataIndex: "companyInfos",
            key: "companyInfos",
            render:(row,data)=>{
                return row[0].name
            }
        },
        {
            title: "申请人信息",
            dataIndex: "id",
            key: "id",
            render: (text, record) => (
                <Button
                    type="primary"
                    onClick={(e) => {
                        showDetail(record);
                    }}
                    ghost
                >
                    查看
                </Button>
            ),
        },
        {
            title: "申请时间",
            dataIndex: "createTime",
            key: "createTime",
        },
        {
            title: "状态",
            dataIndex: "orderState",
            key: "orderState",
            render: (text, record) => {
                switch (text) {
                    case 1:
                        return "待受理";
                    case 2:
                        return "待签约";
                    case 3:
                        return "已签约";
                    case 4:
                        return (
                            <>
                                <div>已拒绝</div>
                                <div>原因:{record.handleResult}</div>
                            </>
                        );
                }
            },
        },
        {
            title: "操作",
            key: "action",
            render: (text, record) => (
                <>
                    {type == 1 && (
                        <>
                            <Button type="primary" onClick={(e) => verifyCompany(record)}>
                                受理
                            </Button>
                            <Button
                                type="danger"
                                style={{ marginLeft: "10px" }}
                                onClick={(e) => refuse(record)}
                            >
                                拒绝
                            </Button>
                        </>
                    )}
                    {type == 2 && (
                        <Button type="primary" onClick={(e) => verifyCompany2(record)}>
                            签约
                        </Button>
                    )}
                </>
            ),
        },
    ];

    const columns2 = [
        { title: "公司名字", key: "firstName", type: "name" },
        { title: "地址电话", key: "addressPhone", type: "text" },
        { title: "税务登记号", key: "taxAccount", type: "text" },
        { title: "开户银行及账号", key: "bankAccount", type: "text" },
        { title: "营业执照", key: "license", type: "image" },
        { title: "开户许可证", key: "permit", type: "image" },
    ];

    const getList = () => {
        API_getApplyCompany({
            pageindex: pageIndex,
            pagesize: pageSize,
            accept: type,
        }).then((res) => {
            res.data.map((data) => {
                data.phone = data.userResume.phone;
            });
            setApplyList(res.data);
            setTotal(res.total);
        });
    };

    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSIze] = useState(10);
    const [applyList, setApplyList] = useState([]);
    const [total, setTotal] = useState(0);
    useEffect((e) => { getList(); }, [pageIndex, type]);
    const onChange = (e) => {
        setPageIndex(e);
    };

    const goAddress = () => {
        window.open(
            `http://api.map.baidu.com/marker?location=${companyDetail.defatluAddress.point[1]},${companyDetail.defatluAddress.point[0]}&title=机构位置&content=${companyDetail.organName}&output=html&src=webapp.baidu.openAPIdemo`
        );
    };

    const verifyCompany = (item) => {
        Modal.confirm({
            content: "是否受理该公司申请?",
            okText: "确定",
            cancelText: "取消",
            onOk: (e) => {
                API_companyVerify({ orderId: item.id }).then((res) => {
                    message.success("受理成功!");
                    item.isVerify = true;
                    setIsModalVisible(false);
                    getList();
                    Modal.destroyAll();
                    return Promise.resolve();
                });
            },
        });
    };

    const verifyCompany2 = (item) => {
        Modal.confirm({
            content: "是否签约该公司?",
            okText: "确定",
            cancelText: "取消",
            onOk: (e) => {
                API_companyVerify2({ orderId: item.id }).then((res) => {
                    message.success("签约成功!");
                    item.isVerify = true;
                    setIsModalVisible(false);
                    getList();
                    Modal.destroyAll();
                    return Promise.resolve();
                });
            },
        });
    };

    const refuse = (item) => {
        localStorage.setItem("reason", "");
        Modal.confirm({
            title: "拒绝原因",
            content: (
                <Input
                    placeholder="请输入拒绝原因"
                    onChange={(e) => {
                        localStorage.setItem("reason", e.target.value);
                    }}
                ></Input>
            ),
            okText: "确定",
            cancelText: "取消",
            onOk: (e) => {
                API_refuseCompanyApply({
                    orderId: item.id,
                    message: localStorage.getItem("reason") || "拒绝",
                })
                    .then((res) => {
                        message.success("已拒绝!");
                        item.isVerify = true;
                        Modal.destroyAll();
                        getList();
                        return Promise.resolve();
                    })
                    .catch((e) => { });
            },
        });
    };

    const [companyDetail, setCompanyDetail] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showDetail = (item) => {
        setCompanyDetail({})
        API_getCompanyDetail({ uid: item.userID, pageindex: 1, pagesize: 10 }).then((res) => {
            setCompanyDetail(res.data[0]);
        });
        setIsModalVisible(true);
    };

    return (
        <div className="custom_auto">
            <div className="custom_pack">
                <Table columns={columns} dataSource={applyList} rowKey={(columns) => columns.id}
                    pagination={{
                        pageSize: pageSize,
                        total: total,
                        onChange: onChange,
                        pageSizeOptions: [],
                    }} />
            </div>
            <Modal
                title="机构详情"
                open={isModalVisible}
                footer={null}
                onCancel={(e) => setIsModalVisible(false)}
            >
                <AntdCell columns={columns2} dataSource={companyDetail}></AntdCell>
            </Modal>
        </div>
    );
};
export default CompanyManage;
