import { useState, useCallback, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  message,
  AutoComplete,
} from "antd";
import { Amap, Marker, InfoWindow } from "@amap/amap-react";
const AddressPicker = (props) => {
    const [showAmap, setShowAmap] = useState(false);
    const [head,setHead]=useState("")
    const [address, setAddress] = useState("");
    const [position, setPosition] = useState([120, 30]);
    const [addressOptions, setAddressOptions] = useState([]);
    const [searchAddress, setSearchAddress] = useState("");

    const handleGeocoderAddress = (point) => {
        setPosition(point);
        window.AMap.plugin(["AMap.Geocoder"], function () {
            let geocoder = new window.AMap.Geocoder({
                radius: 1000, //以已知坐标为中心点，radius为半径，返回范围内兴趣点和道路信息
                extensions: "all", //返回地址描述以及附近兴趣点和道路信息，默认"base"
            });
            geocoder.getAddress(point, function (status, result) {
                if (status === "complete" && result.info === "OK") {
                    // result为对应的地理位置详细信息

                    let address = result.regeocode.formattedAddress;
                    let point = result.regeocode.crosses[0].location;
                    setHead("");
                    setAddress(address);
                }
            });
        });
    };
    const  useDebounce=(fn, delay, dep = [])=> {
        const { current } = useRef({ fn, timer: null });
        useEffect(function () {
            current.fn = fn;
        }, [fn]);

        return useCallback(function f(...args) {
            if (current.timer) {
                clearTimeout(current.timer);
            }
            current.timer = setTimeout(() => {
                current.fn.call(this, ...args);
            }, delay);
        }, dep)
    }
    const handleSearchAddress = useDebounce((keyword) => {
        if (keyword) {
            window.AMap.plugin(["AMap.AutoComplete"], function () {
                var autoOptions = {
                    //city 限定城市，默认全国
                    city: '全国'
                };
                // 实例化AutoComplete
                var autoComplete = new window.AMap.AutoComplete(autoOptions);
                // 根据关键字进行搜索
                autoComplete.search(keyword, function (status, result) {
                    console.log(result)
                    // 搜索成功时，result即是对应的匹配数据
                    let addressList = []
                    result.tips.forEach((data, index) => {
                        addressList.push({
                            head:data.district,
                            label: data.name,
                            value: [data.location.lng, data.location.lat],
                            key: index
                        })
                    })
                    console.log(addressList);
                    setAddressOptions(addressList)
                })
            })
        }

    }, 400);

    const confirmClick = () => {
        setShowAmap(false);
        props.getAddress(position,head+address)
    }


    return (
        <div className="addressPicker">
            <Button
                onClick={() => setShowAmap(true)}
                style={{ marginLeft: "10px" }}
            >
                选取位置
            </Button>
            <Modal
                width={600}
                title="选取位置"
                layout="vertical"
                open={showAmap}
                okText="确认"
                cancelText="取消"
                onOk={confirmClick}
                onCancel={() => setShowAmap(false)}
            >
                <div style={{ padding: "0 0 24px 0" }}>
                    <AutoComplete
                        allowClear
                        style={{ width: "100%" }}
                        value={searchAddress}
                        onSearch={handleSearchAddress}
                        onChange={(key, option) => {
                            setSearchAddress(key)
                        }}
                        onSelect={(key, option) => {
                            if (!option.value[0]) {
                                message.warning('该位置无准确经纬度')
                                return;
                            }
                            setHead(option.head)
                            setAddress(option.label)
                            setPosition(option.value)
                            setSearchAddress(option.label)
                            console.log(key, option);
                        }}
                        placeholder="请输入地址关键字查询"
                        options={addressOptions}
                    ></AutoComplete>
                </div>
                <div style={{ height: "400px", width: "552px" }}>
                    <Amap zoom="15" center={position}>
                        <Marker
                            position={position}
                            draggable
                            label={{
                                content: address,
                                direction: "bottom",
                            }}
                            onDragEnd={(m) => handleGeocoderAddress(m._position)}
                        />
                    </Amap>
                </div>
            </Modal>
        </div>
    )
}

export default AddressPicker