import zlsqAPI from "../config/ajax-zlsq";

export const API_getUser = (data) =>zlsqAPI.get("/User/getUserInfo", data);

export const API_getUserDetail = (data) =>zlsqAPI.get("/User/getDetail", data);

export const API_getZlsqStoreDetail = (data) =>zlsqAPI.get("/Store/GetStoreInfo/"+data, {});


export const API_geCategoryList = (data) =>zlsqAPI.get("/Category/GetDetail", data);

export const API_getProductList = (data) =>zlsqAPI.get("/Product/Search", data);
export const API_GetProductInfo = (data) => zlsqAPI.get("/Product/GetDetail", data);


export const API_GetProductSpaces = (data) => zlsqAPI.get("/ProductSpace/GetSpaces", data);

//下单

export const API_placeOrder = (data) => zlsqAPI.post("/Order/PlaceOrderV1", data);

//获取掌联分类

export const API_getClasses = (data) => zlsqAPI.get('/Industry/GetClasses', data)

//获取掌联商城分类

export const API_getStoreClasses = (data) => zlsqAPI.get('/ProductCategory/GetDetailList', data)



