import BigNumber from "bignumber.js";

export const dataUrl = "https://upfiles.zjzlsq.cn/";
export const mapKey = "030324c1d7cbaa237cbd0c4069f33a13";

export const isAssetTypeAnImage = (e) => {
  let list = e.split(".");
  let ext = list[list.length - 1];
  return (
    ["png", "jpg", "jpeg", "bmp", "gif", "webp", "psd", "svg", "tiff"].indexOf(
      ext.toLowerCase()
    ) !== -1
  );
};

export const getFileType = (url) => {
  let pdfReg = /^.+(\.pdf)$/;
  let txtReg = /^.+(\.txt)$/;
  let wordReg = /^.+(\.doc|\.docx)$/;
  let excelReg = /^.+(\.xls|\.xlsx)$/;
  let jpgReg = /^.+(\.png|\.jpg|\.jpeg|\.bmp|\.gif)$/;
  let rarReg = /^.+(\.rar|\.zip|\.7z)$/;
  if (pdfReg.test(url)) {
    return "pdf";
  }
  if (txtReg.test(url)) {
    return "txt";
  }
  if (wordReg.test(url)) {
    return "word";
  }
  if (excelReg.test(url)) {
    return "excel";
  }
  if (jpgReg.test(url)) {
    return "image";
  }
  if (rarReg.test(url)) {
    return "rar";
  }
  return "other";
};

//设置cookie
export const setCookie = (name, value) => {
  let strsec = 3600 * 1000 * 24;
  let exp = new Date();
  exp.setTime(exp.getTime() + strsec * 1);
  document.cookie =
    name + "=" + escape(value) + ";expires=" + exp.toGMTString();
};

//获取query
export const getQuery = (name) => {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  let r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
};

export const extractInvoiceInfo = (text) => {
  const info = {
    companyName: /(?:名称|公司名称)：(.+?)(?:\n|账号|开户银行|$)/,
    taxpayerId: /(?:纳税人识别号|社会统一信息代码证)：(.+?)(?:\n|$)/,
    address: /(?:地址)：(.+?)(?:\n|$)/,
    phone: /(?:电话)：(.+?)(?:\n|$)/,
    account: /(?:账号)：(.+?)(?:\n|$)/,
    bank: /(?:开户银行|银行)：(.+?)(?:\n|$)/,
  };

  const result = {};
  Object.keys(info).forEach((key) => {
    const match = text.match(info[key]);
    if (match) {
      result[key] = match[1];
    } else {
      result[key] = null;
    }
  });

  return result;
};

export function arabicToChinese(num) {
  var chnNumChar = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
  var chnUnitChar = ["", "十", "百", "千", "万", "亿", "万亿", "亿亿"];
  var strIns = "",
    chnStr = "";
  var unitPos = 0;
  var zero = true;
  while (num > 0) {
    // 从个位开始处理数字，每次处理一位
    var v = num % 10;
    if (v === 0) {
      if (!zero) {
        zero = true;
        chnStr = chnNumChar[v] + chnStr;
      }
    } else {
      zero = false;
      strIns = chnNumChar[v];
      strIns += chnUnitChar[unitPos];
      chnStr = strIns + chnStr;
    }
    unitPos++;
    num = Math.floor(num / 10);
  }
  return chnStr;
}




export function calculateParams(
  { amount, price, priceWithTax, priceNum, priceNumWithTax, rate, rateNum },key) {
  if (key == "price") {
    priceWithTax = "";
  } else if (key == "priceWithTax") {
    price = "";
  } else if (key == "priceNum") {
    priceNumWithTax = "";
  } else if (key == "priceNumWithTax") {
    priceNum = "";
  }

  let paramNum = 0;
  if (amount) paramNum++;
  if (price || priceWithTax) {
    paramNum++;
    if (price) {
      priceWithTax = BigNumber(price)
        .times(rate / 100 + 1)
        .decimalPlaces(10)
        .toNumber();
    } else {
      price = BigNumber(priceWithTax)
        .dividedBy(rate / 100 + 1)
        .decimalPlaces(10)
        .toNumber();
    }
  }
  if (priceNum || priceNumWithTax) {
    paramNum++;
    if (priceNum) {
      priceNumWithTax = BigNumber(priceNum)
        .times(rate / 100 + 1)
        .decimalPlaces(2)
        .toNumber();
    } else {
      priceNum = BigNumber(priceNumWithTax)
        .dividedBy(rate / 100 + 1)
        .decimalPlaces(2)
        .toNumber();
    }
  }
  if (paramNum > 1) {
    if (key == "amount") {
      price = priceNum/amount;
      priceWithTax = priceNumWithTax/amount;
    } else if(key=='price'||key=='priceWithTax'){
        if(amount){
          priceNum =BigNumber(price).times(amount).decimalPlaces(2).toNumber();
          priceNumWithTax = BigNumber(priceWithTax).times(amount).decimalPlaces(2).toNumber();
        }else{
          amount=BigNumber(priceNum).dividedBy(price).decimalPlaces(10).toNumber()
        }
      }else if(key=='priceNum'||key=='priceNumWithTax'){
        if(amount){
          price =BigNumber(priceNum).dividedBy(amount).decimalPlaces(10).toNumber();
          priceWithTax = BigNumber(priceNumWithTax).dividedBy(amount).decimalPlaces(10).toNumber();
        }else{
          amount=BigNumber(priceNum).dividedBy(price).decimalPlaces(10).toNumber()
        }
      }
    }
  
  return {
    amount: amount,
    price: price,
    priceWithTax: priceWithTax,
    priceNum: priceNum,
    priceNumWithTax: priceNumWithTax,
    rate: rate,
    rateNum: rateNum,
  };
}
