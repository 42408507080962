import {
  Button,
  Table,
  Modal,
  Form,
  message,
  Upload,
  Input,
  Select,
  Image,
  Empty,
  DatePicker,
} from "antd";
import {
  API_GetCashFlowList,
  API_AddCashFlow,
  API_getContractList,
  API_getInvoiceList,
} from "@/service/common";
import React, { useEffect, useState } from "react";
import { API_RemoveCashFlow, API_getStaffList } from "../../../service/common";
import { PlusOutlined } from "@ant-design/icons";
import { uploadHeader } from "../../../utils/utils";
import FileList from "../../../components/fileList/fileList";
import { useRef } from 'react';
import FileContent from "../../../components/fileContent";
import FileModal from "../../../components/fileModal/fileModal";
import dayjs from 'dayjs';
/**
 * @fileName 现金流管理
 * @param {*} props
 * @returns
 */

export default function CashflowManage(props) {
  const {RangePicker}=DatePicker;
  const invoiceRef=useRef()
  const [rangeTime,setRangeTime]=useState([]);
  const [text,setText]=useState('')
  const [contractList, setContractList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [cashflowList, setCashflowList] = useState([]);
  const [reqCashflowInfo, setReqCashflowInfo] = useState({
    pageIndex: 1,
    pageSize: 10,
  });
  const [cashflowLoading, setCashflowLoading] = useState(false);
  const [cashflowTotal, setCashflowTotal] = useState(0);
  const [transaction, setTransaction] = useState([]);
  const [transForm] = Form.useForm();
  const [showTransInfo, setShowTransInfo] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [fileContentShow,setFileContentShow]=useState(false)
  const [fileContent,setFileContent]=useState([]);
  const [contractID, setContractID] = useState(0);
  const [invoiceShow,setInvoiceShow]=useState(false);
  const [cashInvoiceList,setCashInvoiceList]=useState([])
  const [chooseNum,setChooseNum]=useState(0)
  const userType=localStorage.getItem('userType')
 const fileRef=useRef();
  useEffect((e) => {
    API_getStaffList({ pagesize: 150 }).then((res) => {
      let list = res.data;
      setStaffList(list);
    });
    API_getContractList({ pageIndex: 1, pageSize: 99 }).then((res) => {
      setContractList(res.data);
    });
  }, []);

  // 获取合同列表
  const onGetCashflowList = () => {
    setCashflowLoading(true);
    let reqData={
      Header:text
    }
    if(rangeTime.length==2){
       reqData.CreateTimeStart=rangeTime[0]+' 00:00:00';
       reqData.CreateTimeEnd=rangeTime[1]+' 23:59:59'
    }
    API_GetCashFlowList({...reqCashflowInfo,...reqData}).then((res) => {
      setCashflowList(res.data);
      setCashflowLoading(false);
      setCashflowTotal(res.total);
    });
  };

  useEffect(() => {
    onGetCashflowList();
  }, [reqCashflowInfo.pageIndex, reqCashflowInfo.pageSize]);

  useEffect(
    (e) => {
      if (contractID) {
        API_getInvoiceList({
          pageIndex: 1,
          pageSize: 99,
          contractid: contractID,
        }).then((res) => {
          res.data.map((row) => {
            row.label = row.contractlist.header + "(" + row.id + ")";
          });
          setInvoiceList(res.data);
        });
      } else {
        setInvoiceList([]);
      }
    },
    [contractID]
  );

    //删除

    const del = (e) => {
      Modal.confirm({title:"提示",content:"是否确认删除该记录?",onOk:row=>{
          API_RemoveCashFlow({ id: e }).then((res) => {
              message.success("删除成功");
              onGetCashflowList();
              Modal.destroyAll()
          });
      }})
  
    };

    const showFile = (e)=>{
        setFileContentShow(true)
        setFileContent(e.split(','))
    }

  // 表格参数
  const columns = userType==1?[
    { title: "备注",  fixed: "left", align: "center", dataIndex: "header" },
    { title: "发票", align: "center", dataIndex: "invoicesIds",render:(e)=><Button onClick={()=>{setCashInvoiceList(e);setInvoiceShow(true)}}>查看发票</Button> },
    { title: "员工", align: "center", dataIndex: "staffName" },
    { title: "金额", align: "center", dataIndex: "money" },
    { title: "转账记录", align: "center", dataIndex: "paymentVoucher",render(e){
        return <Button type='primary' onClick={()=>showFile(e)}>查看记录</Button>
    } },
    
    { title: "创建时间", align: "center", dataIndex: "createTime" },
    { title: "操作", align: "center", dataIndex: "id",render(e){
       return <Button danger type='primary' onClick={() => {del(e)}}>删除</Button>
    } },
  ]:[
    { title: "备注",  fixed: "left", align: "center", dataIndex: "header" },
    { title: "发票", align: "center", dataIndex: "invoicesIds",render:(e)=><Button onClick={()=>{setCashInvoiceList(e);setInvoiceShow(true)}}>查看发票</Button> },
    { title: "金额", align: "center", dataIndex: "money" },
    { title: "转账记录", align: "center", dataIndex: "paymentVoucher",render(e){
        return <Button type='primary' onClick={()=>showFile(e)}>查看记录</Button>
    } },
    { title: "创建时间", align: "center", dataIndex: "createTime" },
    { title: "操作", align: "center", dataIndex: "id",render(e){
       return <Button danger type='primary' onClick={() => {del(e)}}>删除</Button>
    } },
  ];
  const onEditTransInfo = (form) => {
    message.loading("正在提交")
    form.paymentVoucher = fileRef.current.returnFileList().join(',');
    API_AddCashFlow(form).then((res) => {
      message.success("添加完成");
      setShowTransInfo(false);
      onGetCashflowList();
    });
  };

  const onUpload2 = (e, record) => {
    let response = e.file.response
    if (response) {
      if (response.status == 'OK') {
        transaction.push(response.data)
        setTransaction([...transaction])
      } else {
        message.error("上传失败!")
      }
    }
  };

  const formateRange = (e)=>{
    if(!e){setRangeTime([])}
    let list=[];
    e.map(row=>{
      list.push(dayjs(row).format('YYYY-MM-DD'))
    })
    setRangeTime(list)
  }

  const getInvoiceDetail = (id)=>{
    message.loading()
      API_getInvoiceList({id:id}).then(res=>{
        if(!res.data[0]||!res.data[0].images){
             message.error('未上传发票文件!');
             return
        }
           message.destroy()
           invoiceRef.current.setFileContent(res.data[0].images);
           invoiceRef.current.show();
      })
  }
  
  console.log(transForm.getFieldValue())

  return (
    <div className="custom_auto">
      <div className="custom_pack custom_filter">
        <div>
           <RangePicker   onChange={e=>formateRange(e)}   format="YYYY-MM-DD"> </RangePicker>
        </div>
        <div><Input placeholder="输入备注搜索" onChange={e=>setText(e.target.value)} allowClear></Input></div>
        <Button
          onClick={(e) => {
            onGetCashflowList();
          }}
          type="primary"
          style={{backgroundColor:'#0bb90b'}}
        >
          搜索
        </Button>
        <Button
          onClick={(e) => {
            setShowTransInfo(true);
          }}
          type="primary"
        >
          新增
        </Button>
      </div>
      <div className="custom_pack">
        <Table
          rowKey={(columns) => columns.id}
          dataSource={cashflowList}
          loading={cashflowLoading}
          columns={columns}
          pagination={{
            showTotal: () => `共 ${cashflowTotal} 条`,
            pageSizeOptions: [10, 20, 50, 100],
            showSizeChanger: true,
            pageSize: reqCashflowInfo.pageSize,
            total: cashflowTotal,
            onChange(pageIndex, pageSize) {
              setReqCashflowInfo({ ...reqCashflowInfo, pageIndex, pageSize });
            },
          }}
          scroll={{ y: `calc(100vh - 396px)`, scrollToFirstRowOnChange: true }}
        />
      </div>
      <Modal
        open={showTransInfo}
        title="编辑现金流"
        onCancel={() => {
          setShowTransInfo(false);
        }}
        onOk={() => transForm.submit()}
        afterClose={() => {
          transForm.resetFields();
          setTransaction([])
          
        }}
        maskClosable={false}
        destroyOnClose={true}
      >
        <Form
          form={transForm}
          initialValues={{}}
          labelWrap
          onFinish={onEditTransInfo}
        >
          <Form.Item label="备注" name="header"  rules={[{ required: true, message: "请输入备注" }]}>
            <Input type="text" />
          </Form.Item>
          <Form.Item
            label="合同"
            name="contractID"
            rules={[{ required: true, message: "请选择合同" }]}
          >
            <Select
              options={contractList}
              placeholder="请选择合同"
              fieldNames={{ label: "header", value: "contractId" }}
              onChange={(e) => {
                setContractID(e);
                transForm.setFieldsValue({invoicesIds:[]})
                setChooseNum(0)
              }}
            />
          </Form.Item>
          <Form.Item
            label="发票"
            name="invoicesIds"
            
            rules={[{ required: true, message: "请选择发票" }]}
          >
            <Select
              defaultValue={transForm.invoicesIds}
              options={invoiceList}
              mode="multiple"
              onChange={e=>{setChooseNum(e.length)}}
              showSearch={false}
              suffixIcon={<div className="select-btn">{(chooseNum==0)?"点击选择":`已选(${chooseNum})个`}</div>}
              placeholder="请选择发票"
              fieldNames={{ label: "label", value: "id" }}
            />
          </Form.Item>
          {userType==1&&<Form.Item
            label="员工"
            name="staffId"
            rules={[{ required: true, message: "请选择员工" }]}
          >
            <Select
              options={staffList}
              placeholder="请选择员工"
              fieldNames={{ label: "staffName", value: "staffId" }}
            />
          </Form.Item>}
          <Form.Item
            label="金额"
            name="money"
            rules={[{ required: true, message: "请输入金额" }]}
          >
            <Input type="number" />
          </Form.Item>
    
          <Form.Item
            label="转账记录"
            name="paymentVoucher"
            rules={[{ required: true, message: "请上传转账记录!" }]}
          >
            <div className="flex-start2">
               <FileList ref={fileRef} width='100px' fileList={transaction}></FileList>
               <Upload
                 headers={uploadHeader()}
                 onChange={(e) => onUpload2(e)}
                 itemRender={e=><></>}
                 action={process.env.REACT_APP_API+"Upload/FileUpload"}
               >
                  <div className="flex-center image-select" >
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>点击上传</div>
                  </div>
               </Upload>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={fileContentShow}
        title='文件列表'
        footer={null}
        onCancel={(e) => {
          setFileContentShow(false);
        }}
        wrapClassName="myInvoice-modal"
        width="80%"
        maskClosable={false}
        destroyOnClose={true}
      >
         {fileContent.map(res=>(
          <FileContent width='200px' url={res}></FileContent>
         ))}
        {fileContent.length==0&&<Empty></Empty>}
      </Modal>
      <Modal
        open={invoiceShow}
        title='发票列表'
        footer={null}
        onCancel={(e) => {
          setInvoiceShow(false);
        }}
        wrapClassName="myInvoice-modal"
        width="80%"
        maskClosable={false}
        destroyOnClose={true}
      >
         <div className="custom_filter">
         {cashInvoiceList.map(res=>(
             <Button type="primary" onClick={e=>getInvoiceDetail(res)}>{res}</Button>
            ))}
         </div>
        {cashInvoiceList.length==0&&<Empty></Empty>}
      </Modal>
      <FileModal ref={invoiceRef} title='发票文件' ></FileModal>
    </div>
  );
}
