import { Button, Input, Modal, message } from "antd";
import "./serviceManage.less";
import { useEffect, useState } from "react";
import {
  API_addService,
  API_addServiceType,
  API_deleteService,
  API_deleteServiceType,
  API_getServiceType,
  API_organGetStaff,
  API_updateService,
  API_updateServiceType,
} from "../../../service/common";

const ServiceManage = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputText, setInputText] = useState("");
  const [mainList, setMainList] = useState([]);
  const [handleItem, setHandleItem] = useState({});
  const [classActive, setClassActive] = useState(0);
  const [addType,setAddType]=useState(0)
  const [subList, setSubList] = useState([]);
  const getMainList = async () => {
    API_getServiceType({ ParentId: 0 }).then((res) => {
      setMainList(res.data);
    });
  };

  useEffect((e) => {
    getMainList();

  }, []);

  useEffect(
    (e) => {
      setSubList(mainList[classActive]?.services || []);
    },
    [classActive, mainList]
  );

  const handleOk = () => {
    if (addType == 0) {
      let reqData = {
        name: inputText,
        parentId: 0,
        remark: "",
        code: "",
        icon: "",
      };
      if (handleItem.id) {
        reqData.id = handleItem.id;
        API_updateServiceType(reqData).then((res) => {
          message.success("新增成功!");
          setInputText("");
          getMainList();
          setIsModalOpen(false);
          setHandleItem({});
        });
      } else {
        API_addServiceType(reqData).then((res) => {
          message.success("添加成功!");
          setInputText("");
          getMainList();
          setIsModalOpen(false);
          setHandleItem({});
        });
      }
    } else {
      let reqData = {
        name: inputText,
        classifyId: mainList[classActive].id,
        description: ""
      };
      if (handleItem.id) {
        reqData.id = handleItem.id;
        API_updateService(reqData).then((res) => {
          message.success("新增成功!");
          setInputText("");
          getMainList();
          setIsModalOpen(false);
          setHandleItem({});
        });
      } else {
        API_addService(reqData).then((res) => {
          message.success("添加成功!");
          setInputText("");
          getMainList();
          setIsModalOpen(false);
          setHandleItem({});
        });
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const updateClass = (item, e) => {
    setAddType(1)
    e.stopPropagation();
    setHandleItem(item);
    setInputText(item.name);
    setIsModalOpen(true);
  };

  const deleteClass = (item, e,addType) => {

    e.stopPropagation();
    Modal.confirm({
      title: "提示",
      content: "确定要删除吗?",
      okText: "确认",
      cancelText: "取消",
      onOk: () => {
        if(addType==0){
          API_deleteServiceType({ id: item.id }).then((res) => {
            message.success("删除成功!");
            getMainList();
          });
        }else{
          API_deleteService({ id: item.id }).then((res) => {
            message.success("删除成功!");
            getMainList();
          });
        }
        
      },
    });
  };


  return (
    <div className="serviceManage-page custom_auto ">
      <div className="flex">
        <div>
          <div className="page-title">主分类</div>
          <div className="class-btn">
            <Button
              type="primary"
              onClick={(e) => {
                setAddType(0)
                setIsModalOpen(true);
                setHandleItem({})
              }}
            >
              新增主分类
            </Button>
          </div>
          <div className="class-content">
            {mainList.map((item,index) => (
              <div className={`class-item flex ${classActive==index?'active':''}`} onClick={e=>setClassActive(index)}>
                <div className="class-item-name">{item.name}</div>
                <div className="class-item-btn flex">
                  <Button
                    type="primary"
                    onClick={(e) => {updateClass(item, e,0)}}
                    ghost
                  >
                    编辑
                  </Button>
                  <Button
                    type="primary"
                    onClick={(e) => {deleteClass(item, e,0)}}
                    danger
                  >
                    删除
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className="page-title">{mainList[classActive]?.name||'子分类'}</div>
          <div className="class-btn">
            <Button
              type="primary"
              onClick={(e) => {
                setAddType(1);
                setIsModalOpen(true);
                setHandleItem({})
              }}
            >
              新增子分类
            </Button>
          </div>
          <div className="class-content">
            {subList.map((item) => (
              <div className="class-item flex" >
                <div className="class-item-name">{item.name}</div>
                <div className="class-item-btn flex">
                  <Button
                    type="primary"
                    onClick={(e) => {updateClass(item, e,1)}}
                    ghost
                  >
                    编辑
                  </Button>
                  <Button
                    type="primary"
                    onClick={(e) => {deleteClass(item, e,1)}}
                    danger
                  >
                    删除
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Modal
        destroyOnClose
        title={`添加${addType==0?'主':'子'}分类`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Input
          type="text"
          autoFocus
          defaultValue={inputText}
          onChange={(e) => {
            setInputText(e.target.value);
          }}
          placeholder="请输入分类名称"
        ></Input>
      </Modal>
    </div>
  );
};

export default ServiceManage;
