import { useCallback, useEffect, useRef } from "react";
// 深拷贝


// 深度克隆
export function deepClone(obj) {
    // 对常见的“非”值，直接返回原来值
    if ([null, undefined, NaN, false].includes(obj)) return obj;
    if (typeof obj !== "object" && typeof obj !== 'function') {
        //原始类型直接返回
        return obj;
    }
    var o = isArray(obj) ? [] : {};
    for (let i in obj) {
        if (obj.hasOwnProperty(i)) {
            o[i] = typeof obj[i] === "object" ? deepClone(obj[i]) : obj[i];
        }
    }
    return o;
}
// 判断arr是否为一个数组，返回一个bool值
function isArray(arr) {
    return Object.prototype.toString.call(arr) === '[object Array]';
}
//验证手机
export function testPhone(phone) {
    let checkRule = /^((\+|00)86)?1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
    if (checkRule.test(phone)) {
        return true;
    } else {
        return false;
    }
}


export function useDebounce(fn, delay, dep = []) {
    const { current } = useRef({ fn, timer: null });
    useEffect(function () {
        current.fn = fn;
    }, [fn]);

    return useCallback(function f(...args) {
        if (current.timer) {
            clearTimeout(current.timer);
        }
        current.timer = setTimeout(() => {
            current.fn.call(this, ...args);
        }, delay);
    }, dep)
}
export function useThrottle(fn, delay, dep = []) {
    const { current } = useRef({ fn, timer: null });
    useEffect(function () {
        current.fn = fn;
    }, [fn]);

    return useCallback(function f(...args) {
        if (!current.timer) {
            current.timer = setTimeout(() => {
                delete current.timer;
            }, delay);
            current.fn.call(this, ...args);
        }
    }, dep);
}


//发票类别等 

export const invoiceTypes = [{ label: "增值税专用发票", value: 1 }, { label: "增值税电子专用发票", value: 2 }, { label: "增值税电子普通发票", value: 3 }] // 发票类型
export const componyCategories = [{ label: "一般纳税人", value: 1 }, { label: "小规模纳税人", value: 2 }] // 企业类型

//


export function returnTypeText (id){
    let row=invoiceTypes.find(item=>item.value==id)||{}
    console.log(row,id)
    return row.label
}

export function returnCategoryText (id){
 let row=componyCategories.find(item=>item.value==id)||{}
 return row.label
}


export function uploadHeader(){
   return { Authorization: 'Bearer '+localStorage.getItem("accessToken")}
}

