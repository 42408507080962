import "./login.less";
import React, { useEffect, useState } from "react";
import { Steps, Form, Input, Button, message, Modal, Radio, Upload, Image } from "antd";
import { PhoneOutlined,PlusOutlined, LockOutlined, ArrowRightOutlined, MessageOutlined, HomeOutlined, PartitionOutlined, UserOutlined } from "@ant-design/icons";
import {
  API_getUserInfo,
  API_accountLogin,
  API_getMsgCode,
  API_checkMsgCode,
  API_register,
  API_getCompanyList,
  API_getMyOrgan,
  API_accountLoginMsg,
  API_addCompanyList,
  API_getAccountSubAccount,
  API_subAccountLogin,
} from "../service/common";
import { Link, useNavigate, useParams } from "react-router-dom";
import OrganRegister from "./organ/register";
import { uploadHeader } from "../utils/utils";
export default LoginPage;
function LoginPage(props) {
  const navigate = useNavigate();
  const [taxpayerForm] = Form.useForm()
  const { from } = useParams();
  const { Step } = Steps;
  const [msgLogin, setMsgLogin] = useState(false);
  const [showStep, setShowStep] = useState(false);
  const [current, setCurrent] = useState(1);
  const [userType, setUserType] = useState(0);
  const [loginType, setLoginType] = useState(true);
  const [registerShow, setRegisterShow] = useState(false);
  const [registerShow2, setRegisterShow2] = useState(false);
  const [phone, setPhone] = useState(localStorage.getItem('phone')||'');
  const [name, setName] = useState('');
  const [msgCode, setMsgCode] = useState("");
  const [password, setPassword] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [countDown, setCountDown] = useState(0);
  const [organData, setOrganData] = useState({});
  const [companyList,setCompanyList]= useState([]);
  const [showCompanyList,setShowCompanyList]=useState(false);
  const [license, setLicense] = useState('');
  const [permit, setPermit] = useState('');

  const onFinish = (values) => {
    message.loading("正在登录");
    if (msgLogin) {
      API_accountLoginMsg({ phone, password:msgCode }).then((res) => {
        localStorage.setItem("accessToken", res.data);
        localStorage.setItem("phone",phone)
        handleData();
      });
    } else {
      API_accountLogin({ phone, password }).then((res) => {
        localStorage.setItem("accessToken", res.data);
        localStorage.setItem("phone",phone)
        handleData();
      });
    }
  };

  const getCompanyList=()=>{
    API_getCompanyList({
      pageindex: 1,
      pagesize: 99,
      isseller: true,
    }).then((res) => {
      setCompanyList(res.data)
    });
  }
  const jumpPage = (info)=>{
    let userType =info.userType;
     if (userType === 4) {
       API_getMyOrgan().then(
         (res) => {
           let info = res.data;
           if (info && info.id) {
             setOrganData(info);
             localStorage.setItem('organID', info.organid)
             setShowModal(!info.isVerify);
             setRegisterShow(!info.isVerify);
             if (info.isVerify) {
               navigate("/organ/organInfo");
             }
           } else {
             setShowModal(false);
             setRegisterShow(true);
           }
         }
       );
     } else{
        navigate("/taxpayer/infoManage/taxpayerInfo");
     }
  }

  const handleData = () => {
    API_getUserInfo().then(async (res) => {
      localStorage.setItem('userID', res.data.userID)
      localStorage.setItem('userType', res.data.userType)
      let subUsers=await API_getAccountSubAccount({masterUserId:res.data.userID})
      if(subUsers.data.length>0){
        setCompanyList([res.data].concat(subUsers.data));
        setShowCompanyList(true);
        message.destroy()
        return;
      }else{        
        jumpPage(res.data);
        message.destroy()
      }

    });
  };
  useEffect(e=>{
    localStorage.setItem('accessToken', '')
  },[])
  useEffect(() => {
    if (countDown) {
      setTimeout(() => {
        setCountDown((count) => count - 1);
      }, 1000);
    } else {
      setDisabled(false);
    }
  }, [countDown]);

  const getCode = () => {
    if (phone.length != 11) {
      return;
    }
    setDisabled(true);
    setCountDown(60);
    API_getMsgCode({ phone: phone }).then((resData) => { });
  };

  const onRegister = (values) => {
    console.log(values.userType);
    if (values.userType === undefined) {
      message.warning("请选择注册类型");
      return;
    }
   API_register({ phone: phone, password,roleType:values.userType, userType: values.userType, name: name,code: msgCode}).then(
     (res) => {
       message.success("注册成功!");
       setLoginType(true);
     }
   );
  };

  const  addCompany=(form)=>{
      let newObj = form;
      newObj.license=license;
      newObj.permit=permit;
      newObj.isSeller = true;
        API_addCompanyList(newObj).then((res) => {
          message.success("添加成功!");
          setRegisterShow2(false);
          setLicense('');
          setPermit('');
          taxpayerForm.resetFields()
          getCompanyList()
        });
  }

  const chooseCompany =(data,index)=>{
    if(index==0){
      jumpPage(data)
    }else{
      message.loading('正在登录...')
      let {userID,phone}=data;
      API_subAccountLogin({userID,phone}).then(row=>{
        localStorage.setItem("accessToken", row.data);
        message.destroy()
        API_getUserInfo().then(async (res) => {
          localStorage.setItem('userID', res.data.userID)
          localStorage.setItem('userType', res.data.userType)
          jumpPage(res.data)
      })
     
      })
    }
    setShowCompanyList(false)
  }
  return (
    <div className="login">
      <img className="login_bg" src={require("../imgs/loginbg.jpg")} alt="" />
      <section className="login_content">
        <div className="login_title">
          {loginType ? (from === "organ" ? "机构登录" : "用户登录") : "注册"}
        </div>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={loginType ? onFinish : onRegister}
        >
          {!loginType && (
            <>
              <Form.Item name="userType" label='类型'>
                <Radio.Group >
                  <Radio value={1}>个人</Radio>
                  <Radio value={2}>公司</Radio>
                  <Radio value={4}>机构</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item name="name">
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  size="large"
                  placeholder="请输入名字"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Form.Item>
            </>
          )}

          <Form.Item name="phone">
            <Input
              maxLength={11}
              prefix={<PhoneOutlined className="site-form-item-icon" />}
              defaultValue={phone}
              size="large"
              placeholder="请输入手机号"
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            />
          </Form.Item>
          {!loginType && (
            <Form.Item>
              <Input.Group compact>
                <Input
                  style={{ width: "calc(100% - 100px)" }}
                  prefix={<MessageOutlined className="site-form-item-icon" />}
                  size="large"
                  placeholder="请输入验证码"
                  onChange={(e) => {
                    setMsgCode(e.target.value);
                  }}
                />
                <Button
                  onClick={disabled?'':getCode}
                  style={{ width: "100px", height: "40px" }}
                  type="primary"
                >
                  {disabled ? countDown + "s" : "获取验证码"}
                </Button>
              </Input.Group>
            </Form.Item>
          )}
          <Form.Item>
            {msgLogin ? (
              <Input.Group compact>
                <Input
                  style={{ width: "calc(100% - 100px)" }}
                  prefix={<MessageOutlined className="site-form-item-icon" />}
                  size="large"
                  placeholder="请输入验证码"
                  onChange={(e) => {
                    setMsgCode(e.target.value);
                  }}
                />
                <Button
                 onClick={disabled?'':getCode}
                  style={{ width: "100px", height: "40px" }}
                  type="primary"
                >
                  {disabled ? countDown + "s" : "获取验证码"}
                </Button>
              </Input.Group>
            ) : (
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                size="large"
                placeholder="请输入密码"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            )}
            <div className="flex">
              <div className="toggle" onClick={(e) => setMsgLogin(!msgLogin)}>
                {msgLogin ? "密码登录" : "验证码登录"}
              </div>
              <div
                className="toggle"
                onClick={(e) => {
                  setLoginType(!loginType);
                  setMsgLogin(false);
                }}
              >
                <span>{loginType ? "前往注册" : "前往登录"}</span>
                <ArrowRightOutlined />
              </div>
            </div>
          </Form.Item>
          {loginType ? (
            <Form.Item>
              <Button
                type="primary"
                shape="round"
                size="large"
                htmlType="submit"
                className="login-form-button"
                block
              >
                登录
              </Button>
            </Form.Item>
          ) : (
            <>
              <Form.Item>
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  htmlType="submit"
                  className="login-form-button"
                  block
                >
                  注册
                </Button>
              </Form.Item>
            </>
          )}
        </Form>
      </section>
      <Modal
        wrapClassName="login-modal"
        onCancel={(e) => setRegisterShow(false)}
        open={registerShow}
        footer={null}
        title="机构注册"
        width="70%"
        style={{ height: "70vh" }}
      >
        <OrganRegister
          setShowModal={setShowModal}
          organData={organData}
          showModal={showModal}
        ></OrganRegister>
      </Modal>
      <Modal
        wrapClassName="login-modal"
        onCancel={(e) => setRegisterShow2(false)}
        open={registerShow2}
        footer={null}
        title="公司注册"
        width="70%"
        style={{ height: "70vh" }}
      >
        <Form form={taxpayerForm} name="taxpayerForm" labelCol={{ span: 4, offset: 0 }} onFinish={(e) => { addCompany(e)}} autoComplete="off" size="large">
            <Form.Item label="公司/个体名称" name="name" rules={[{ required: true, message: "请输入公司名称" }]}>
                <Input></Input>
            </Form.Item>
            <Form.Item label="地址电话" name="addressPhone" rules={[{ required: true, message: "请输入地址电话" }]}>
                <Input></Input>
            </Form.Item>
            <Form.Item label="税务登记号" name="taxAccount" rules={[{ required: true, message: "请输入税务登记号" }]}>
                <Input></Input>
            </Form.Item>
            <Form.Item label="开户银行及账号" name="bankAccount" rules={[{ required: true, message: "请输入开户银行及账号" }]}>
                <Input></Input>
            </Form.Item>
            <Form.Item label="营业执照" name="license" rules={[{ required: true, message: "请上传营业执照!" }]}>
                <div className="custom_upload_height flex-start">
                {license&&<Image width='100px' height="100px" style={{marginRight:"10px"}} src={license}></Image>}
                    <Upload      headers={uploadHeader()} action={process.env.REACT_APP_API+"Upload/FileUpload"} accept="image/*" data={{ FileType: "1" }}
                          onChange={({ fileList }) => {if(fileList[0].response){setLicense(fileList[0].response.data)}}}>
                        <div className="uploadBtn-1">
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>上传图片</div>
                        </div>
                    </Upload>
                </div>
            </Form.Item>
            <Form.Item label="开户许可证" name="permit" rules={[{ required: true, message: "请上传营业执照!" }]}>
                <div className="custom_upload_height flex-start">
                   {permit&&<Image width='100px' height="100px" style={{marginRight:"10px"}} src={permit}></Image>}
                    <Upload      headers={uploadHeader()} action={process.env.REACT_APP_API+"Upload/FileUpload"} accept="image/*" data={{ FileType: "1" }}
                         onChange={({ fileList }) => {if(fileList[0].response){setPermit(fileList[0].response.data)}}}>
                        <div className="uploadBtn-1">
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>上传图片</div>
                        </div>
                    </Upload>
                </div>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 3, span: 16 }}>
                <Button style={{ width: "150px" }} type="primary" htmlType="submit">
                    添加
                </Button>
            </Form.Item>
        </Form>
      </Modal>
      <Modal
        width="800px"
        open={showStep}
        onCancel={(e) => setShowStep(false)}
        footer={null}
        wrapClassName="step-modal"
      >
        <div style={{ width: "200px", margin: "0 auto" }}>
          <Steps
            current={current}
            onChange={(e) => {
              setCurrent(e);
            }}
          >
            <Step key={0} title="" />
            <Step key={1} title="" />
          </Steps>
        </div>
        {current === 0 && (
          <div className="flex step-content">
            <div
              className="step-item"
              onClick={(e) => {
                setCurrent(1);
              }}
            >
              <HomeOutlined style={{ fontSize: "55px" }} />
              <div>公司用户</div>
            </div>
            <div
              className="step-item"
              onClick={(e) => {
                setRegisterShow(true);
              }}
            >
              <PartitionOutlined style={{ fontSize: "55px" }} />
              <div>机构用户</div>
            </div>
          </div>
        )}
        {current === 1 && userType === 1 && (
          <div className="flex step-content">
            <div className="step-item">
              <span>已注册公司</span>
              <div>
                <Link to="/HomePage/UserInfo">
                  前往完善资料
                  <ArrowRightOutlined />
                </Link>
              </div>
            </div>
            <div className="step-item2">
              <span>还未注册公司</span>
              <div>
                <Button type="ghost">自行注册</Button>
                <Button
                  type="primary"
                  onClick={(e) => {
                    setRegisterShow2(true);
                  }}
                  style={{ marginLeft: "10px" }}
                >
                  平台代注册
                </Button>
              </div>
            </div>
          </div>
        )}
      </Modal>
      <Modal width="60%"
      open={showCompanyList}
      title="账号列表"
      onCancel={(e) => setShowCompanyList(false)}
      footer={null}>
      <div>
      <div className="organList-content flex-start">
            {companyList.map((data,index) => {
                return (
                    <div className="organ-item">
                        <div className="organ-title">{data.name}</div>
                        <div className="organ-phone">手机账号:{data.phone}</div>
                        <div className="organ-btn">
                            <Button  onClick={e => chooseCompany(data,index)} type="primary">
                                选择账号进入
                            </Button>
                        </div>
                    </div>
                );
            })}
        </div>
      </div>
      </Modal>
    </div>
  );
}
