import "./goodsInfo.less";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import StoreTop from "../../components/shoppingMall/storeTop";
import { Button, Divider, Image, InputNumber, Popover, Radio } from "antd";
import {
  StepBackwardOutlined,
  StepForwardOutlined,
  CaretRightOutlined,
  CloseOutlined,
  ArrowRightOutlined
} from "@ant-design/icons";
import {
  API_GetProductInfo,
  API_GetProductSpaces,
  API_getZlsqStoreDetail,
  API_placeOrder,
} from "../../service/zlsq-api";
import { dataUrl } from "../../config/utils";

export default function GoodsInfo(props) {
  const params = useParams();
  const [goodsInfo, setGoodsInfo] = useState({}); // 商品信息
  const [videoUrl, setVideoUrl] = useState(""); // 视频地址
  const [showVideo, setShowVideo] = useState(false); //视频展示
  const [specList, setSpecList] = useState([]); // 商品规格列表
  const [specTree, setSpecTree] = useState([]); // 商品规格树
  const [defaultItem, setDefaultItem] = useState([]); // 规格参数
  const [defaultSpecId, setDefaultSpecId] = useState(""); // 当前规格id
  const [defaultSpace, setDefaultSpace] = useState({}); // 当前规格
  const [buyNum, setBuyNum] = useState(1); // 购买数量
  const [imgIndex, setImgIndex] = useState(0);
  const [showMask, setShowMask] = useState(false);
  const [maskLocal, setMaskLocal] = useState({});
  const [largeStyle, setLargeStyle] = useState({});
  const [specLeft, setSpecLeft] = useState(0);
  const [specIndex, setSpecIndex] = useState(1);
  const [storeDetail,setStoreDetail]=useState({})
  const qrCode =
    "http://zjzlsq.cn/API/QRCode/GetSmallProgram_HaiPaiMaoQRCode?path=/hiShopping/pages/details/details?goodsId=" +
    params.id;
  useEffect(() => {
    API_GetProductInfo({ ID: params.id }).then((res) => {
      let goodsInfo = res.data;
      let videoUrl = goodsInfo.detailPictureList.filter((data) => {
        return ["mp4", "m3u8", "mov", "m4v", "3gp", "avi", "webm"].includes(
          data.split(".")[1]
        );
      })[0]; // 过滤视频(仅小程序支持格式)
      goodsInfo.imageList = goodsInfo.detailPictureList.filter((data) => {
        return ["jpg", "png", "gif", "svg", "webp", "jpeg"].includes(
          data.split(".")[1]
        );
      }); // 过滤图片(仅小程序支持格式)
      API_GetProductSpaces({ productId: params.id }).then((res) => {
        let spacesList = res.data;
        if (!spacesList.length) {
          return;
        }
        let specTree = [],
          specList = [],
          options = [];
        let c_list = spacesList[0].space.split(",");
        c_list.forEach((data, index) => {
          let key = data.split("-")[0];
          specTree.push({
            k: key,
            ks: "s" + index,
            v: [],
          });
        });
        spacesList.forEach((data) => {
          let optionKeys = data.space.split(",");
          optionKeys.forEach((optionkey, optionIndex) => {
            let key = optionkey.split("-")[0];
            let val = optionkey.split("-")[1];
            options[optionIndex] = val;
            specTree.forEach((t_item) => {
              if (t_item.k === key) {
                let v_item = {
                  id: val,
                  name: val,
                };
                if (optionIndex === 0) {
                  console.log(data.picture);
                  v_item.imgUrl = data.picture
                    ? dataUrl + data.picture
                    : dataUrl + goodsInfo.detailPicture[0];
                }
                let add = true;
                t_item.v.forEach((elm) => {
                  if (elm.id === val) {
                    add = false;
                  }
                });
                if (add) {
                  t_item.v.push(v_item);
                }
              }
            });
          });
          let spaceItem = {
            id: data.ID,
            price: data.price,
            stock_num: data.stock,
            picture: data.picture ? data.picture : goodsInfo.detailPicture[0],
          };
          options.forEach((data, index) => {
            spaceItem["s" + index] = data;
          });
          specList.push(spaceItem);
        });
        let defaultItem = [];
        specTree.forEach((data, index) => {
          defaultItem[index] = data.v[0].id;
        });
        console.log(goodsInfo);

        setSpecList(specList);
        setSpecTree(specTree);
        setDefaultItem(defaultItem);
        setDefaultSpecId(specList[0].id);
      });

      setGoodsInfo(goodsInfo);
      setVideoUrl(videoUrl);
      API_getZlsqStoreDetail(goodsInfo.sellerID).then(res=>{
        setStoreDetail(res.data)
      })
    });
  }, [params]);

  // 切换规格
  const onChangeSpec = (index, name) => {
    console.log(index, name);
    defaultItem[index] = name;
    let initSpec = specList;
    defaultItem.forEach((data, index) => {
      initSpec = initSpec.filter((node) => node["s" + index] === data);
    });
    setDefaultItem([...defaultItem]);
    setDefaultSpecId(initSpec[0].id);
  };

  //
  useEffect(() => {
    let defaultSpace = specList.find((data) => data.id === defaultSpecId);
    setDefaultSpace(defaultSpace || {});
  }, [defaultSpecId]);

  // 鼠标移动
  const onMouseMovePreview = (e) => {
    let { offsetX, offsetY } = e.nativeEvent;
    let initX = offsetX - 112.5;
    let initY = offsetY - 112.5;
    if (initX < 0) initX = 0;
    if (initY < 0) initY = 0;
    if (initX > 225) initX = 225;
    if (initY > 225) initY = 225;
    setMaskLocal({
      left: initX,
      top: initY,
    });
    setLargeStyle({
      left: -2 * initX,
      top: -2 * initY,
    });
  };
  // 移动规格图片列表
  const onMoveSpecList = (step) => {
    let totalNum = Math.ceil(goodsInfo.detailPicture.length / 5);
    let initIndex = specIndex + step; // 变动后页码
    let initLeft = specLeft - step * 360; // 移动距离
    if (initIndex === totalNum) {
      initLeft = -(goodsInfo.detailPicture.length / 5 - step) * 350 - 2;
      initIndex = specIndex;
    }
    if (initIndex === 0 || totalNum === 1) {
      initLeft = 0;
      initIndex = 1;
    }
    setSpecLeft(initLeft);
    setSpecIndex(initIndex);
  };
  // 提交
  const onSubmitOrder = () => {
    let reqData = {
      productID: goodsInfo.ID,
      amount: buyNum,
      productSpaceID: defaultSpecId,
      purchaseType: 5,
    };
    API_placeOrder(reqData).then((res) => {});
  };
  const [payType, setPayType] = useState(1);
  const PlaceOrder = () => {
    return (
      <div>
        <Radio.Group
          onChange={(e) => {
            setPayType(e.target.value);
          }}
          value={payType}
        >
          <Radio value={1}>线上小额支付</Radio>
          <Radio value={2}>线下大额支付</Radio>
        </Radio.Group>
        <Divider></Divider>
        <div>
          {payType == 1 ? (
            <div className="payItem">
              <div>微信扫一扫下单支付</div>
              <Image width="150px" height='150px' src={qrCode} />
            </div>
          ) : (
            <div className="flex">
            <div className="payItem">
              <div>线下转账途径</div>
              {storeDetail.PaymentChannels.map(data=>{
                return <Image width="150px" height='150px' key={data} src={dataUrl+data} />
              })}
            </div>
            <ArrowRightOutlined style={{fontSize:'30px',margin:'0 20px'}} />
            <div className="payItem">
              <div>微信扫码下单上传转账记录</div>
              <Image width="150px" height='150px' src={qrCode} />
            </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <div>
      <StoreTop id={goodsInfo.sellerID}></StoreTop>
      <div className="goods_box">
        <div className="goods_preview">
          <div className="preview">
            <div
              className="preview_mask"
              onMouseEnter={() => setShowMask(true)}
              onMouseMove={onMouseMovePreview}
              onMouseOut={() => setShowMask(false)}
            ></div>
            {goodsInfo.detailPicture && (
              <img
                className="preview_img"
                src={dataUrl + goodsInfo.detailPicture[imgIndex]}
                alt="商品图片"
              ></img>
            )}
            {goodsInfo.detailPicture && showMask && (
              <>
                <div className="mask_block" style={maskLocal}></div>
                <div className="preview_large_area">
                  <img
                    className="preview_large_img"
                    style={largeStyle}
                    src={dataUrl + goodsInfo.detailPicture[imgIndex]}
                    alt="商品图片"
                  ></img>
                </div>
              </>
            )}
            {!showVideo && videoUrl && (
              <div className="video_play" onClick={() => setShowVideo(true)}>
                <CaretRightOutlined />
              </div>
            )}
            {showVideo && (
              <>
                <CloseOutlined
                  className="video_close"
                  onClick={() => setShowVideo(false)}
                />
                <video
                  className="preview_video"
                  src={dataUrl + videoUrl}
                  onEnded={() => setShowVideo(false)}
                  controls
                  autoPlay
                ></video>
              </>
            )}
          </div>
          {goodsInfo.detailPicture && (
            <div className="goods_spec_area">
              <div className="goods_arrow" onClick={() => onMoveSpecList(-1)}>
                <StepBackwardOutlined />
              </div>
              <div className="goods_spec">
                <div className="goods_spec_list" style={{ left: specLeft }}>
                  {goodsInfo.detailPicture.map((data, index) => (
                    <img
                      className={
                        imgIndex === index
                          ? "spec_img spec_selected"
                          : "spec_img"
                      }
                      onMouseEnter={() => setImgIndex(index)}
                      src={dataUrl + data}
                      alt="商品图片"
                      key={data}
                    ></img>
                  ))}
                </div>
              </div>
              <div className="goods_arrow" onClick={() => onMoveSpecList(1)}>
                <StepForwardOutlined />
              </div>
            </div>
          )}
        </div>
        <div className="goods_info">
          <div className="goods_name">{goodsInfo.name}</div>
          <div className="goods_price_box">
            <div className="price_meta">价格</div>
            <div className="price_area">
              <span className="price_yen">¥</span>
              <span className="prece_num">
                {defaultSpace.price / 100 || goodsInfo.price/100}
              </span>
            </div>
          </div>
          {specTree.map((data, index) => (
            <div className="goods_attr" key={data.ks}>
              <div className="attr_label attr_lh48">{data.k}</div>
              <div className="attr_value">
                {data.v.map((v) => (
                  <div
                    className={
                      defaultItem[index] === v.id
                        ? "sku_item sku_selected"
                        : "sku_item"
                    }
                    onClick={() => onChangeSpec(index, v.id)}
                    key={v.id}
                  >
                    {v.imgUrl && (
                      <img
                        className="sku_img"
                        src={v.imgUrl}
                        alt="商品图片"
                      ></img>
                    )}
                    <div className="sku_name">{v.name}</div>
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div className="goods_attr">
            <div className="attr_label">数量</div>
            <InputNumber
              size="large"
              min={1}
              defaultValue={1}
              onChange={(val) => setBuyNum(val)}
            />
          </div>
          <div className="goods_attr">
            <div className="attr_label"></div>
            <Popover
              trigger="click"
              content={<PlaceOrder></PlaceOrder>}
              title="支付方式"
            >
              <Button
                style={{ width: "213px" }}
                size="large"
                type="primary"
                danger
              >
                立即购买
              </Button>
            </Popover>
          </div>
        </div>
      </div>
      <div className="goods_desc">
        <div dangerouslySetInnerHTML={{ __html: goodsInfo.description }}></div>
      </div>
    </div>
  );
}
