import { useState } from "react";
import FileContent from "@/components/fileContent";
import { Empty, Modal } from "antd";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
const FileModal = forwardRef(
    (props,ref)=>{
        const [fileContentShow,setFileContentShow]=useState(false)
        const [fileTitle,setFileTitle]=useState(props.title);
        const [fileContent,setFileContent]=useState(props.fileList||[]);

        useImperativeHandle(ref,e=>{
            return {
                show:()=>{
                    setFileContentShow(true)
                },
                setFileContent:e=>{
                    setFileContent(e)
                }
            }
        
        })
    
       return <>
          {props.button&&<div  onClick={e=>setFileContentShow(true)}>{props.button}</div>}
          <Modal
            open={fileContentShow}
            title={fileTitle}
            footer={null}
            onCancel={(e) => {
              setFileContentShow(false);
            }}
            wrapClassName="myInvoice-modal"
            width="80%"
            maskClosable={false}
            destroyOnClose={true}
          >
             {fileContent.map(res=>(
              <FileContent width='200px' url={res}></FileContent>
             ))}
                {fileContent.length==0&&<Empty></Empty>}
          </Modal>
       </>
    }
)

export default FileModal;