
import { Form, Input, Button, Checkbox, Upload, message } from "antd";
import { UploadOutlined, InboxOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Map, Marker, InfoWindow } from "react-amap";
import './register.less'
import { API_organRegister } from "../../service/common";
import AddressPicker from "../../components/addressPicker/addressPicker";
import { uploadHeader } from "../../utils/utils";
const OrganRegister = (props) => {
  const { showModal, organData } = props;
  const [showModal2, setShowModal2] = useState(false)
  const [form] = Form.useForm();
  const onFinish = (values) => {
    values.serviceType = [0, 1, 2, 3, 4];
    values.businessLicense = businessLicense;
    values.specialLicense = specialLicense;
    values.address = {
      point: lnglat,
      address: address
    }
    console.log('Success:', values);
    API_organRegister(values).then(res => {
      message.success('申请成功!');
      setShowModal2(true)
    })
  };



  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const [businessLicense, setBusinessLicense] = useState('');
  const normFile = (e) => {
    let response = e.file.response
    if (response) {
      if (response.status == 'OK') {
        setBusinessLicense(response.data)
      } else {
        message.error("上传失败!")
      }
    }
  };

  const [specialLicense, setSpecialLicense] = useState('');
  const normFile2 = (e) => {
    let response = e.file.response
    if (response) {
      if (response.status == 'OK') {
        setSpecialLicense(response.data)
      } else {
        message.error("上传失败!")
      }
    }
  };


  const [lnglat, setLnglat] = useState([]);
  const [address, setAddress] = useState(null);

  const getAddress = (point, address) => {
    setAddress(address);
    setLnglat(point)
  }

  return (
    <div className="organRegister-page">
      {(showModal || showModal2) ? (
        <div>
          <div className="wait">该账号的机构申请正在审核中...</div>
          {/* <div style={{ textAlign: "center" }}>
            <Button
              type="primary"
              size="large"
              onClick={(e) => {
                props.setShowModal(false);
              }}
              style={{ marginTop: "5px" }}
            >
              修改申请
            </Button>
          </div> */}
        </div>
      ) : (
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
          size="large"
        >
          <Form.Item
            label="机构名称"
            name="organName"
            rules={[{ required: true, message: "请输入机构名称" }]}
          >
            <Input placeholder="请输入机构名称" />
          </Form.Item>

          <Form.Item
            label="联系人"
            name="legalPerson"
            rules={[{ required: true, message: "请输入联系人" }]}
          >
            <Input placeholder="请输入联系人" />
          </Form.Item>

          <Form.Item
            label="联系电话"
            name="phone"
            rules={[{ required: true, message: "请输入联系电话" }]}
          >
            <Input placeholder="请输入联系电话" />
          </Form.Item>

          <Form.Item label="营业执照">
            <Form.Item
              name="businessLicense"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              noStyle
            >
              <Upload.Dragger
                maxCount={1}
                headers={uploadHeader()}
                name="file"
                action={process.env.REACT_APP_API+"Upload/FileUpload"}
              >
                {businessLicense ? (
                  <img className="uploadImg" src={businessLicense} />
                ) : (
                  <>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      点击选择或者拖拽图片到此区域
                    </p>
                  </>
                )}
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>

          <Form.Item label="行业证书">
            <Form.Item
              name="specialLicense"
              valuePropName="fileList"
              getValueFromEvent={normFile2}
              noStyle
            >
              <Upload.Dragger
                maxCount={1}
                name="file"
                headers={uploadHeader()}
                action={process.env.REACT_APP_API+"Upload/FileUpload"}
              >
                {specialLicense ? (
                  <img className="uploadImg" src={specialLicense} />
                ) : (
                  <>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      点击选择或者拖拽图片到此区域
                    </p>
                  </>
                )}
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>

          <Form.Item label="机构地址">
            <div className="flex-start">
              <Input
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                value={address}
              ></Input>
              <AddressPicker getAddress={getAddress}></AddressPicker>
            </div>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button style={{ width: "150px" }} type="primary" htmlType="submit">
              申请
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
}

export default OrganRegister;