import { List, Badge, Button, Modal } from "antd";
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { API_getMsgs, API_readMsgs } from "@/service/common"
import { API_readSingleMsgs } from "../../service/common";

export default function Messages() {
    const [msgList, setMsgList] = useState([]);
    const navigate = useNavigate()
    useEffect(() => {
        API_getMsgs().then(res => {
            setMsgList(res.data);
           
        })
    }, []);
    const onMessageNavPage = (item) => {
        API_readSingleMsgs({id:item.smsId}).then(res=>{
            item.readState='已读'
        })
        let userType=localStorage.getItem('userType');
        if(userType!=4){
            navigate('/taxpayer/invoiceManage');
            return
        }else{
            switch (item.type) {
                case '发票申请':
                    navigate('/organ/invoiceManage/1')
                    break;
                case '发票废弃':
                    navigate('/organ/invoiceManage/8')
                    break;
                case '签约申请':
                    navigate('/organ/companyManage/1')
                    break;
                case '合作社绑定申请':
                    navigate('/organ/cooperativeManage/1')
                    break;
                default:
                    break;
            }
        }
       
    }

    const readAll = () => {
        Modal.confirm({
            title: '提示',
            content: '确定全部已读？',
            onOk: () => {
                API_readMsgs().then(res=>{
                    msgList.map(row=>{
                        row.readState='已读'
                    })
                    setMsgList([...msgList])
                })
            }
        })
    }
    return (
        <div className="custom_auto">
            <div className="custom_pack">
                <Button type="primary" onClick={readAll}>全部已读</Button>
                <div className="custom_message_scroll">
                    <List  dataSource={msgList} renderItem={(item) =>
                        <Badge.Ribbon text={item.readState} color={item.readState === '未读' ? 'red' : 'blue'}>
                            <List.Item style={{cursor:'pointer'}} onClick={() => onMessageNavPage(item)}>
                                <List.Item.Meta
                                    title={item.type+'-------'+item.createTime}
                                    description={item.content}
                                />
                            </List.Item>
                        </Badge.Ribbon>
                    }>
                    </List>
                </div>
            </div>
        </div>
    )
}