import {
  Button,
  Table,
  Select,
  Modal,
  Switch,
  Form,
  message,
  Upload,
  Image,
  Input,
  DatePicker,
} from "antd";
import {
  API_getContractList,
  API_addContract,
  API_editContract,
  API_deleteContract,
} from "@/service/common";
import React, { useEffect, useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import FileContent from "@/components/fileContent";
import { API_activateContract } from "../../../service/common";
import { uploadHeader } from "../../../utils/utils";
import FileList from "../../../components/fileList/fileList";
import dayjs from 'dayjs';
import FileModal from "../../../components/fileModal/fileModal";
/**
 * @fileName 合同管理
 * @param {*} props
 * @returns
 */
const { Option } = Select;
export default function ContractManage(props) {
  const {RangePicker}=DatePicker;
  const [rangeTime,setRangeTime]=useState([]);
  const [text,setText]=useState('')
  const [form] = Form.useForm();
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(6);
  const [applyList, setApplyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [total, setTotal] = useState(0);
  const [fileData, setFileData] = useState([]);
  const [invoiceShow, setInvoiceShow] = useState(false);
  const [editID, setEditID] = useState(0);
  const [checked, setChecked] = useState(true);
  const [onlyUseless,setOnlyUseless]=useState(false)
  const fileRef=useRef()
  const getList = () => {
    let reqData={
      pageindex: pageIndex,
      pagesize: pageSize,
      Header:text
    }
    if(onlyUseless){
      reqData.onlyuseless=true
    }else{
      reqData.Onlynormal=true
    }
    if(rangeTime.length==2){
       reqData.CreateTimeStart=rangeTime[0]+' 00:00:00';
       reqData.CreateTimeEnd=rangeTime[1]+' 23:59:59'
    }
    API_getContractList(reqData).then((res) => {
      setApplyList(res.data);
      setLoading(false);
      setTotal(res.total);
    });
  };

  const formateRange = (e)=>{
    if(!e){setRangeTime([])}
    let list=[];
    e.map(row=>{
      list.push(dayjs(row).format('YYYY-MM-DD'))
    })
    setRangeTime(list)
  }

  const onFinish = (values) => {
    if(!fileData){
      message.error("请重新上传合同文件")
      return
    }
    message.loading("正在提交")
    let reqData = { ...values };
    reqData.conteactTextLink = fileRef.current.returnFileList().join(',');
    reqData.isCanSeekContract=checked
    if (editID > 0) {
      reqData.contractId = editID;

      API_editContract(reqData).then((res) => {
        message.success("修改成功!");
        setInvoiceShow(false);
        getList();
      });
    } else {
      API_addContract(reqData).then((res) => {
        message.success("添加成功!");
        setInvoiceShow(false);
        getList();
      });
    }
  };

  const deleteRecord = (item) => {
    Modal.confirm({
      content: "是否确认删除?",
      onOk: (e) => {
        API_deleteContract({ id: item.contractId }).then((res) => {
          message.success("删除成功!");
          Modal.destroyAll();
          getList();
        });
      },
    });
  };

  const onUpload2 = (e, record) => {
    let response = e.file.response;
    console.log(e)
    if(e.file.percent==0){
      message.loading(`正在上传,请勿操作`,0)
    }
    if (response) {
      if (response.status == 'OK') {
        message.success("上传成功!");
        fileData.push(response.data)
        setFileData([...fileData])
      } else {
        message.error("上传失败!")
      }
    }
  };
  useEffect(
    (e) => {
      setApplyList([]);
      setLoading(true);
      getList();
    },
    [pageIndex]
  );
  const onChange = (e) => {
    setPageIndex(e);
  };

  const activate = (e) => {
    Modal.confirm({
      content: `是否确认${e.statue == "正常" ? "作废" : "启用"}该合同?`,
      onOk: () => {
        API_activateContract({ id: e.contractId }).then((res) => {
          message.success(`${e.statue == "正常" ? "作废" : "启用"}成功!`);
          Modal.destroyAll();
          getList();
        });
      },
    });
  };

  const columns = [
    {
      title: "合同名称",
      dataIndex: "header",
      key: "header",
    },
    {
      title: "合同文件",
      align: "center",
      dataIndex: "conteactTextLink",
      render: (text, row) => <FileModal title='合同文件' fileList={text.split(',')} button={<Button>查看文件</Button>}></FileModal>,
    },
    {
      title: "是否允许机构查看",
      dataIndex: "isCanSeekContract",
      key: "isCanSeekContract",
      render: (text, record) => (text ? "是" : "否"),
    },
    {
      title: "合同状态",
      dataIndex: "statue",
      key: "statue",
    },
    {
      title: "修改状态",
      dataIndex: "statue",
      key: "statue",
      render: (text, record) =>
        text == "正常" ? (
          <Button
            style={{ marginLeft: "10px" }}
            danger
            type="primary"
            onClick={(e) => activate(record)}
          >
            作废
          </Button>
        ) : (
          <Button
            style={{ marginLeft: "10px" }}
            type="primary"
            onClick={(e) => activate(record)}
          >
            启用
          </Button>
        ),
    },
    {
      title: "添加时间",
      dataIndex: "createTime",
      key: "createTime",
    },
    {
      title: "操作",
      dataIndex: "handleResult",
      render: (text, record) =>
        props.choose ? (
          <div>
            <Button
              style={{ marginLeft: "10px" }}
              danger
              type="primary"
              onClick={(e) => props.chooseFun(record)}
            >
              选择
            </Button>
          </div>
        ) : (
          <div>
            <Button
              type="primary"
              onClick={(e) => {
                setInvoiceShow(true);
                setEditID(record.contractId);
                form.setFieldsValue(JSON.parse(JSON.stringify(record)));
                setChecked(record.isCanSeekContract);
                setFileData(record.conteactTextLink.split(','));
              }}
            >
              编辑
            </Button>
            <Button
              style={{ marginLeft: "10px" }}
              danger
              type="primary"
              onClick={(e) => deleteRecord(record)}
            >
              删除
            </Button>
          </div>
        ),
    },
  ];

  return (
    <div className="custom_auto">
      <div className="custom_pack custom_filter">
        
        <div className="flex-start">
          <div className="flex-text">合同状态:</div>
          <div>
            {" "}
            <Select
              style={{ width: 120 }}
              value={onlyUseless}
              onChange={(e) =>
                setOnlyUseless(e)
              }
            >
              <Option value={false}>已启用</Option>
              <Option value={true}>已作废</Option>
            </Select>
          </div>
        </div>
        <div>
           <RangePicker   onChange={e=>formateRange(e)}   format="YYYY-MM-DD"> </RangePicker>
        </div>
        <div><Input placeholder="输入合同名称搜索" onChange={e=>setText(e.target.value)} allowClear></Input></div>
        <Button
          onClick={(e) => {
            getList();
          }}
          type="primary"
          style={{backgroundColor:'#0bb90b'}}
        >
          搜索
        </Button>
        <Button
          onClick={(e) => {
            setInvoiceShow(true);
            form.resetFields();
            setFileData([]);
            setEditID(0);
          }}
          type="primary"
        >
          新增
        </Button>
      </div>
      <div className="custom_pack">
        <Table
          dataSource={applyList}
          columns={columns}
          loading={loading}
          rowKey={(columns) => columns.id}
          scroll={{
            y: `calc(100vh - 396px)`,
            scrollToFirstRowOnChange: true,
          }}
          pagination={{
            pageSize: pageSize,
            total: total,
            onChange: onChange,
            pageSizeOptions: [],
          }}
        />
      </div>
      <Modal
        open={invoiceShow}
        footer={null}
        onCancel={(e) => {
          setInvoiceShow(false);
        }}
        title="合同详情"
        wrapClassName="myInvoice-modal"
        width="60%"
        maskClosable={false}
        destroyOnClose={true}
      >
        <Form
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          size="large"
          form={form}
        >
          <Form.Item
            label="合同名称"
            name="header"
            required
            rules={[{ required: true, message: "请输入文件名称" }]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label="合同文件"
            required
            rules={[{ required: true, message: "请输入地址电话" }]}
          >
          <div className="flex-start2">
             <FileList ref={fileRef} width='100px' fileList={fileData}></FileList>
             <Upload
               headers={uploadHeader()}
               onChange={(e) => onUpload2(e)}
               itemRender={e=><></>}
               action={process.env.REACT_APP_API+"Upload/FileUpload"}
             >
                <div className="flex-center image-select" >
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>点击上传</div>
                </div>
             </Upload>
          </div>
          </Form.Item>
          <Form.Item label="是否允许机构查看" name="isCanSeekContract">
            <Switch defaultChecked={checked} onChange={e=>setChecked(e)}></Switch>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <Button
              loading={loadingBtn}
              style={{ width: "150px" }}
              type="primary"
              htmlType="submit"
            >
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
