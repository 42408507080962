
import { useEffect, useState } from 'react'
import './storeTop.less'
import { Input } from 'antd'
import { API_getZlsqStoreDetail } from '../../service/zlsq-api'
import { dataUrl, getQuery } from '../../config/utils'
const {Search}=Input
const StoreTop =(props)=> {
  const [storeDetail,setStoreDetail]=useState({})
  const [keyword,setKeyword]=useState('')
   useEffect(e=>{
    if(props.id){
      API_getZlsqStoreDetail(props.id).then(res=>{
        setStoreDetail(res.data)
        console.log(res.data)
      })
    }
    setKeyword(getQuery('keyword'))
   },[props.id])

    return (
      <div className="StoreTop">
         <div className='content flex'>
             <div className='flex-start'>
             <img className='store_logo' src={require('../../imgs/logo.png')} />
                <img className='store_img' src={dataUrl+storeDetail.SignImg} />
                <div className='store_info'>
                    <div className='store_name'>{storeDetail.Name}</div>
                    <div className='store_des'>店铺地址:{storeDetail.Address},联系方式:{storeDetail.Phone}</div>
                </div>
             </div>
             <div className='store_search'>
             </div>
         </div>
      </div>
    )
}

export default StoreTop
