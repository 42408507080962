import { Button, Divider, Input, Modal, Table } from "antd"
import { useEffect, useState } from "react"
import { API_getBalance, API_walletRecharge } from "@/service/common"
const Wallet = () => {
    const [balance, setBalance] = useState(0);
    const [qrCode, setQrCode] = useState('');
    const [rechargeShow, setRechargeShow] = useState(false);
    const [rechargeMoney, setRechargeMoney] = useState('');
    const getBalance = () => {
        API_getBalance().then(res => {
            setBalance(res.data.toFixed(2))
        })
    }
    useEffect(e => { getBalance() }, []);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [applyList, setApplyList] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const onChange = (e) => {
        setPageIndex(e);
    };
    const columns = [
        {
            title: "类型",
            dataIndex: "firstName",
            key: "firstName",
        },
        {
            title: "金额",
            dataIndex: "contacts",
            key: "contacts",
        },
        {
            title: "时间",
            dataIndex: "phone",
            key: "phone",
        }
    ];

    const reCharge = () => {
        let reqData = {
            userid: localStorage.getItem("userID"),
            money: rechargeMoney * 100,
            payType: 1
        }
        API_walletRecharge(reqData).then(res => {
            console.log(res)
            setQrCode(res.data)
        })
    }

    const submit = () => {
        Modal.destroyAll();
        getBalance()
    }
    return (
        <div className="custom_auto">
            <div className="custom_pack">
                <h2>余额: ¥<span>{balance}</span></h2>
                <Button type="primary" onClick={e => setRechargeShow(true)}>充值</Button>
            </div>
            <div className="custom_pack">
                <Table
                    columns={columns}
                    loading={loading}
                    pagination={{
                        pageSize: pageSize,
                        total: total,
                        onChange: onChange,
                        pageSizeOptions: [],
                    }}
                    dataSource={applyList}
                />
            </div>
            <Modal
                open={rechargeShow}
                width="500px"
                footer={null}
                onCancel={(e) => {
                    setRechargeShow(false);
                }}
                wrapClassName="myInvoice-modal"
                title="输入充值金额"
                maskClosable={false}
                destroyOnClose={true}
            >
                <Input value={rechargeMoney} type='number' onChange={e => setRechargeMoney(e.target.value)} placeholder='请输入需要充值的金额'></Input>
                <div style={{ marginTop: "20px" }}>
                    <Button type='primary' onClick={reCharge}>确定</Button>
                </div>
                {qrCode && <div className="qrCode">
                    <Divider >请使用微信扫一扫</Divider>
                    <img src={"https://zjzlsq.cn/API/QRCode/GetQRCode?content=" + qrCode} alt="" />
                    <div>
                        <Button type="primary" onClick={e => submit()}>我已充值</Button>
                    </div>
                </div>}
            </Modal>
        </div>)
}

export default Wallet