/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Table,
  Modal,
  Switch,
  Form,
  message,
  Upload,
  Input,
  Select,
  DatePicker,
} from "antd";
import {
  API_getTransactionList,
  API_addTransaction,
  API_updateTransaction,
  API_removeTransaction,
  API_getContractList,
} from "@/service/common";
import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import FileContent from "@/components/fileContent";
import { uploadHeader } from "../../../utils/utils";
import dayjs from 'dayjs';
import FileModal from "../../../components/fileModal/fileModal";
/**
 * @fileName 业务管理
 * @param {*} props
 * @returns
 */

export default function TransactionManage(props) {
  const {RangePicker}=DatePicker;
  const [rangeTime,setRangeTime]=useState([]);
  const [text,setText]=useState('')
  const [contractList, setContractList] = useState([]);
  const [transactionList, setTransactionList] = useState([]);
  const [reqTransactionInfo, setReqTransactionInfo] = useState({
    pageIndex: 1,
    pageSize: 10,
  });
  const [transactionLoading, setTransactionLoading] = useState(false);
  const [transactionTotal, setTransactionTotal] = useState(0);
  const [transForm] = Form.useForm();
  const [showTransInfo, setShowTransInfo] = useState(false);
  const [transaction, setTransaction] = useState([]);
  const [transactionId, setTransactionId] = useState("");


  // 获取列表
  const onGetTransactionList = () => {
    setTransactionLoading(true);
    let reqData={
      TransactionDescribe:text
    }
    if(rangeTime.length==2){
       reqData.CreateTimeStart=rangeTime[0]+' 00:00:00';
       reqData.CreateTimeEnd=rangeTime[1]+' 23:59:59'
    }
    API_getTransactionList({...reqTransactionInfo,...reqData}).then((res) => {
      setTransactionList(res.data);
      setTransactionLoading(false);
      setTransactionTotal(res.total);
    });
  };

  const formateRange = (e)=>{
    if(!e){setRangeTime([])}
    let list=[];
    e.map(row=>{
      list.push(dayjs(row).format('YYYY-MM-DD'))
    })
    setRangeTime(list)
  }

  useEffect(() => {
    onGetTransactionList();
  }, [
    reqTransactionInfo,
    reqTransactionInfo.pageIndex,
    reqTransactionInfo.pageSize,
  ]);

  useEffect(() => {
    API_getContractList({ pageIndex: 1, pageSize: 99 ,Onlynormal:true}).then((res) => {
      setContractList(res.data);
    });
  }, []);

  // 表格参数
  const columns = [
    {
      title: "业务名称",
      width: 200,
      align: "center",
      dataIndex: "transactionDescribe",
      fixed: "left",
    },
    {
      title: "合同名称",
      align: "center",
      dataIndex: "header",
      render: (text, row) => row.contractlist.header || "",
    },
    {
      title: "业务文件",
      align: "center",
      dataIndex: "transaction",
      render: (text, row) => <FileModal title='业务文件' fileList={text} button={<Button>查看文件</Button>}></FileModal>,
    },
    {
      title: "创建时间",
      align: "center",
      dataIndex: "createTime"
    },
    {
      width: 240,
      title: "操作",
      align: "center",
      fixed: "right",
      dataIndex: "operation",
      render: (text, row) => (
        <div className="custom_func_area">
          <Button onClick={() => handleEditTransInfo(row)} type="primary">
            编辑
          </Button>
          <Button
            onClick={(e) => handleDeleteTransInfo(row)}
            type="primary"
            danger
          >
            删除
          </Button>
        </div>
      ),
    },
  ];
  const handleEditTransInfo = (row) => {
    let initTrans = [];
    row.transaction.forEach((data) => {
      initTrans.push({
        url: data,
      });
    });
    setTransaction(initTrans);
    setTransactionId(row.id);
    transForm.setFieldsValue(row);
    setShowTransInfo(true);
  };
  const onEditTransInfo = (form) => {
    console.log(form, transaction);
    if (!transaction.length) {
      message.warning("请上传封面图");
      return;
    }
    let initTrans = [];
    transaction.forEach((data) => {
      initTrans.push(data.url || data.response.data);
    });
    form.transaction = initTrans;
    message.loading("正在提交")
    if (transactionId) {
      form.transactionId = transactionId;
      API_updateTransaction(form).then((res) => {
        message.success("编辑完成");
        setShowTransInfo(false);
        onGetTransactionList();
      });
    } else {
      API_addTransaction(form).then((res) => {
        message.success("添加完成");
        setShowTransInfo(false);
        onGetTransactionList();
      });
    }
  };
  const handleDeleteTransInfo = (row) => {
    Modal.confirm({
      content: "是否删除此数据?",
      cancelText: "取消",
      okText: "确认",
      centered: true,
      onOk: () => {
        API_removeTransaction({ id: row.id }).then((res) => {
          message.success("删除成功");
          onGetTransactionList();
        });
      },
    });
  };
  return (
    <div className="custom_auto">
      <div className="custom_pack custom_filter">
      <div>
           <RangePicker   onChange={e=>formateRange(e)}   format="YYYY-MM-DD"> </RangePicker>
        </div>
        <div><Input placeholder="输入业务名称搜索" onChange={e=>setText(e.target.value)} allowClear></Input></div>
        <Button
          onClick={(e) => {
            onGetTransactionList();
          }}
          type="primary"
          style={{backgroundColor:'#0bb90b'}}
        >
          搜索
        </Button>
        <Button
          onClick={(e) => {
            transForm.resetFields();
            setTransactionId("");
            setTransaction([]);
            setShowTransInfo(true);
          }}
          type="primary"
        >
          新增
        </Button>
      </div>
      <div className="custom_pack">
        <Table
          rowKey={(columns) => columns.id}
          dataSource={transactionList}
          loading={transactionLoading}
          columns={columns}
          pagination={{
            showTotal: () => `共 ${transactionTotal} 条`,
            pageSizeOptions: [10, 20, 50, 100],
            showSizeChanger: true,
            pageSize: reqTransactionInfo.pageSize,
            total: transactionTotal,
            onChange(pageIndex, pageSize) {
              setReqTransactionInfo({
                ...reqTransactionInfo,
                pageIndex,
                pageSize,
              });
            },
          }}
          scroll={{
            x: 800,
            y: `calc(100vh - 396px)`,
            scrollToFirstRowOnChange: true,
          }}
        />
      </div>
      <Modal
        open={showTransInfo}
        title="编辑业务"
        onCancel={() => {
          setShowTransInfo(false);
        }}
        onOk={() => transForm.submit()}
        afterClose={() => {
          transForm.resetFields();
          setTransactionId("");
        }}
        maskClosable={false}
        destroyOnClose={true}
      >
        <Form
          form={transForm}
          labelCol={{ flex: "100px" }}
          initialValues={{}}
          labelWrap
          onFinish={onEditTransInfo}
        >
          <Form.Item
            label="业务名称"
            name="transactionDescribe"
            rules={[{ required: true, message: "请填写业务名称" }]}
          >
            <Input placeholder="请填写业务名称"></Input>
          </Form.Item>
          <Form.Item
            label="合同"
            name="contractId"
            rules={[{ required: true, message: "请选择合同" }]}
          >
            <Select
              defaultValue={transForm.contractId}
              options={contractList}
              placeholder="请选择合同"
              fieldNames={{ label: "header", value: "contractId" }}
            />
          </Form.Item>
          <Form.Item
            label="业务文件"
            name="transaction"
            rules={[{ required: true, message: "请上传业务文件!" }]}
          >
            <div className="custom_upload_height">
              <Upload
              headers={uploadHeader()}
                action={process.env.REACT_APP_API+"Upload/FileUpload"}
                listType="picture-card"
                fileList={transaction}
                onChange={({ fileList }) => {
                  setTransaction(fileList);
                }}
              >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>点击上传</div>
                </div>
              </Upload>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
