import React, { forwardRef, useEffect, useState } from "react"
import FileContent from "../fileContent";
import { CloseCircleOutlined } from "@ant-design/icons";
const FileList =(props,ref)=> {
    const [fileList,setFileList]=useState([]);
    useEffect(()=>{
        setFileList(props.fileList)
    },[props.fileList])

    const del=(index)=>{
        fileList.splice(index,1);
        setFileList([...fileList])
    }

    React.useImperativeHandle(ref, (e) => ({
        returnFileList() {
            return fileList
      },
    }));

    return (
      <>
          {fileList.map((item,index)=>{
              return <div className="fileList-item" style={{width:props.width,height:props.width,marginRight:'10px',marginBottom:'10px'}}>
                 <span className="fileList-icon"><CloseCircleOutlined onClick={e=>del(index)} /></span>
                 <FileContent width={props.width}  url={item} key={index}/>
              </div>
          })}
      </>
    )
}

export default forwardRef(FileList)
