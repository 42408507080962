import { Button, Table, Image, Input, Form, Modal, message, Tag } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { API_approveBindOrgan, API_getCooperList, API_invitePersonToCooperative, API_searchUserByPhone } from "@/service/cooperative";
import { API_createCooperative, API_getMyCooperative } from "@/service/cooperative";
import { useParams } from "react-router-dom";
import AntdCell from "@/components/antdCell";
import { API_organList } from "@/service/common";
import InvoiceTemplate from "@/components/invoiceTemplate/invoiceTemplate";
import { API_CooperativeInvoicesApply } from "../../../service/common";

const CooperativeManage = (props) => {
    const { type } = useParams();
    const [form] = Form.useForm();
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [applyList, setApplyList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [registered, setRegistered] = useState(true);
    const [id, setId] = useState(0);
    const [info, setInfo] = useState({})
    const getList = () => {
        setLoading(true);
        API_getCooperList({
            pageindex: pageIndex,
            pagesize: pageSize,
            cooperativeid: id,
            accept: type,
            isoneself:false
        }).then((res) => {
            res.data.map(row => {
                row.memberPhone = row.members.membersResume.phone;
                row.memberName = row.members.membersResume.name
            })
            setApplyList(res.data);
            setLoading(false);
            setTotal(res.total);
        });
    };

    useEffect((e) => { getList(); }, [pageIndex, type]);

    const getMyInfo = () => {
        API_getMyCooperative().then((res) => {
            if (res.data.id) {
                setInfo(res.data);
                setApplyList([]);
                setRegistered(true);
                setId(res.data.cooperativeId);
                setOrganDetail(res.data.organDescribe)
            } else {
                setRegistered(false);
            }
        });
    }


    useState(e => {
        getMyInfo()
    }, [])
    const onChange = (e) => {
        setPageIndex(e);
    };

    const onFinish = (values) => {
        API_createCooperative(values).then((res) => {
            setRegistered(true);
            setId(res.data.cooperativeId);
        });
    };

    const [phoneOpen, setPhoneOpen] = useState(false);
    const [organOpen, setOrganOpen] = useState(false);
    const [searchPhone, setSearchPhone] = useState("");
    const [personData, setPersonData] = useState({});
    const [organList, setOrganList] = useState([]);
    const [organDetailOpen, setOrganDetailOpen] = useState(false);
    const [organDetail, setOrganDetail] = useState({});

    const search = () => {
        if (searchPhone) {
            API_searchUserByPhone({ phone: searchPhone }).then(res => {
                if (res.data.userID) {
                    setPersonData(res.data)
                } else {
                    message.error("未找到用户")
                }
            })
        }
    }

    const invite = () => {
        API_invitePersonToCooperative({ membersUserid: personData.userID, cooperativeId: id }).then(res => {
            setPhoneOpen(false);
            getList()
            message.success("邀请成功,请等待用户确认")
        })
    }

    useEffect(e => {
        API_organList({ onlyvalid: true }).then((res) => {
            setOrganList(res.data);
        });
    }, []);

    const choose = (data) => {
        setOrganOpen(false);
        API_approveBindOrgan({ cooperativeId: id, organid: data.organid }).then(res => {
            message.success("绑定申请已提交");
            getMyInfo()
        })
    }

    const [showInvoice, setShowInvoice] = useState(false)
    const [invoiceDetail, setInvoiceDetail] = useState();
    const [membersUserID, setMembersUserID] = useState(0)
    const ref = useRef();

    const columns = [
        {
            title: "邀请人",
            dataIndex: "memberName",
            key: "memberName",
        },
        {
            title: "邀请号码",
            dataIndex: "memberPhone",
            key: "memberPhone",
        },
        {
            title: "个体户名称",
            dataIndex: "members",
            key: "members1",
            render: (e) => {
                return e.companys[0].name
            }
        },
        {
            title: "营业执照",
            dataIndex: "members",
            key: "members1",
            render: (e) => {
                return <Image src={e.companys[0].license} width='40px' height='40px'></Image>
            }
        },
        {
            title: "邀请时间",
            dataIndex: "createTime",
            key: "createTime",
        },
        {
            title: "邀请状态",
            dataIndex: "state",
            key: "state",
            render: function (e, e1) {
                if (e === 1) {
                    return <Tag>待确认</Tag>
                } else if (e === 3) {
                    return <Tag color='#87d068'>已同意</Tag>
                } else if (e === 4) {
                    return <Tag color='red'>已拒绝</Tag>
                }
            },
        },
        {
            width: 120,
            title: '操作',
            fixed: 'right',
            align: 'center',
            render(row) {
                return <Button type="primary" onClick={() => onIndividualInvoice(row)}>开票</Button>
            }
        }
    ];
    const onIndividualInvoice = (row) => {
        setInvoiceDetail({ seller: row.members.companys[0], goodsList: [] })
        setShowInvoice(true)
        setMembersUserID(row.members.membersUserID)
    }
    const onInvoiceFinish = (values) => {
        let invoiceData = { ...ref.current.getInvoiceData() };
        console.log(invoiceData)
        let amount = 0;
        let validList = [];
        invoiceData.goodsList.map((data, index) => {
            if (data.priceNum > 0) {
                amount += data.priceNum * 1;
                validList.push(data);
            }
        });
        const reqData = {
            header: invoiceData.buyer.name,
            category: invoiceData.category,
            isCanSeekContract: true,
            type: invoiceData.type,
            money: amount,
            invoiceNotice: {
                remarks: invoiceData.remarks,
                seller: invoiceData.seller,
                buyer: invoiceData.buyer,
                time: invoiceData.time,
                goodsList: validList,
            },
            membersUserID
        }
        console.log(reqData)
        API_CooperativeInvoicesApply(
            Object.assign(reqData)
        ).then((res) => {
            message.success("申请成功!");
            setShowInvoice(false);
        });
    };
    const columns2 = [
        {
            title: "用户",
            type: "text",
            key: "name",
        },
        {
            title: "手机号码",
            type: "text",
            key: "phone",
        },
        {
            title: "公司名称",
            key: "companys",
            render: (e) => {
                return e[0].name
            }
        },
        {
            title: "营业执照",
            key: "companys",
            render: (e) => {
                return <Image width='100px' height='100px' src={e[0].license}></Image>
            }
        },
    ];

    const columns3 = [
        {
            title: "机构名称",
            type: "text",
            key: "organName",
        },
        {
            title: "联系人",
            type: "text",
            key: "legalPerson",
        },
        {
            title: "联系电话",
            key: "phone",
            type: "text",
        },
        {
            title: "机构地址",
            key: "address",
            render: (e, a) => {
                return <a target='_blank' href={`http://api.map.baidu.com/marker?location=${e.point[1]},${e.point[0]}&title=机构位置&content=${a.organName}&output=html&src=webapp.baidu.openAPIdemo`}>{e.address}</a>
            }
        },
    ];

    return (
        <div>
            {registered ? (
                <div className="custom_auto">
                    <div className="custom_pack custom_filter">
                        <Button type="primary" onClick={(e) => setPhoneOpen(true)}> 邀请个体户</Button>
                        <div style={{ marginLeft: "50px" }}>当前绑定机构:</div>
                        <div>{info.organid ? info.organDescribe.organName : '无'}{info.state == 1 && '(待通过)' || info.state == 3 && '(已通过)' || info.state == 4 && '(已拒绝)'}</div>
                        {info.organid && <Button style={{ marginLeft: "10px" }} type="primary" onClick={(e) => setOrganDetailOpen(true)}>
                            查看机构信息
                        </Button>}
                        <Button style={{ marginLeft: "10px" }} type="danger" onClick={(e) => setOrganOpen(true)}>
                            {info.organid ? "更换" : "前往"}绑定
                        </Button>
                    </div>
                    <div className="custom_pack">
                        <Table
                            columns={columns}
                            loading={loading}
                            pagination={{
                                pageSize: pageSize,
                                total: total,
                                onChange: onChange,
                                pageSizeOptions: [],
                            }}
                            dataSource={applyList}
                        />
                    </div>
                </div>
            ) : (
                <div className="custom_auto">
                    <div className="custom_pack">
                        <Form name="basic" labelCol={{ span: 3, offset: 0 }} initialValues={{ remember: true }} onFinish={onFinish} autoComplete="off" size="large" form={form}>
                            <Form.Item label="合作社名称" name="name" rules={[{ required: true, message: "请输入合作社名称" }]}>
                                <Input></Input>
                            </Form.Item>
                            <Form.Item label="服务项目" name="serviceItem" rules={[{ required: true, message: "请输入服务项目" }]}>
                                <Input></Input>
                            </Form.Item>
                            <Form.Item label="联系人" name="contacts" rules={[{ required: true, message: "请输入联系人" }]}>
                                <Input></Input>
                            </Form.Item>
                            <Form.Item label="联系电话" name="phone" rules={[{ required: true, message: "请输入联系电话" }]}>
                                <Input></Input>
                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 3, span: 16 }}>
                                <Button style={{ width: "150px" }} type="primary" htmlType="submit">
                                    更新
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            )}
            <Modal
                title="邀请个体户"
                footer={null}
                open={phoneOpen}
                onCancel={(e) => setPhoneOpen(false)}
            >
                <div className="flex-start">
                    <Input
                        onChange={(e) => { setSearchPhone(e.target.value) }}
                        type="number"
                        placeholder="输入个体户手机号"
                    ></Input>
                    <Button onClick={search} style={{ marginLeft: "10px" }} type="danger">
                        查询
                    </Button>
                </div>
                {personData.userID && <div>
                    <AntdCell columns={columns2} dataSource={personData}></AntdCell>
                    <Button onClick={invite} style={{ marginTop: "10px" }} type="primary">
                        邀请
                    </Button>
                </div>}
            </Modal>
            <Modal
                title="选择机构"
                width="70%"
                footer={null}
                open={organOpen}
                onCancel={(e) => setOrganOpen(false)}
            >
                <div className="organList-content flex-start">
                    {organList.map(data => {
                        return (
                            <div className="organ-item">
                                <div className="organ-title">{data.organName}</div>
                                <div className="organ-phone">联系电话:{data.phone}</div>
                                <div className="organ-btn">
                                    <Button onClick={e => choose(data)} type="primary">
                                        申请绑定
                                    </Button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Modal>
            <Modal
                title="机构信息"
                width="70%"
                footer={null}
                open={organDetailOpen}
                onCancel={(e) => setOrganDetailOpen(false)}
            >
                <AntdCell columns={columns3} dataSource={organDetail}></AntdCell>
            </Modal>
            <Modal
                open={showInvoice}
                title="增值税发票开票通知单"
                width='80vw'
                okText="确认"
                cancelText="取消"
                onOk={onInvoiceFinish}
                onCancel={() => setShowInvoice(false)}
            >
                <InvoiceTemplate invoiceDetail={invoiceDetail} ref={ref}></InvoiceTemplate>
            </Modal>
        </div>
    );
};

export default CooperativeManage;
