import {
  Button,
  Col,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Table,
  Tag,
} from "antd";
import { useEffect, useState } from "react";
import {
  API_organAddStaff,
  API_organUpdateStaff,
  API_organDeleteStaff,
  API_organGetStaff,
} from "../../../service/common";
import { EditOutlined ,DeleteOutlined} from "@ant-design/icons";
const StaffManagement = () => {
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSIze] = useState(10);
  const [applyList, setApplyList] = useState([]);
  const [total, setTotal] = useState(0);

  const columns = [
    { title: "姓名", align: "center", key: "name", dataIndex: "name" },
    {
      title: "手机号",
      key: "phone",
      dataIndex: "phone",
      align: "center",
    },
    {
      title: "注册时间",
      key: "createTime",
      align: "center",
      dataIndex: "createTime",
    },
    {
      title: "操作",
      key: "enable",
      dataIndex: "enable",
      align: "center",
      render: function (enable, itemData) {
        return (
          <div>
            <Button
              type="primary"
              icon={<DeleteOutlined />}
              onClick={(e) => changeUserStatus(itemData)}
              danger
            >
              删除
            </Button>
          </div>
        );
      },
    },
  ];

  const onChange = (e) => {
    setPageIndex(e);
  };

  const getUserList = () => {
    API_organGetStaff({ PageIndex: pageIndex, pageSize: 20 }).then((res) => {
      setApplyList(res.data);
      setTotal(res.total);
    });
  };

  const changeUserStatus = (itemData) => {
    Modal.confirm({
      title: "确认删除",
      content: "确认删除该员工吗？",
      onOk: () => {
        let reqData = {
          id: itemData.id
        };
        API_organDeleteStaff(reqData).then((res) => {
          message.success("操作成功");
          getUserList();
        });
      }
    });
  }



  useEffect(
    (e) => {
      getUserList();
    },
    [pageIndex]
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = () => {
    setLoading(true);
    if (userInfo.id) {
      API_organUpdateStaff(userInfo)
        .then((res) => {
          message.success("修改成功");
          getUserList();
          setIsModalOpen(false);
          setUserInfo({});
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      userInfo.address="";
      userInfo.portrait=""
      API_organAddStaff(userInfo)
        .then((res) => {
          message.success("新增成功");
          getUserList();
          setIsModalOpen(false);
          setUserInfo({});
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const changeType = (e) => {
    userInfo.userType = e.target.value;
    setUserInfo({...userInfo});
  };

  const changeInput = (e, key) => {
    userInfo[key] = e.target.value;
    setUserInfo({...userInfo});
  };

  const showUserDetail = (e) => {
    setUserInfo(e);
  };
  useEffect(() => {
    if (userInfo.id) {
      setIsModalOpen(true);
    }
  }, [userInfo]);

  useEffect(
    (e) => {
      if (!isModalOpen) {
        setUserInfo({});
      }
    },
    [isModalOpen]
  );
  return (
    <div className="custom_auto">
      <div className="flex-start table-top">
        <Button
          onClick={(e) => {
            setIsModalOpen(true);
          }}
          type="primary"
        >
          新增
        </Button>
      </div>
      <Table
        columns={columns}
        pagination={{
          pageSize: pageSize,
          total: total,
          onChange: onChange,
          pageSizeOptions: [],
        }}
        dataSource={applyList}
      />
      <Modal
        open={isModalOpen}
        title="用户信息"
        destroyOnClose
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            保存
          </Button>,
        ]}
      >
        <div className="input-modal">
          <Row>
            <Col span={6}>姓名:</Col>
            <Col span={18}>
              <Input
                defaultValue={userInfo.name}
                onChange={(e) => changeInput(e, "name")}
              ></Input>
            </Col>
          </Row>
          <Row>
            <Col span={6}>电话:</Col>
            <Col span={18}>
              <Input
                defaultValue={userInfo.phone}
                type="number"
                onChange={(e) => changeInput(e, "phone")}
              ></Input>
            </Col>
          </Row>
          {!userInfo.userID && (
            <Row>
              <Col span={6}>登录密码:</Col>
              <Col span={18}>
              默认123456
              </Col>
            </Row>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default StaffManagement;
