


import Index from '../views/index';
import Login from '../views/login';
import Messages from '../views/common/messages';
import Wallet from '../views/taxpayer/wallet';
import TaxpayerIndex from '../views/taxpayer/index';
import Dashboard from '../views/taxpayer/dashboard';
import TaxpayerInfo from '../views/taxpayer/infoManage/taxpayerInfo';
import UnitInfo from '../views/taxpayer/infoManage/unitInfo';
import InvoiceDownload from '../views/taxpayer/invoiceManage/invoiceDownload';
import ContractManage from '../views/taxpayer/fourStreamManage/contractManage';
import ContractManage2 from '../views/taxpayer/cooperativeManage/contractManage';
import TransactionManage from '../views/taxpayer/fourStreamManage/transactionManage';
import CashflowManage from '../views/taxpayer/fourStreamManage/cashflowManage';
import TInvoiceManage from '../views/taxpayer/fourStreamManage/invoiceManage';
import StaffManage from '../views/taxpayer/staffManage/staffManage';
import TCooperativeManage from '../views/taxpayer/cooperativeManage/cooperativeManage';

import OrganIndex from '../views/organ/index';
import OrganInfo from '../views/organ/organInfo';
import CompanyManage from "../views/organ/companyManage/companyManage";
import InvoiceManage from "../views/organ/invoiceManage/invoiceManage";
import CooperativeManage from '../views/organ/cooperativeManage/cooperativeManage';
import StoreDetail from '../views/shoppingMall/storeDetail';
import GoodsInfo from '../views/shoppingMall/goodsInfo';
import InvoiceStatistics from '../views/organ/statistics/invoiceStatistics';
import CustomerManage from '../views/organ/customerManage/customerManage';
import ServiceManage from '../views/organ/serviceManage/serviceManage';
import StaffManagement from '../views/organ/staffManage/staffManagement';





export const routes = [
    {
        path: '/', element: <Index />, children: [
            { path: 'Introduction', element: <Index /> },
        ]
    },
    { path: '/login', element: <Login /> },
    { path: '/storeDetail/:storeID', element: <StoreDetail /> },
    { path: '/goodsInfo/:id', element: <GoodsInfo /> },
    {
        path: '/taxpayer', element: <TaxpayerIndex />, // 纳税主体 企业/个人
        children: [
            { path: 'dashboard', element: <Dashboard /> }, // 控制台
            { path: 'message', element: <Messages /> }, // 消息列表
            { path: 'wallet', element: <Wallet /> }, // 消息列表
            { path: 'invoiceManage', element: <TInvoiceManage /> },
            {
                path: 'infoManage', children: [
                    { path: 'taxpayerInfo', element: <TaxpayerInfo /> }, // 纳税主体信息
                    { path: 'unitInfo', element: <UnitInfo /> }, // 纳税主体信息
                ]
            },
            {
                path: 'invoiceManage', children: [
                    { path: 'invoiceDownload', element: <InvoiceDownload /> }, //发票下载
                ]
            },
            {
                path: 'fourStreamManage', children: [
                    { path: 'contractManage', element: <ContractManage /> }, // 合同管理
                    { path: 'transactionManage', element: <TransactionManage /> }, // 发票管理
                    { path: 'invoiceManage', element: <TInvoiceManage /> }, // 发票管理
                    { path: 'cashflowManage', element: <CashflowManage /> }, // 现金流管理
                ]
            },
            {
                path: 'staffManage', children: [
                    { path: 'staffManage', element: <StaffManage /> }, // 员工管理
                ]
            },
            {
                path: 'cooperative', children: [
                    {
                        path: 'cooperativeManage/:type', element: <TCooperativeManage />, // 合作社管理
                    },
                    {
                        path: 'contractManage', element: <ContractManage2 />, // 合作社合同
                    }
                ]
            },

        ]
    },
    {
        path: '/organ', element: <OrganIndex />, // 机构
        children: [
            { path: 'organInfo', element: <OrganInfo /> }, // 机构信息
            { path: 'message', element: <Messages /> }, // 消息列表
            { path: 'companyManage/:type', element: <CompanyManage /> }, // 公司管理
            { path: 'invoiceManage/:type', element: <InvoiceManage /> }, // 发票管理
            { path: 'cooperativeManage/:type', element: <CooperativeManage /> }, // 合作社管理
            { path: 'invoiceStatistics', element: <InvoiceStatistics /> }, // 统计管理
            { path: 'customerManage', element: <CustomerManage /> } ,// 客户管理
            { path: 'staffManage', element: <StaffManagement /> } ,// 员工管理
            { path: 'serviceManage', element: <ServiceManage /> } // 服务项目管理
        ]
    },
]