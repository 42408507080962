import { Button, Descriptions, Image, Input, message, Space, Tag } from 'antd'
import { useState, useEffect } from 'react'
import { API_getMyOrgan, API_updateMyOrganPhone } from '@/service/common'
import { testPhone } from '@/utils/utils';

const OrganInfo = () => {
    const [organInfo, setOrganInfo] = useState({})
    const [phone, setPhone] = useState('')
    useEffect(() => {
        API_getMyOrgan().then(res => {
            setOrganInfo(res.data)
            setPhone(res.data.phone)
        })
    }, [])
    const onUpdateOrganPhone = () => {
        if (!testPhone(phone)) {
            message.warning('无效的手机号')
            return;
        }
        API_updateMyOrganPhone({
            organid: organInfo.organid,
            phone
        }).then(res => {
            message.success('更新完成')
        })
    }
    return (
        <div className="custom_auto">
            <div className="custom_pack">
                <Descriptions bordered title="机构信息" column={2}>
                    <Descriptions.Item label="机构名称" >{organInfo.organName}</Descriptions.Item>
                    <Descriptions.Item label="注册时间">{organInfo.createTime}</Descriptions.Item>
                    <Descriptions.Item label="注册地">{organInfo.address?.address}</Descriptions.Item>
                    <Descriptions.Item label="法人">{organInfo.legalPerson}</Descriptions.Item>
                    <Descriptions.Item label="联系电话">
                        <Space.Compact>
                            <Input value={phone}
                                style={{ width: '220px' }}
                                placeholder="法人/公司联系电话"
                                allowClear
                                enterButton="更新"
                                onChange={(e) => setPhone(e.target.value)}
                            />
                            <Button type="primary" onClick={() => { onUpdateOrganPhone() }}>更新</Button>
                        </Space.Compact>
                    </Descriptions.Item>
                    <Descriptions.Item label="机构类型">{organInfo.serviceType && organInfo.serviceType.map(data => <Tag key={data}>{data}</Tag>)}</Descriptions.Item>
                    <Descriptions.Item label="营业执照">
                        <Image src={organInfo.businessLicense} height={'310px'} width={'218px'}></Image>
                    </Descriptions.Item>
                    <Descriptions.Item label="行业证书">
                        <Image src={organInfo.specialLicense} height={'310px'} width={'218px'}></Image>
                    </Descriptions.Item>
                </Descriptions>
            </div>
        </div>
    )
}
export default OrganInfo