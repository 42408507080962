

/**
 * 地址#后路径对应文本配置,匹配后文本用于tab栏显示
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    '/organ/organInfo': '我的资料',
    '/organ/invoiceStatistics': '开票统计',
    '/organ/message': '消息',
    '/organ/companyManage/1': '申请列表',
    '/organ/companyManage/2': '待签约列表',
    '/organ/companyManage/3': '已签约列表',
    '/organ/companyManage/4': '已拒绝列表',
    '/organ/invoiceManage/1': '申请列表',
    '/organ/invoiceManage/2': '待处置列表',
    '/organ/invoiceManage/3': '已处置列表',
    '/organ/invoiceManage/4': '已拒绝列表',
    '/organ/cooperativeManage/1': '申请列表',
    '/organ/cooperativeManage/3': '合作社列表',
    '/organ/cooperativeManage/0': '申请列表',
    '/organ/customerManage': '客户管理',
    '/organ/staffManage': '员工管理',
    '/organ/serviceManage': '项目服务管理',
    '/taxpayer/invoiceManage/myInvoice': '我的发票',
    '/taxpayer/invoiceManage/invoiceDownload': '发票下载',
    '/taxpayer/message': '消息',
    '/taxpayer/dashboard':'首页',
    '/taxpayer/invoiceManage':'发票申请',
    '/taxpayer/cooperative/contractManage':'个体户合同',
    '/taxpayer/fourStreamManage/contractManage':'合同管理',
    '/taxpayer/fourStreamManage/transactionManage':'业务管理',
    '/taxpayer/fourStreamManage/invoiceManage':'票务管理',
    '/taxpayer/fourStreamManage/cashflowManage':'现金流管理',
}