import React, { useState, useEffect } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { Badge, Layout, Popover, Avatar, Modal } from 'antd';
import { UserOutlined, ExportOutlined, BellOutlined, WalletOutlined } from '@ant-design/icons';
import { dataUrl } from '@/config/utils';
import { API_getMsgs } from '@/service/common';
import ChooseOrgan from '../company/chooseOrgan';
import Tab from "@/components/Tab"
import MenuPage from '../../components/Menu';
const { Header, Sider, Content } = Layout;

/**
 * 纳税主体 企业/个人 主页
 * 
 */
export default function TaxpayerIndex() {
    const [portrait, setPortrait] = useState('');
    const [showChoose, setShowChoose] = useState(false)
    const [msgList, setMsgList] = useState([])
    const [companyInfo, setCompanyInfo] = useState({})
    const navigate = useNavigate();
    useEffect(() => {
        if (!localStorage.getItem('accessToken')) {
            // 判断登录状态
            navigate('/')
            return;
        }

        setPortrait(localStorage.getItem('portrait'))
        API_getMsgs({ pagesize: 99 }).then(res => {
            setMsgList(res.data.filter(row => {
                return row.readState === "未读"
            }))
        })
        // if(localStorage.getItem('companyInfo')){
        //     let companyInfo=JSON.parse(localStorage.getItem('companyInfo'));
        //     setCompanyInfo(companyInfo)
        // }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const goMsg = () => {
        setMsgList([]);
        navigate('/taxpayer/message')
    }
    const outLogin = () => {
        let phone=localStorage.getItem('phone');
        localStorage.clear();
        localStorage.setItem('phone',phone);
        sessionStorage.clear()
        navigate("/");
    }
    const content = (
        <div className="popo_content">
            <div className="popo_item">
                <Link to="/HomePage/UserInfo">
                    <UserOutlined /> 我的资料
                </Link>
            </div>
            <div className="popo_item" onClick={outLogin}>
                <ExportOutlined /> 退出登录
            </div>
        </div>
    );

    return (
        <div className="home_page">
            <Layout>
                <Header className="custom_hander">
                    <img className='logo_img' onClick={() => navigate('/')} src={require('../../imgs/logo.png')} alt='' />
                    <div className='custom_hander_main'>
                        <div>{companyInfo.name}</div>
                        <Link to='/taxpayer/wallet' className='flex' >
                            <Popover placement="top" content="我的钱包">
                                <WalletOutlined style={{ fontSize: 24, color: '#fff' }} />
                            </Popover>
                        </Link>
                        <Badge count={msgList.length} className="flex" onClick={goMsg}>
                            <BellOutlined style={{ fontSize: 24, color: '#fff' }} />
                        </Badge>
                        <Popover placement="bottomRight" content={content}>
                            <Avatar size="large" icon={portrait ? (<img className="header_img" src={dataUrl + portrait} alt="" />) : (<UserOutlined />)} />
                        </Popover>
                    </div>
                </Header>
                <Layout>
                    <Sider trigger={null} collapsible>
                        <MenuPage type="taxpayer" />
                    </Sider>
                    <Content>
                        <Tab></Tab>
                        <Outlet></Outlet>
                    </Content>
                </Layout>
            </Layout>
            <Modal open={showChoose} title="选择一个签约机构" footer={null} closable={false} >
                <ChooseOrgan setShowChoose={setShowChoose}></ChooseOrgan>
            </Modal>
        </div>
    );
};