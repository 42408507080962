import {
  Table,
  Button,
  Modal,
  Radio,
  message,
  Image,
  Divider,
  Tag,
  Upload,
  Input,
  Progress,
  Select,
  Empty,
  DatePicker,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  API_getInvoiceApplyList,
  API_acceptInvoiceApply,
  API_handleInvoiceApply,
  API_refuseInvoiceApply,
} from "@/service/common";
import AntdCell from "@/components/antdCell";
import {
  FileTextOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import InvoiceTemplate from "@/components/invoiceTemplate/invoiceTemplate";
import { isAssetTypeAnImage } from "@/config/utils";
import FileContent from "@/components/fileContent";
import dayjs from 'dayjs';
import { deepClone, uploadHeader } from "../../../utils/utils";
import { API_discardInvoiceApplyHandle, API_getApplyCompany, API_handleInvoiceApply1, API_rejectInvoiceApply } from "../../../service/common";
const InvoiceManage = (props) => {
  const { type } = useParams();
  const {RangePicker}=DatePicker;
  const {Option}=Select;
  const [invoiceFileList, setInvoiceFileList] = useState([]);
  const [percent, setPercent] = useState(100);
  const ref = useRef();
  const [fileShow, setFileShow] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileData, setFileData] = useState({});
  const [invoiceImg2, setInvoiceImg2] = useState("");
  const [fileContentShow,setFileContentShow]=useState(false)
  const [fileTitle,setFileTitle]=useState('');
  const [fileContent,setFileContent]=useState([]);
  const [rangeTime,setRangeTime]=useState([])
  const [unitList, setUnitList] = useState([])
  const [unitId,setUnitId]=useState(0)
  useEffect(e=>{
   API_getApplyCompany({
       pageindex: 1,
       pagesize: 999,
       accept: 3,
   }).then((res) => {
    let list =[]
       res.data.map((data) => {
        let info=data.companyInfos[0]
           list.push({id:info.id,name:info.name})
       });
       setUnitList(list)
   });
  },[])
  const columns = [
    {
      title: "编号",
      dataIndex: "id",
      width: 140,
      align: "center",
      fixed: "left",
    },
  
    {
      title: "销货单位",
      width: 170,
      align: "center",
      dataIndex: "invoiceNotice",
      render: (e, row) => e.seller?.name,
    },
    {
      title: "购货单位",
      width: 170,
      align: "center",
      dataIndex: "invoiceNotice",
      render: (e, row) => e.buyer?.name,
    },
    { title: "开票时间", width: 180, align: "center", dataIndex: "invoiceNotice",render:(e)=>{return e.time.split(' ')[0]} },
    { title: "开票金额", width: 90, align: "center", dataIndex: type==3?"totalMoney":"money" },
    {
      title: "受理状态",
      align: "center",
      width: 100,
      dataIndex: "orderState",
      render: (data, row) => {
        let tag = null;
        switch (row.orderState) {
          case 1:
            tag = <Tag color="default">未受理</Tag>;
            break;
          case 2:
            tag = <Tag color="processing">已受理</Tag>;
            break;
          case 3:
            tag = <Tag color="success">已处置</Tag>;
            break;
          case 4:
            tag = <Tag color="error">已拒绝</Tag>;
          case 5:
            tag = <Tag color="success">已拒绝</Tag>;
            break;
          case 6:
            tag = <Tag>已取消</Tag>;
            break;
          case 7:
            tag = <Tag color="success">已支付</Tag>;
            break;
          case 8:
            tag = <Tag color="error">作废申请中</Tag>;
            break;
           case 9:
              tag = <Tag color="error">已作废</Tag>;
           break;
          default:
            tag = <Tag>无效状态</Tag>;
            break;
        }
        return tag;
      },
    },
    {
      title: "绑定状态",
      width: 220,
      align: "center",
      render: (text, row) => (
        <>
          {!row.contractId ? (
            <div style={{ marginBottom: "10px" }}>
              <Tag color="error">无合同</Tag>
            </div>
          ) : (
            <div style={{ marginBottom: "10px" }}>
              <Tag style={{cursor:'pointer'}} color="processing" onClick={e=>{showContract(row)}}>合同:{row.contractlist.header}</Tag>
            </div>
          )}
          {!row.cTransactionId ? (
            <Tag color="error">无业务</Tag>
          ) : (
            <Tag style={{cursor:'pointer'}} color="processing" onClick={e=>{setFileTitle('业务列表');setFileContent(row.businesslist);setFileContentShow(true)}}>业务:{row.transactionDescribe}</Tag>
          )}
        </>
      ),
    },
    {
      title: (type === "1"||type === "2") ?"开票信息":"发票详情",
      width: 120,
      dataIndex: "data",
      align: "center",
      fixed: "right",
      render: (text, record) => {
        if(record.orderState==3||record.orderState==8){
          return <div className="link" onClick={e=>{setFileTitle('发票详情');setFileContent(record.images||[]);setFileContentShow(true)}}>发票详情</div>
        }else if(record.orderState==9){
          return <div className="link" onClick={e=>{setFileTitle('作废详情');setFileContent([record.discardNote]);setFileContentShow(true)}}>作废详情</div>
        }else{
          return         <div
          onClick={(e) => {
            openInvoiceDetail(record);
          }}
          className="check-invoice link"
        >
          点击查看
        </div>
        }
      },
    },
    {
      title: "操作",
      width: 180,
      align: "center",
      fixed: "right",
      render: (text, record) => (
        <>
          {type === "1" && (
            <div className="custom_func_area">
              <Button type="primary" onClick={(e) => verifyInvoice(record)}>
                受理
              </Button>
              <Button onClick={(e) => refuse(record)} danger>
                拒绝
              </Button>
            </div>
          )}
          {type === "2" && (
            <div className="custom_func_area">
              <Button type="primary" onClick={(e) => showDetail(record)}>
                处置
              </Button>
              <Button onClick={(e) => reject(record)} danger>
                驳回
              </Button>
            </div>
          )}
          {type === "3" && (
            <div className="custom_func_area">
              <Button type="primary" onClick={(e) => showDetail(record)}>
                再处置
              </Button>
              <Button onClick={(e) => reject(record)} danger>
                驳回
              </Button>
            </div>
          )}
           {type === "8" && (
            <Button type="primary" onClick={(e) => showDisCardDetail(record)}>
              确认作废
            </Button>
          )}
        </>
      ),
    },
  ];

  const columns2 = [
    {
      title: "发票抬头",
      key: "header",
      type: "text",
      render: (text, record) => {
        return record.invoiceNotice.buyer.name;
      },
    },
    {
      title: "税号",
      key: "header",
      type: "text",
      render: (text, record) => {
        return record.invoiceNotice.buyer.taxAccount;
      },
    },
    {
      title: "发票详情",
      dataIndex: "header",
      type: "text",
      render: (text, record) => {
        return (
          <div
            onClick={(e) => {
              openInvoiceDetail(record);
            }}
            className="check-invoice"
          >
            点击查看
          </div>
        );
      },
    },
    { title: "发票类型", key: "type", type: "text" },
    { title: "开票金额", key: "money", type: "text" },
    { title: "纳税人类型", key: "category", type: "text" },
    { title: "申请时间", key: "createTime", type: "text" },
  ];


  const [totalMoney, setTotalMoney] = useState('');
  const [totalTax, setTotalTax] = useState('');
  const onFinish = (e) => {
    if(detailType=='discard'){
      if(invoiceFileList.length==0){
        message.error('请上传文件')
        return
      }
      let reqData = {
        orderId: invoiceDetail.id,
        notes:invoiceFileList[0].url
      };
      API_discardInvoiceApplyHandle(reqData).then((res) => {
        message.success(res.message);
        setIsModalVisible(false);
        getList();
      });
      return
    }
    if (!totalMoney) {
      return;
    }
    let images = [];
    if (uploadType === 1) {
      invoiceFileList.forEach((data) => {
        images.push(data.url);
      });
    }
    let reqData = {
      orderId: invoiceDetail.id,
      images,
      money: totalMoney,
      tax:totalTax,
      message:'处置'
    };
    if (invoiceDetail.totalMoney) {
      API_handleInvoiceApply(reqData).then((res) => {
        message.success(res.message);
        setIsModalVisible(false);
        getList();
      });
    } else {
      API_handleInvoiceApply1(reqData).then((res) => {
        message.success(res.message);
        setIsModalVisible(false);
        getList();
      });
    }
  };

  const [invoiceShow, setInvoiceShow] = useState(false);
  const [invoiceData, setInvoiceData] = useState();
  const openInvoiceDetail = (e) => {
    let reqData = e.invoiceNotice;
    reqData.type=e.eType;
    reqData.category=e.eCategory;
    reqData.handleResult = {
      images: e.images || [],
      tax: e.tax,
      totalMoney: e.totalMoney,
    };
    reqData.orderState = e.orderState;
    reqData.notes=[e.discardNote];
    console.log(reqData)
    setInvoiceData(reqData);
    setInvoiceShow(true);
  };
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSIze] = useState(10);
  const [applyList, setApplyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const getList = () => {
    let reqData={
      pageindex: pageIndex,
      pagesize: pageSize,
      accept: type,
      organid: localStorage.getItem("organID"),
    }
    if(rangeTime.length==2){
      reqData.startTime=rangeTime[0]+' 00:00:00';
      reqData.endTime=rangeTime[1]+' 23:59:59'
    }
    if(unitId){
      reqData.sellerId=unitId
    }
    API_getInvoiceApplyList(reqData).then((res) => {
      setApplyList(res.data);
      setLoading(false);
      setTotal(res.total);
    });
  };
  useEffect(
    (e) => {
      setApplyList([]);
      setLoading(true);
      getList();
    },
    [pageIndex, type,rangeTime,unitId]
  );
  const onChange = (e) => {
    setPageIndex(e);
  };
  const [loadingBtn, setLoadingBtn] = useState(false);
  const verifyInvoice = (record) => {
    Modal.confirm({
      content: "是否受理发票申请?",
      okText: "确定",
      cancelText: "取消",
      onOk: (e) => {
        setLoadingBtn(true);
        API_acceptInvoiceApply({ orderId: record.id, money: record.money })
          .then((res) => {
            message.success("受理成功!");
            record.isVerify = true;
            Modal.destroyAll();
            getList();
            setLoadingBtn(false);
            return Promise.resolve();
          })
          .catch((e) => {
            setLoadingBtn(false);
          });
      },
    });
  };

  const refuse = (item) => {
    localStorage.setItem("reason", "");
    Modal.confirm({
      title: "拒绝原因",
      content: (
        <Input
          placeholder="请输入拒绝原因"
          onChange={(e) => {
            localStorage.setItem("reason", e.target.value);
          }}
        ></Input>
      ),
      okText: "确定",
      cancelText: "取消",
      onOk: (e) => {
        setLoadingBtn(true);
        handleOK(item);
      },
    });
  };

  const reject= (item)=>{
    Modal.confirm({
      title: "驳回提醒!",
      content: '发票驳回后将进入待受理状态,是否确认驳回?',
      okText: "确定",
      cancelText: "取消",
      onOk: (e) => {
        setLoadingBtn(true);
        API_rejectInvoiceApply({invoicesId:item.id,orderState:1}).then(res=>{
          message.success('驳回成功,可进入待受理列表查看');
          Modal.destroyAll();
        })
      },
    });
  }

  const handleOK = (item) => {
    API_refuseInvoiceApply({
      orderId: item.id,
      message: localStorage.getItem("reason") || "拒绝",
    })
      .then((res) => {
        message.success("已拒绝!");
        item.isVerify = true;
        Modal.destroyAll();
        getList();
        setLoadingBtn(false);
        return Promise.resolve();
      })
      .catch((e) => {
        setLoadingBtn(false);
      });
  };
  const [invoiceDetail, setInvoiceDetail] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [uploadType, setUploadType] = useState(1);
  const [detailType,setDetailType]=useState('')
  const showDetail = (item) => {
    setDetailType('')
    let data = deepClone(item);
    let list = [];
    setInvoiceDetail({ ...item });
    data.images?.map((row) => {
      list.push({ url: row });
    });
    setInvoiceFileList(list);
    setTotalMoney(item.totalMoney || 0);
    setTotalTax(item.tax || 0);
    setIsModalVisible(true);
  };
  const onInitInvoceInfo = () => {
    setInvoiceFileList([]);
  };

  const showDisCardDetail = (item) => {
    setUploadType(1)
    setDetailType('discard')
    setInvoiceDetail({ ...item });
    setInvoiceFileList([]);
    setIsModalVisible(true);
  };

  const showContract =(row)=>{
    if(row.contractlist.conteactTextLink=='限制查看'){
      message.error('合同被限制为不公开,无法查看')
      return
    }
    setFileTitle('合同列表');
    setFileContent(row.contractlist.conteactTextLink.split(','));
    setFileContentShow(true)
  }

  const formateRange = (e)=>{
    if(!e){setRangeTime([])}
    let list=[];
    e.map(row=>{
      list.push(dayjs(row).format('YYYY-MM-DD'))
    })
    setRangeTime(list)
  }

  return (
    <div className="custom_auto">
      <div className="custom_pack custom_filter">

      <div className="flex-start">
        <div className="flex-text">销货单位:</div>
        <div>
          <Select
            style={{ minWidth: 220 }}
            value={unitId}
            showSearch
            optionFilterProp="children"
            onChange={(e) => setUnitId(e)}
          >
            <Option value={0}>全部</Option>
            {unitList.length > 0 &&
              unitList.map((data, index) => {
                return <Option label={data.name} value={data.id}>{data.name}</Option>;
              })}
          </Select>
        </div>
      </div>
      <div className="flex-start" >
        <div className="flex-text">开票时间:</div>
        <div>         
           <RangePicker   onChange={e=>formateRange(e)}   format="YYYY-MM-DD"> </RangePicker>
        </div>
      </div>
      </div>
      <div className="custom_pack">
        <Table
          dataSource={applyList}
          columns={columns}
          loading={loading}
          rowKey={(columns) => columns.id}
          pagination={{
            pageSize: pageSize,
            total: total,
            onChange: onChange,
            pageSizeOptions: [],
          }}
          scroll={{
            x: 1400,
            y: `calc(100vh - 346px)`,
            scrollToFirstRowOnChange: true,
          }}
        />
      </div>
      <Modal
        title="文件详情"
        open={fileShow}
        width="60%"
        onCancel={(e) => setFileShow(false)}
        footer={null}
      >
        <div className="file-content2 flex-start">
          {fileData.type === "business" &&
            fileList.map((data) => (
              <div className="file-item">
                {isAssetTypeAnImage(data) ? (
                  <Image className="item-type" src={data} />
                ) : (
                  <div className="item-type">
                    <FileTextOutlined />
                  </div>
                )}
                <div className="name">{data}</div>
                <div>
                  <Button onClick={(e) => window.open(data)}>下载</Button>
                </div>
              </div>
            ))}
          {fileData.type === "contract" &&
            fileList.map((data) => (
              <div className="file-item">
                <FileContent url={data.conteactTextLink}></FileContent>
                <div className="name">{data.header}</div>
                <div>
                  <Button onClick={(e) => window.open(data.conteactTextLink)}>
                    下载
                  </Button>
                </div>
              </div>
            ))}
        </div>
      </Modal>
      <Modal
        width="800px"
        title="发票申请详情"
        onOk={onFinish}
        open={isModalVisible}
        onCancel={(e) => {
          setIsModalVisible(false);
        }}
        afterClose={() => onInitInvoceInfo()}
        maskClosable={false}
        destroyOnClose={true}
      >
        <AntdCell columns={columns2} dataSource={invoiceDetail}></AntdCell>
        <Divider>{detailType=="discard"?'作废':'处置'}反馈</Divider>
        {detailType!="discard"&&<>
        <div className="flex-start" style={{ marginBottom: "20px" }}>
          <div>实际开票金额 (含税):</div>
          <Input
            size="large"
            type="number"
            style={{ width: "200px", margin: "0 20px" }}
            value={totalMoney}
            onChange={(e) => {
              setTotalMoney(e.target.value);
            }}
          /><div>税额:</div>
          <Input
            size="large"
            type="number"
            style={{ width: "200px", margin: "0 20px" }}
            value={totalTax}
            onChange={(e) => {
              setTotalTax(e.target.value);
            }}
          />
        </div>
        <Radio.Group
          onChange={(e) => {
            setUploadType(e.target.value);
          }}
          value={uploadType}
        >
          <Radio value={1}>上传发票</Radio>
          <Radio value={2}>填写发票地址</Radio>
        </Radio.Group>
        </>}
        {uploadType === 1 ? (
          <div className="custom_upload_area">
            {invoiceFileList.map((data, index) => (
              <FileContent
                onRemove={(index) => {
                  invoiceFileList.splice(index, 1);
                  setInvoiceFileList([...invoiceFileList]);
                }}
                width={100}
                height={100}
                url={data.url}
                name={data.name}
                index={index}
                key={data.url}
              ></FileContent>
            ))}
            <div className="custom_upload_box">
              <Upload
                onChange={({ file, fileList }) => {
                  setPercent(file.percent);
                  if (file.status === "done") {
                    if (file.response.status === "OK") {
                      if(detailType=='discard'){
                        setInvoiceFileList([
                          { url: file.response.data, name: file.name }
                        ]);
                      }else{
                        setInvoiceFileList([
                          ...invoiceFileList,
                          { url: file.response.data, name: file.name },
                        ]);
                      }
                    
                    } else {
                      // 上传失败
                      message.error(file.response.message);
                    }
                  }
                }}
                headers={uploadHeader()}
                showUploadList={false}
                disabled={percent !== 100}
                action={process.env.REACT_APP_API+"Upload/FileUpload"}
                listType="picture-card"
              >
                {percent !== 100 ? (
                  <Progress type="circle" percent={percent} size={60} />
                ) : (
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>选择文件</div>
                  </div>
                )}
              </Upload>
            </div>
          </div>
        ) : (
          <Input
            defaultValue={invoiceImg2}
            onChange={(e) => setInvoiceImg2(e.target.value)}
          ></Input>
        )}
      </Modal>
      <Modal
        open={invoiceShow}
        footer={null}
        onCancel={(e) => {
          setInvoiceShow(false);
        }}
        wrapClassName="myInvoice-modal"
        width="80%"
        maskClosable={false}
        destroyOnClose={true}
      >
        <InvoiceTemplate
          invoiceDetail={invoiceData}
          disabled={true}
          ref={ref}
        ></InvoiceTemplate>
      </Modal>
      <Modal
        open={fileContentShow}
        title={fileTitle}
        footer={null}
        onCancel={(e) => {
          setFileContentShow(false);
        }}
        wrapClassName="myInvoice-modal"
        width="80%"
        maskClosable={false}
        destroyOnClose={true}
      >
         {fileContent.map(res=>(
          <FileContent width='200px' url={res}></FileContent>
         ))}
         {fileContent.length==0&&<Empty></Empty>}
      </Modal>
      
    </div>
  );
};
export default InvoiceManage;
