
import Tab from '../../components/Tab'
import React, { useState, useEffect } from 'react';
import { Badge, Layout, Popover, Avatar } from "antd";
import { UserOutlined, ExportOutlined, BellOutlined } from "@ant-design/icons";
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { dataUrl } from '../../config/utils';
import {API_getMsgs } from '../../service/common';
import MenuPage from '../../components/Menu';
const { Header, Sider, Content } = Layout;
/**
 * 机构 主页
 * 
 */
export default function OrganIndex(props) {
    const [portrait, setPortrait] = useState('');
    const [msgList, setMsgList] = useState([]);

    const navigate = useNavigate();
    useEffect(() => {
        if (!localStorage.getItem('accessToken')) {
            // 判断登录状态
            navigate('/')
            return;
        }
        setPortrait(localStorage.getItem('portrait'))
        API_getMsgs({ pagesize: 99 }).then(res => {
            setMsgList(res.data.filter(row => {
                return row.readState === "未读"
            }))
        })


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const goMsg = () => {
        navigate('/organ/message')
    }
    const outLogin = () => {
        let phone=localStorage.getItem('phone');
        localStorage.clear();
        localStorage.setItem('phone',phone);
        sessionStorage.clear()
        navigate('/')
    }
    const content = (
        <div className='popo_content'>
            <div className='popo_item'><Link to="/organ/organInfo">
                <UserOutlined /> 我的资料
            </Link></div>
            <div className='popo_item' onClick={outLogin}><ExportOutlined /> 退出登录</div>
        </div>
    );
    const goTax =()=>{
        window.open("https://tpass.zhejiang.chinatax.gov.cn:8443/#/login?redirect_uri=https%3A%2F%2Fetax.zhejiang.chinatax.gov.cn%2Fzjgfdzswj%2Fmain%2Fkx%2Fskip.html%3Fservice%3Dhttps%3A%2F%2Fetax.zhejiang.chinatax.gov.cn%2Fzjgfdzswj%2Fmain%2Fhome%2Fwdxx%2Findex.html&client_id=xdf6a5cd4cx74bfca46x3bbfcxa4c4xf&response_type=code&state=test")
    }
    return (
        <Layout>
            <Header className='custom_hander'>
                <img className='logo_img' onClick={()=>navigate('/')} src={require('../../imgs/logo.png')} alt='' />
                <div className='custom_hander_main'>
                <div className='link' onClick={goTax}>国家税务总局浙江省电子税务局入口</div>
                    <Badge count={msgList.length} className='flex' onClick={goMsg} >
                        <BellOutlined style={{fontSize:24,color:'#fff'}} />
                    </Badge>
                    <Popover placement="bottomRight" content={content}>
                        <Avatar size="large" src={dataUrl + portrait} icon={<UserOutlined />} />
                    </Popover>
                </div>
            </Header>
            <Layout>
                <Sider trigger={null} collapsible>
                    <MenuPage  type="organ" />
                </Sider>
                <Content>
                    <Tab></Tab>
                    <Outlet></Outlet>
                </Content>
            </Layout>
        </Layout>
    );
};