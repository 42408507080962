import './index.less'
import { useEffect, useState } from 'react';
import { API_getNews, API_getZlsqToken } from '../service/common';
import { useNavigate } from 'react-router-dom';
import Product from '../components/shoppingMall/product';
import { API_geCategoryList, API_getClasses, API_getProductList } from '../service/zlsq-api';
import { setCookie } from '../config/utils';
import { Empty } from 'antd';
const PageIndex = () => {
  const [categoryList,setCategoryList]=useState([]);
 const [categoryID,setCategoryID]=useState(0)
  const [productList,setProductList]=useState([]);
  const [classList,setClassList]=useState([]);
  useEffect(e=>{
    let list=[{ID:0,name:'全部'}]
    API_geCategoryList({id:'936'}).then(res=>{
          list=list.concat(res.data.subCategoryList)
          setCategoryList(list)
    })
    API_getClasses().then(res=>{
      setClassList(res.data)
    })
  },[])

  const getProductList = (id)=>{
    setCategoryID(id)
  }

  useEffect(e=>{
    let reqData={
      start:1,
      count:8,
      isSoldOut:false,
      orderBy:'asc',
      categoryID:categoryID||'936',
      categoryClass:categoryID?2:1
    }
    API_getProductList(reqData).then(res=>{
      setProductList(res.data)
    })
  },[categoryID])
  const PageTitle = (data) => {
    return (
      <div className="page-title flex-center">
        <div className="title-content">{data.title}</div>
        <div className="title-line flex-center">
          <div className="line"></div>
          <div className="circle"></div>
          <div className="line"></div>
        </div>
        {data.ps && <div className="title-ps">{data.ps}</div>}
      </div>
    );
  }
  const serviceList = [
    {
      title: "特色小镇运营管理",
      content: [
        "招商运营、企业服务、",
        "机构把控、票务管理、",
        "金融服务一体化运营。",
      ],
      icon: require("@/imgs/s1.png"),
      iconActive: require("@/imgs/s11.png"),
      indent: false,
    },
    {
      title: "产业税务统筹服务",
      content: [
        "会计事务所一对一服务，专业税务事务所完税把控，产业洼地资源共享。",
      ],
      icon: require("@/imgs/s2.png"),
      iconActive: require("@/imgs/s22.png"),
      indent: false,
    },
    {
      title: "特色金融服务配套",
      content: [
        "入驻企业有银行和第三方金额机构配套信贷服务，开票越多配额越多",
        "可为企业工资代发，税款代缴，应收款质押贷款，代收代付服务等等。",
      ],
      indent: true,
    },
    {
      title: "行业资源共享融合",
      content: [
        "根据行业服务需求自动匹配关联服务机构及上下游产业资源，让企业寻找客户更便捷。",
      ],
      icon: require("@/imgs/s3.png"),
      iconActive: require("@/imgs/s33.png"),
      indent: true,
    },
    {
      title: "专业机构驻扎服务",
      content: [
        "不同区域有不同专业服务机构，从洼地政策、公司注册、会计申报、开票完税、成本建议、资源匹配，让企业再无税务麻烦之忧。",
      ],
      indent: true,
    },
    {
      title: "全数字化申报体系",
      content: [
        "平台全数字机构化登记、申报体系，让服务更具完整和追溯性，实现管理有保障，政策支持更放心。",
      ],
      icon: require("@/imgs/s4.png"),
      iconActive: require("@/imgs/s44.png"),
      indent: true,
    },
  ];

  const advantageTextList1 = [
    "国家一边在严格税务管理机制，",
    " 一边为减轻企业负担开路，",
    "但不等于让企业财务管理放松，",
    "更不能让企业虚开成本偷税漏税。",
    "企业没有完善税务管理机制和必要的政策支持是不可私自妄为，挥洒自如的，",
    "什么“公打私”，“小金库”，“缺票外开”等等",
    "在未来都是“走钢丝”。",
  ];
  const advantageTextList2 = [
    "第一，",
    " 有地方园区税收政策洼地保障。",
    "第二，",
    "根据企业行业属性和产业要求有专业的税务事务所和 会计事务所配以专业的人员进行一对一的服务，帮助入驻企业。",
    "第三，",
    "运用地方政府返还补贴政策让企业实现合法红利。",
  ];
  const [news1, setNews1] = useState([]);
  const [news2, setNews2] = useState([]);
  useEffect(e => {
    API_getNews({ PageIndex: 1, PageSize: 3, Type: 1 }).then(res => {
      setNews1(res.data)
    });
    API_getNews({ PageIndex: 1, PageSize: 3, Type: 2 }).then(res => {
      setNews2(res.data)
    })
  }, [])
  const goLink = (e) => {
    window.open(e.link)
  }
  const goMore = ()=>{
    navigate('/storeDetail/0')
  }
  const navigate = useNavigate();
  const menu = [
    { text: "首页", page: "/" },
    { text: "园区介绍" },
    { text: "企业服务窗口", page: "/login" },
    { text: "机构窗口登录", page: "/login" },
    { text: "个人服务窗口", page: "/login" },
  ];
  const goPage = (e) => {
    navigate(e);
  }
  return (
    <>
      <div className="flex-center menu">
        <div className="menu-tile">税邦服务</div>
        <div className="flex-start">
          {menu.map((data) => {
            return (
              <div onClick={(e) => goPage(data.page)} className="menu-item">
                {data.text}
              </div>
            );
          })}
        </div>
      </div>
      <div className="page-index" style={{textAlign:"center"}}> 
        <img style={{ width: "1400px" }} src={require("@/imgs/banner.jpg")} />
        <div className="page">
          <PageTitle
            title="一， 服务特色"
            ps="配套的税收政策服务 , 专业的税务统筹"
          ></PageTitle>
          <div className="flex service-content page-item">
            {serviceList.map((data, index) => {
              return (
                <div className="service-item" key={data.title}>
                  <div className="service-index">
                    {(index + 1 + "").padStart(2, "0")}
                  </div>
                  <div className="service-title">{data.title}</div>
                  <div className="service-text">
                    {data.content.map((row) => {
                      return (
                        <div
                          key={row}
                          className={
                            data.indent ? "indent text" : "noIndent text"
                          }
                        >
                          {row}
                        </div>
                      );
                    })}
                  </div>
                  {data.icon && (
                    <div className="service-icon">
                      <img src={data.icon} />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div className='flex page-item page-item3'>
            <div style={{width:'65%'}}>
               <PageTitle title="二， 集采机构"></PageTitle>
               <div className='class_content flex-center'>
                {categoryList.map(data=>(
                  <>
                   <div className={`class_item ${categoryID==data.ID?'active':''}`} onClick={()=>getProductList(data.ID)}>{data.name}</div>
                  <div className='line'></div>
                  </>
                ))}
               </div>
               <div className='product_content flex-start'>
                 {productList.map(data=>(
                  <Product itemData={data}></Product>
                 ))}
                 {productList.length==0&&<div className='flex-center' style={{marginTop:'30px',width:'100%'}}><Empty description="暂无商品" /></div>}
               </div>
               <div className='product_more'><span onClick={e=>goMore()}>查看更多商品</span></div>
            </div>
            <div style={{width:'30%'}}>
               <PageTitle title="三， 各类服务商"></PageTitle>
               <div className='service_content'>
                {classList.map(data=>(
                       <div className='service_item flex'>
                       <div className='service_name'>{data.Name}</div>
                       <img src={data.Icon} className='service_img'></img>
                     </div>
                ))}
               </div>
            </div>
          </div>
          <PageTitle title="四， 合作机构"></PageTitle>
          <div className="page-item2 flex">
            <div className="co-item">
              <img src={require("@/imgs/c1.png")} />
              <img src={require("@/imgs/c2.png")} />
              <img src={require("@/imgs/c3.png")} />
              <img src={require("@/imgs/c4.png")} />
            </div>
            <div className="co-item">
              <img src={require("@/imgs/c5.png")} />
              <img src={require("@/imgs/c6.png")} />
              <img src={require("@/imgs/c7.png")} />
              <img src={require("@/imgs/c8.png")} />
            </div>
            <div className="co-item">
              <img src={require("@/imgs/c9.png")} />
              <img src={require("@/imgs/c10.png")} />
              <img src={require("@/imgs/c11.png")} />
              <img src={require("@/imgs/c12.png")} />
            </div>
          </div>
          <div style={{ width: "1050px", marginTop: "50px" }}>
            <img style={{ width: "100%" }} src={require("@/imgs/zc.jpg")} />
          </div>
          <div className="flex news-page">
            <div className="news-item">
              <div className="flex">
                <div className="item-title">地方国家政策</div>
                <div
                  className="item-more"
                  onClick={(e) =>
                    goLink({
                      link: "http://zhejiang.chinatax.gov.cn/jiaxing/index.html",
                    })
                  }
                >
                  【查看更多】
                </div>
              </div>
              <div className="item-content">
                {news1.map((data) => {
                  return (
                    <div className="new flex" onClick={(e) => goLink(data)}>
                      <div className="new-title">{data.title}</div>
                      <div className="new-time">{data.timeText}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="news-item">
              <div className="flex">
                <div className="item-title">国家税务政策</div>
                <div
                  className="item-more"
                  onClick={(e) =>
                    goLink({ link: "http://www.chinatax.gov.cn/" })
                  }
                >
                  【查看更多】
                </div>
              </div>
              <div className="item-content">
                {news2.map((data) => {
                  return (
                    <div className="new flex" onClick={(e) => goLink(data)}>
                      <div className="new-title">{data.title}</div>
                      <div className="new-time">{data.timeText}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="footer-content flex-center">
        <div className="flex footer-text">
          <div className="footer-title">税邦</div>
          <div className="footer-c">
            <div>联系我们</div>
            <div>客服电话: 0573-82868383</div>
            <div>商业沟通: 13706733898</div>
            <div>办公时间: 8:30 - 17:00</div>
            <div>办公地点: 浙江省嘉兴市南湖区环球金融中心</div>
          </div>
          <div className="footer-c">
            <div>服务机构</div>
            <div>同达财务</div>
            <div>大度会计</div>
            <div>新月会计</div>
            <div>金管家</div>
          </div>
          <div className="footer-c" style={{ marginTop: '27px' }}>
            <div>合作机构</div>
            <div>招商银行</div>
            <div>嘉兴银行</div>
            <div>杭州银行</div>
            <div>湖州银行</div>
            <div>赢策金融</div>
          </div>
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </>

  );
}

export default PageIndex