import { Table, Button, Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_organApproveCooperativeApply, API_organGetCooperativeApply } from "@/service/cooperative";
const CooperativeManage = () => {
    const { type } = useParams();
    const columns = [
        {
            title: "合作社名称",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "主营项目",
            dataIndex: "serviceItem",
            key: "serviceItem",
        },
        {
            title: "联系人",
            dataIndex: "contacts",
            key: "contacts",
        },
        {
            title: "联系电话",
            dataIndex: "phone",
            key: "phone",
        },
        {
            title: "申请时间",
            dataIndex: "createTime",
            key: "createTime",
        },
        {
            title: "审核状态",
            dataIndex: "state",
            key: "state",
            render: (text, record) => {
                switch (text) {
                    case 1:
                        return "待受理";
                    case 2:
                        return "待签约";
                    case 3:
                        return "已通过";
                    case 4:
                        return "已拒绝";
                }
            },
        },
        {
            title: "操作",
            key: "action",
            render: (text, record) => (
                <>
                    {type == 1 && (
                        <>
                            <Button type="primary" onClick={(e) => verifyCompany(record)}>
                                通过
                            </Button>
                            <Button
                                type="danger"
                                style={{ marginLeft: "10px" }}
                                onClick={(e) => verifyCompany2(record)}
                            >
                                拒绝
                            </Button>
                        </>
                    )}
                </>
            ),
        },
    ];
    const getList = () => {
        API_organGetCooperativeApply({
            pageindex: pageIndex,
            pagesize: pageSize,
            accept: type
        }).then((res) => {
            setApplyList(res.data);
            setTotal(res.total);
        });
    };

    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSIze] = useState(10);
    const [applyList, setApplyList] = useState([]);
    const [total, setTotal] = useState(0);
    useEffect(
        (e) => {
            getList();
        },
        [pageIndex, type]
    );
    const onChange = (e) => {
        setPageIndex(e);
    };



    const verifyCompany = (item) => {
        Modal.confirm({
            content: "是否通过该合作社申请?",
            okText: "确定",
            cancelText: "取消",
            onOk: (e) => {
                API_organApproveCooperativeApply({ id: item.cooperativeId, isaccept: true }).then((res) => {
                    message.success("已通过!");
                    getList();
                    Modal.destroyAll();
                    return Promise.resolve();
                });
            },
        });
    };

    const verifyCompany2 = (item) => {
        Modal.confirm({
            content: "是否拒绝该合作社申请?",
            okText: "确定",
            cancelText: "取消",
            onOk: (e) => {
                API_organApproveCooperativeApply({ id: item.cooperativeId, isaccept: false }).then((res) => {
                    message.success("已拒绝!");
                    getList();
                    Modal.destroyAll();
                    return Promise.resolve();
                });
            },
        });
    };

    return (
        <div className="custom_auto">
            <div className="custom_pack">
                <Table
                    columns={columns}
                    rowKey={(columns) => columns.id}
                    pagination={{
                        pageSize: pageSize,
                        total: total,
                        onChange: onChange,
                        pageSizeOptions: [],
                    }}
                    dataSource={applyList}
                />
            </div>
        </div>
    );
};
export default CooperativeManage;
