import { Button, Form, Image, Input, Modal, Space, Upload, message } from "antd";
import { useEffect, useState } from "react";
import { API_getCompanyList, API_getMyBindOrgan, API_CancelBindOrgan } from "@/service/common";
import { PlusOutlined } from '@ant-design/icons';
import ChooseOrgan from "@/components/Chooseorgan";
import { API_addCompanyList, API_editCompanyList } from "../../../service/common";
import { uploadHeader } from "../../../utils/utils";

export default function TaxpayerInfo() {
    const [invoiceOrgan, setInvoiceOrgan] = useState([]);
    const [taxpayerForm] = Form.useForm()
    const [license, setLicense] = useState('');
    const [permit, setPermit] = useState('');
    const [showOrgan, setShowOrgan] = useState(false);
    const [type, setType] = useState(false);
    const [info,setInfo]=useState({})
    useEffect(() => {
        API_getCompanyList({pageindex:1,pagesize:1,isseller:true}).then(res=>{
          let companyInfo=res.data[0]||{};
          taxpayerForm.setFieldsValue(companyInfo)
          setLicense(companyInfo.license)
          setPermit(companyInfo.permit)
          setInfo(companyInfo)
        })

        API_getMyBindOrgan({ pageindex: 1, pagesize: 99, accept: 0 }).then(res => {
            let invoiceOrgan = res.data.filter(data => data.serviceType === 2 && data.orderState != 4)
            setInvoiceOrgan(invoiceOrgan[0] || {})
        })
        // API_CancelBindOrgan({ organid: 2, type: 2 })
    }, [])
    const returnStatus = (text) => {
        switch (text) {
            case 1:
                return "待受理";
            case 2:
                return "待签约";
            case 3:
                return "已签约";
            case 4:
                return "已拒绝";
            default:
                return "无效状态";
        }
    };
    const handleShowOrgan = (type) => {
        setShowOrgan(true)
        setType(type)
    }

    const handleEditUnitForm = async (form) => {
     
 
         let newObj =  Object.assign(info,form);
         newObj.license=license;
         newObj.permit=permit;
         newObj.isSeller = true;
        if (newObj.id) {
            await API_editCompanyList(newObj).then((res) => {
                message.success("修改成功!");
            });
        } else {
            await API_addCompanyList(newObj).then((res) => {
                message.success("添加成功!");
            });
        }
    }
    return (
        <div className="custom_auto">
            <div className="custom_pack">
                <Space size="large">
                    <div>签约发票机构:</div>
                    {invoiceOrgan.id && (invoiceOrgan.organResume.organName + `(${returnStatus(invoiceOrgan.orderState)})`)}
                    {(!invoiceOrgan.id || invoiceOrgan.orderState == 4) && <Button type="primary" onClick={(e) => handleShowOrgan(2)}>
                        选择机构
                    </Button>}
                </Space>
            </div>
            <div className="custom_pack">
                <Form form={taxpayerForm} name="taxpayerForm" labelCol={{ span: 4, offset: 0 }} onFinish={(e) => { handleEditUnitForm(e)}} autoComplete="off" size="large">
                    <Form.Item label="公司/个体名称" name="name" rules={[{ required: true, message: "请输入公司名称" }]}>
                        <Input></Input>
                    </Form.Item>
                    <Form.Item label="地址电话" name="addressPhone" rules={[{ required: true, message: "请输入地址电话" }]}>
                        <Input></Input>
                    </Form.Item>
                    <Form.Item label="税务登记号" name="taxAccount" rules={[{ required: true, message: "请输入税务登记号" }]}>
                        <Input></Input>
                    </Form.Item>
                    <Form.Item label="开户银行及账号" name="bankAccount" rules={[{ required: true, message: "请输入开户银行及账号" }]}>
                        <Input></Input>
                    </Form.Item>
                    <Form.Item label="营业执照" name="license" rules={[{ required: true, message: "请上传营业执照!" }]}>
                        <div className="custom_upload_height flex-start">
                        <Image width='100px' height="100px" style={{marginRight:"10px"}} src={license}></Image>
                            <Upload headers={uploadHeader()} action={process.env.REACT_APP_API+"Upload/FileUpload"} accept="image/*" data={{ FileType: "1" }}
                                  onChange={({ fileList }) => {if(fileList[0].response){setLicense(fileList[0].response.data)}}}>
                                <div className="uploadBtn-1">
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>上传图片</div>
                                </div>
                            </Upload>
                        </div>
                    </Form.Item>
                    <Form.Item label="开户许可证" name="permit" rules={[{ required: true, message: "请上传营业执照!" }]}>
                        <div className="custom_upload_height flex-start">
                        <Image width='100px' height="100px" style={{marginRight:"10px"}} src={permit}></Image>
                            <Upload headers={uploadHeader()} action={process.env.REACT_APP_API+"Upload/FileUpload"} accept="image/*" data={{ FileType: "1" }}
                                 onChange={({ fileList }) => {if(fileList[0].response){setPermit(fileList[0].response.data)}}}>
                                <div className="uploadBtn-1">
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>上传图片</div>
                                </div>
                            </Upload>
                        </div>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 3, span: 16 }}>
                        <Button style={{ width: "150px" }} type="primary" htmlType="submit">
                            更新
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <Modal title="选择机构" open={showOrgan} footer="" onCancel={() => setShowOrgan(false)}>
                <ChooseOrgan type={type} setShowChoose={setShowOrgan} invoiceOrgan={invoiceOrgan}></ChooseOrgan>
            </Modal>
        </div>
    )
}