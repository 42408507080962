import './dashboard.less'
import { useNavigate } from "react-router-dom";
import { companyMenu } from '../../config/menuConfig';

const companyIndex = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigate = useNavigate()
    const onNavPage = (children, key) => {
        if (!children && key !== 'dashboard') navigate(key)
    }
    function RecursionMenu(props) {
        let { label, children, index, url } = props
        return (
            <div className={index}>
                {label!=='首页'&&<div onClick={() => onNavPage(children, url)}>{label}</div>}
                {children && children.map(child => <RecursionMenu label={child.label} children={child.children} key={child.key} url={child.key} index="dashmenu"></RecursionMenu>)}
            </div>
        )
    }
    return (
        <div className="custom_auto">
            <div className="custom_pack">
                {companyMenu.map((child) => <RecursionMenu label={child.label} children={child.children} key={child.key} url={child.key} index='submenu'></RecursionMenu>)}
            </div>
        </div>
    );
};

export default companyIndex;
