import { Button, Table, Modal, message, DatePicker, Select, } from "antd";
import { API_getInvoiceList,API_getCompanyList, API_invoiceApply,   API_getContractList } from "@/service/common";
import React, { useEffect, useRef, useState } from "react";
import dayjs from 'dayjs';
import InvoiceTemplate from "@/components/invoiceTemplate/invoiceTemplate";
export default function InvoiceDownload() {
    const { RangePicker } = DatePicker;
    const { Option } = Select;
    
    const ref = useRef();
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [applyList, setApplyList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [invoiceShow, setInvoiceShow] = useState(false);
    const [rangeTime,setRangeTime]=useState([])
    const [unitList, setUnitList] = useState([])
    const [unitId,setUnitId]=useState(0);
    const [contractList, setContractList] = useState([]);
    const [contractId, setContractId] = useState(0);
    const getList = () => {
        setApplyList([]);
        setLoading(true);
        let reqData={
            pageindex: pageIndex,
            pagesize: pageSize,
            accept: 3,
            contractid: contractId
        }
        if(rangeTime.length==2){
          reqData.startTime=rangeTime[0]+' 00:00:00';
          reqData.endTime=rangeTime[1]+' 23:59:59'
        }
        if(unitId){
            reqData.buyerId=unitId
        }
        API_getInvoiceList(reqData).then((res) => {
            setApplyList(res.data);
            setLoading(false);
            setTotal(res.total);
        });
    };

    useEffect(() => {
        getList();
    }, [
      pageIndex,
      pageSize,
      contractId,
      rangeTime,
      unitId
    ]);

    useEffect((e) => {
      API_getContractList({
        pageindex: 1,
        pagesize: 99,
        onlyuseless: false,
      }).then((res) => {
        res.data.unshift({header:'(暂不绑定合同)',contractId:0})
        setContractList(res.data);
      });
      API_getCompanyList({isSeller:false,pageSize:999}).then(res => {
          setUnitList(res.data)
      })
    }, []);

    const formateRange = (e)=>{
      if(!e){setRangeTime([])}
      let list=[];
      e.map(row=>{
        list.push(dayjs(row).format('YYYY-MM-DD'))
      })
      setRangeTime(list)
    }
    


    const onChange = (e) => {
        setPageIndex(e);
    };
    const [invoiceDetail, setInvoiceDetail] = useState();
    const openInvoiceDetail = (e) => {
        let reqData = e.invoiceNotice;
        reqData.orderState=e.orderState
        reqData.handleResult = {
            images: e.images,
            tax: e.tax,
            totalMoney: e.totalMoney,
        };
        setInvoiceDetail(reqData);
        setInvoiceShow(true);
    };
    const columns = [
        {
            title: "编号",
            dataIndex: "id",
            key: "id",
        },

        {
            title: "购货单位",
            dataIndex: "header",
            key: "header",
        },
        {
            title: "开票金额",
            dataIndex: "totalMoney",
            key: "totalMoney",
        },
        {
            title: "纳税人类型",
            dataIndex: "category",
            key: "category",
        },
        { title: "开票时间", width: 180, align: "center", dataIndex: "invoiceNotice",render:(e)=>{return e.time.split(' ')[0]} },
        {
            title: "发票详情",
            dataIndex: "header2",
            render: (text, record) => (
                <Button  type="primary" onClick={e => openInvoiceDetail(record)}>
                         查看发票
                         </Button>
            ),
        },
        // {
        //     title: "下载",
        //     dataIndex: "handleResult",
        //     render: (text, record) =>
        //         <Button danger type="primary" onClick={e => window.open(text)}>
        //             下载发票
        //         </Button>
        // }
    ];

    return (
        <div className="custom_auto">
            <div className="custom_pack custom_filter">

            <div className="flex-start">
              <div className="flex-text">购货单位:</div>
              <div>
                <Select
                  style={{ minWidth: 220 }}
                  value={unitId}
                  showSearch
                  optionFilterProp="children"
                  onChange={(e) => setUnitId(e)}
                >
                  <Option value={0}>全部</Option>
                  {unitList.length > 0 &&
                    unitList.map((data, index) => {
                      return <Option label={data.name} value={data.id}>{data.name}</Option>;
                    })}
                </Select>
              </div>
            </div>
            <div className="flex-start">
              <div className="flex-text">合同筛选:</div>
              <div>
                <Select
                  style={{ width: 120 }}
                  value={contractId}
                  onChange={(e) => setContractId(e)}
                >
                  <Option value={0}>全部</Option>
                  {contractList.length > 0 &&
                    contractList.map((data, index) => {
                      return data.contractId&&<Option value={data.contractId}>{data.header}</Option>;
                    })}
                </Select>
              </div>
            </div>
            <div className="flex-start" >
              <div className="flex-text">开票时间:</div>
              <div>
                 <RangePicker   onChange={e=>formateRange(e)}   format="YYYY-MM-DD"> </RangePicker>
              </div>
            </div>
            </div>
            <div className="custom_pack">
                <Table
                    dataSource={applyList}
                    columns={columns}
                    loading={loading}
                    rowKey={(columns) => columns.id}
                    pagination={{
                        pageSize: pageSize,
                        total: total,
                        onChange: onChange,
                        pageSizeOptions: [],
                    }}

                />
            </div>
            <Modal
                open={invoiceShow}
                footer={null}
                onCancel={(e) => { setInvoiceShow(false) }}
                wrapClassName="myInvoice-modal"
                width="80%"
                maskClosable={false}
                destroyOnClose={true}
            >
                <InvoiceTemplate
                    invoiceDetail={invoiceDetail}
                    disabled={true}
                    ref={ref}
                ></InvoiceTemplate>
            </Modal>
        </div>
    );
}
