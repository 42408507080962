import { Button, Table, Input, Modal, Form, message } from "antd";
import React, { useEffect, useState } from "react";
import { API_getCompanyList, API_editCompanyList, API_addCompanyList, API_deleteCompanyList, } from "@/service/common";
import { extractInvoiceInfo } from "../../../config/utils";
const { TextArea } = Input;
const UnitInfo = () => {
    const [unitList, setUnitList] = useState([])
    const [initSearchAfter, setInitSearchAfter] = useState(1);
    const [reqUnitInfo, setReqUnitInfo] = useState({
        pageIndex: 1,
        pageSize: 10,
        isseller: false
    })
    const [unitLoading, setUnitLoading] = useState(false)
    const [unitTotal, setUnitTotal] = useState(0)
    useEffect(() => {
        onGetUnitList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reqUnitInfo.pageIndex, reqUnitInfo.pageSize])
    const onGetUnitList = () => {
        setUnitLoading(true)
        API_getCompanyList(reqUnitInfo).then(res => {
            setUnitList(res.data)
            setUnitLoading(false)
            setUnitTotal(res.total);
        })
    }
    const [showUnit, setShowUnit] = useState(false)
    const [unitForm] = Form.useForm();
    const [unitId, setUnitId] = useState();
    const handleEdit = (row) => {
        setShowUnit(true);
        setUnitId(row.id);
        unitForm.setFieldsValue(row);
    };
    const handleEditUnitForm = async (form) => {
        form.isSeller = false;
        if (unitId) {
            form.id = unitId
            await API_editCompanyList(form).then((res) => {
                message.success("修改成功!");
            });
        } else {
            await API_addCompanyList(form).then((res) => {
                message.success("添加成功!");
            });
        }
        onGetUnitList();
        setShowUnit(false);
    }
    const onDelateUnit = (item) => {
        Modal.confirm({
            content: "是否删除此数据?",
            cancelText: "取消",
            okText: "确认",
            centered: true,
            onOk: () => {
                API_deleteCompanyList(item.id).then((res) => {
                    message.success("删除成功");
                    onGetUnitList()
                });
            },
        });
    }
    const columns = [
        {
            title: "名称",
            dataIndex: "name",
            align: 'center',
            fixed: 'left'
        },
        {
            title: "地址电话",
            dataIndex: "addressPhone",
            align: 'center',
        },
        {
            title: "税务登记号",
            dataIndex: "taxAccount",
            align: 'center',
            textWrap: "word-break",
        },
        {
            title: "开户银行及账号",
            dataIndex: "bankAccount",
            align: 'center',
            textWrap: "word-break",
        },
        {
            title: "单位类型",
            dataIndex: "isSeller",
            align: 'center',
            render: (text, record) => {
                return text ? <div>销货单位</div> : <div>购货单位</div>;
            },
        },
        {
            title: "操作",
            width: 240,
            align: 'center',
            fixed: 'right',
            render: (text, record) => (
                <div className="custom_func_area">
                    <Button onClick={(e) => { handleEdit(record) }} type="primary">
                        编辑
                    </Button>
                    <Button onClick={(e) => { onDelateUnit(record) }} danger>
                        删除
                    </Button>
                </div>
            ),
        },
    ];
      //自动识别  格式化 

  const formatText = (e)=>{
    let info= extractInvoiceInfo(e.target.value);
    console.log(info)
    unitForm.setFieldsValue({
      name:info.companyName||'',
      addressPhone:(info.address||"")+(info.phone||''),
      taxAccount:info.taxpayerId||"",
      bankAccount:(info.bank||"")+(info.account||"")
    })
  }
    return (
        <div className="custom_auto">
            <div className="custom_pack custom_filter">
                <Button onClick={() => setShowUnit(true)} type="primary">新增</Button>
            </div>
            <div className="custom_pack">
                <Table
                    dataSource={unitList}
                    columns={columns}
                    rowKey={(columns) => columns.id}
                    loading={unitLoading}
                    pagination={{
                        current: initSearchAfter,
                        total: unitTotal,
                        showTotal: () => {
                            return `共 ${unitTotal} 条`;
                        },
                        pageSizeOptions: [10, 20, 50, 100],
                        showSizeChanger: true,
                        showQuickJumper: true,
                        onChange(e) {
                            setInitSearchAfter(e);
                            setReqUnitInfo({ ...reqUnitInfo, pageIndex: e });
                        },
                        onShowSizeChange(e, size) {
                            reqUnitInfo.pageSize = size
                            setReqUnitInfo({ ...reqUnitInfo });
                        },
                    }}
                    scroll={{
                        x: 1500,
                        y: `calc(100vh - 395px)`,
                        scrollToFirstRowOnChange: true,
                    }}
                />
            </div>
            <Modal width={800} title="信息编辑" open={showUnit}
                afterClose={() => { unitForm.resetFields(); setUnitId("") }}
                onCancel={(e) => { setShowUnit(false) }}
                onOk={() => unitForm.submit()}

                destroyOnClose={true}
                maskClosable={false}>
                <Form form={unitForm} onFinish={handleEditUnitForm} initialValues={{}} labelCol={{ flex: "150px" }} labelWrap>
                    <Form.Item label="单位名称" name="name" rules={[{ required: true, message: "请输入销货单位" }]}>
                        <Input></Input>
                    </Form.Item>
                    <Form.Item label="地址电话" name="addressPhone" rules={[{ required: true, message: "请输入地址电话" }]}>
                        <Input></Input>
                    </Form.Item>
                    <Form.Item label="税务登记号" name="taxAccount" rules={[{ required: true, message: "请输入税务登记号" }]}>
                        <Input></Input>
                    </Form.Item>
                    <Form.Item label="开户银行及账号" name="bankAccount" rules={[{ required: true, message: "请输入开户银行及账号" }]}>
                        <Input></Input>
                    </Form.Item>
                    <Form.Item
            label="信息粘贴板"
          >
            <TextArea style={{fontSize:"14px"}} autoSize={{minRows:3}} onChange={formatText} placeholder="试试粘贴按格式排列的单位名称、地址电话、税务登记号、开户银行及账号,可快速识别信息并自动填入"></TextArea>
          </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default UnitInfo;
