import {
  Button,
  Table,
  Input,
  Modal,
  Form,
  message,
  Upload,
  Tag,
  Checkbox,
  Image,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  API_getCustomerList,
  API_updateCustomer,
  API_addCustomer,
  API_deleteCustomer,
} from "@/service/common";
import { UploadOutlined,DeleteOutlined } from "@ant-design/icons";
import FileModal from "../../../components/fileModal/fileModal";
import { useRef } from "react";
import {
  API_getServiceType,
  API_organGetStaff,
  API_updateCustomerService,
} from "../../../service/common";
import { CSVLink } from "react-csv";
import { arabicToChinese } from "../../../config/utils";
import AddressPicker from "../../../components/addressPicker/addressPicker";
import { uploadHeader } from "../../../utils/utils";


const headers = [
  { label: '企业名称', key: 'name' },
  {label:'',key:''},
  { label: '企业地址', key: 'address' },
  {label:'',key:''},
  { label: '联系方式', key: 'phone' },
  {label:'',key:''},
  { label: '服务人员', key: 'staff' },
];



const csvOptions = {
  headers: headers,
  mergeCells: [{ start: { row: 1, column: 1 }, end: { row: 3, column: 3 } }],
  columnStyles: {
    0: { width: '200px' }, // 或者使用列名：'columnName': { width: '200px' }
  }
};

const CustomerManage = () => {
  const [customerList, setCustomerList] = useState([]);
  const [initSearchAfter, setInitSearchAfter] = useState(1);
  const [reqCustomerInfo, setReqCustomerInfo] = useState({
    organid: localStorage.getItem("organID"),
    pageindex: 1,
    pagesize: 10,
  });
  const [customerLoading, setCustomerLoading] = useState(false);
  const [customerTotal, setCustomerTotal] = useState(0);
  const [photoList, setPhotoList] = useState([]);
  const [serverList, setServiceList] = useState([]);
  const [showServiceDetailModal, setShowServiceDetailModal] = useState(false);
  const [serviceDetail, setServiceDetail] = useState({ services: [] });
  const [customerService, setCustomerService] = useState([]);
  const [staffList,setStaffList]=useState([])
  const [point, setPoint] = useState([]);
  const [address, setAddress] = useState(null);

  const getAddress = (point,address) => {
    setAddress(address);
    setPoint(point)
  }
  useEffect((e) => {
    API_getServiceType().then((res) => {
      res.data.map((row) => {
 
        let ids = [];
        row.services.map((e) => {
          ids.push(e.id);
        });
        row.servicesIds = ids;
      });
      setServiceList(res.data);
    });
    API_organGetStaff().then(res=>{
      let staff=[];
      res.data.map(row=>{
        staff.push({label:row.name,value:row.id})
      })
      setStaffList(staff)
    })
  }, []);

  useEffect(() => {
    onGetCustomerList();
  }, [reqCustomerInfo.pageindex, reqCustomerInfo.pagesize]);
  const onGetCustomerList = () => {
    setCustomerLoading(true);
    API_getCustomerList(reqCustomerInfo).then((res) => {
      res.data.map(row=>{
        row.organStaffIds=row.staffIds;
      })
      setCustomerList(res.data);
      setCustomerLoading(false);
      setCustomerTotal(res.total);
    });
  };
  const [showCustomer, setShowCustomer] = useState(false);
  const [customerForm] = Form.useForm();
  const [customer, setCustomer] = useState({});
  const fileRef = useRef();
  const handleEdit = (row) => {
    setAddress(row.address);
    setPoint(row.point);
    setPhotoList([...row.photos]);
    setCustomer({ ...row });
    customerForm.setFieldsValue({ ...row });
    setShowCustomer(true);
  };



  //图片上传
  const onUpload= (info)=>{
    if (info.file.status === 'done') {
        photoList.push(info.file.response.data);
        setPhotoList([...photoList]);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} 上传失败.`);
      }
  }
 //图片删除
  const deleteImage= (index)=>{
    photoList.splice(index,1);
    setPhotoList([...photoList]);
  }

  const handleEditCustomerForm = async (form) => {
    form.serverItem = [];
    form.photos = photoList;
    form.address = address;
    form.point=point;
    if (customer.id) {
      form.id = customer.id;
      form.serverItem = customer.serverItem;
      await API_updateCustomer(form).then((res) => {
        message.success("修改成功!");
      });
    } else {
      await API_addCustomer(form).then((res) => {
        message.success("添加成功!");
      });
    }
    onGetCustomerList();
    setShowCustomer(false);
  };
  const onDelateCustomer = (item) => {
    Modal.confirm({
      content: "是否删除此客户?",
      cancelText: "取消",
      okText: "确认",
      centered: true,
      onOk: () => {
        API_deleteCustomer({ id: item.id }).then((res) => {
          message.success("删除成功");
          onGetCustomerList();
        });
      },
    });
  };

  const showServiceDetail = (e, itemList, customer) => {
    setCustomer(customer);
    setServiceDetail(e);
    setCustomerService([...itemList]);
    setShowServiceDetailModal(true);
  };

  const changeCheckBox = (e, id) => {
    if (e.target.checked && !customerService.includes(id)) {
      customerService.push(id);
    } else {
      customerService.splice(customerService.indexOf(id), 1);
    }
    setCustomerService([...customerService]);
  };

  const submitService = () => {
    Modal.confirm({
      content: "是否修改服务情况?",
      cancelText: "取消",
      okText: "确认",
      centered: true,
      onOk: () => {
        let form = {
          Id: customer.id,
          ServiceItem: customerService,
        };
        API_updateCustomerService(form).then((res) => {
          message.success("修改成功!");
          onGetCustomerList();
          setShowServiceDetailModal(false);
        });
      },
    });
  };

  const formatExcel = (detail,itemList)=>{
    let data = [];
    let obj = {
      name:detail['information'],
      address:detail['address'],
      phone:detail['contact'],
      staff:detail['serverStuff']
    }
    data.push(obj);
    data.push({});
    data.push({});
    data.push({});

    serverList.map((res,index)=>{
      data.push({name:arabicToChinese(index+1)+'、'+res.name});
      data.push({});
      res.services.map((e,index)=>{
        data.push({name:(index+1)+'.'+e.name,staff:itemList.includes(e.id)?"已完成":""})
        data.push({});
      })
      data.push({});
      data.push({});
    })
    return data;
  }

  const columns = [
    {
      title: "企业名称",
      dataIndex: "information",
      align: "center",
      fixed: "left",
    },
    {
      title: "企业地址",
      dataIndex: "address",
      align: "center",
    },
    {
      title: "联系方式",
      dataIndex: "contact",
      align: "center",
      width:100
    },
    {
      title: "服务人员",
      dataIndex: "stuff",
      align: "center"
    },
    {
      title: "资料照片",
      dataIndex: "photos",
      align: "center",
      render: (imgList, record) => {
        return (
          <Button
            type="primary"
            onClick={(e) => {
              fileRef.current.setFileContent(imgList);
              fileRef.current.show();
            }}
          >
            查看
          </Button>
        );
      },
    },
    {
      title: "项目服务情况",
      dataIndex: "serverItem",
      align: "left",
      fixed: "right",
      render: (itemList, record) => {
        return serverList.map((res,index) => {
          let color = "";
          if (
            res.servicesIds.every((item) => itemList.includes(item)) &&
            itemList.length > 0
          ) {
            color = "#87d068";
          } else if (
            res.servicesIds.every((item) => !itemList.includes(item)) 
          ) {
            color = "#f50";
          } else {
            color = "#4096ff";
          }
          return (
            <Tag
              onClick={(e) => showServiceDetail(res, itemList, record)}
              style={{ marginBottom: "5px", cursor: "pointer" }}
              color={color}
            >
              {index+1}:{res.name}
            </Tag>
          );
        });
      },
    },
    {
      title: "服务情况导出",
      dataIndex: "serverItem",
      align: "center",
      fixed: "right",
      render: (itemList, record) => {
        return   <CSVLink  data={formatExcel(record,itemList)}  filename="data.xlsx" target="_blank" headers={headers} options={csvOptions}><Button>导出Excel</Button></CSVLink>
      },
    },
    {
      title: "操作",
      width: 240,
      align: "center",
      fixed: "right",
      render: (text, record) => (
        <div className="custom_func_area">
          <Button
            onClick={(e) => {
              handleEdit(record);
            }}
            type="primary"
          >
            编辑企业
          </Button>
          <Button
            onClick={(e) => {
              onDelateCustomer(record);
            }}
            danger
          >
            删除
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="custom_auto">
      <div className="custom_pack custom_filter">
        <Button
          onClick={() => {
            setShowCustomer(true);
            setPhotoList([]);
            setCustomer({});
            setAddress('');
            setPoint([]);
          }}
          type="primary"
        >
          新增
        </Button>
      </div>
      <div className="custom_pack">
        <Table
          dataSource={customerList}
          columns={columns}
          rowKey={(columns) => columns.id}
          loading={customerLoading}
          pagination={{
            current: initSearchAfter,
            total: customerTotal,
            showTotal: () => {
              return `共 ${customerTotal} 条`;
            },
            pageSizeOptions: [10, 20, 50, 100],
            showSizeChanger: true,
            showQuickJumper: true,
            onChange(e) {
              setInitSearchAfter(e);
              setReqCustomerInfo({ ...reqCustomerInfo, pageIndex: e });
            },
            onShowSizeChange(e, size) {
              reqCustomerInfo.pageSize = size;
              setReqCustomerInfo({ ...reqCustomerInfo });
            },
          }}
          scroll={{
            x: 1500,
            y: `calc(100vh - 395px)`,
            scrollToFirstRowOnChange: true,
          }}
        />
      </div>
      <Modal
        width={800}
        title="信息编辑"
        open={showCustomer}
        afterClose={() => {
          customerForm.resetFields();
          setCustomer({});
        }}
        onCancel={(e) => {
          setShowCustomer(false);
        }}
        onOk={() => customerForm.submit()}
        destroyOnClose={true}
        maskClosable={false}
      >
        <Form
          form={customerForm}
          onFinish={handleEditCustomerForm}
          initialValues={{}}
          labelCol={{ flex: "150px" }}
          labelWrap
        >
          <Form.Item
            label="企业名称"
            name="information"
            rules={[{ required: true, message: "请输入企业名称" }]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label="企业地址"
            name="address"
            rules={[{ required: true, message: "请输入企业地址" }]}
          >
            <div className="flex-start">
              <Input onChange={e=>{setAddress(e.target.value)}} value={address} ></Input>
              <AddressPicker getAddress={getAddress}></AddressPicker>
            </div>
          </Form.Item>
          <Form.Item
            label="联系方式"
            name="contact"
            rules={[{ required: true, message: "请输入联系方式" }]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label="服务人员"
            name="organStaffIds"
            rules={[{ required: true, message: "请选择服务人员" }]}
          >
            <Select mode="multiple"   allowClear options={staffList}></Select>
          </Form.Item>
          <Form.Item
            label="资料照片"
            name="photos"
            rules={[{ required: true, message: "请添加资料照片" }]}
          >
            <div className="flex-start">

                {photoList.map((res,index) => {
                 return <div style={{marginRight:"20px"}}>
                    <Image  width={100} height={100}  src={res}></Image>
                    <div onClick={e=>deleteImage(index)} style={{textAlign:'center',cursor:"pointer",border:'1px solid #d3d3d3'}}><DeleteOutlined /></div>
                 </div>;
                })}
              <Upload
              headers={uploadHeader()}
                action={process.env.REACT_APP_API + "Upload/FileUpload"}
                onChange={onUpload}
              >
                <Button icon={<UploadOutlined />}>点击上传</Button>
              </Upload>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <FileModal ref={fileRef} title="资料照片"></FileModal>
      <Modal
        onOk={submitService}
        destroyOnClose={true}
        title={serviceDetail?.name}
        maskClosable={false}
        onCancel={(e) => setShowServiceDetailModal(false)}
        open={showServiceDetailModal}
      >
        {serviceDetail.services &&
          serviceDetail?.services.map((res,index) => {
            return (
              <div style={{ padding: "10px 0" }}>
                <Checkbox
                  onChange={(e) => changeCheckBox(e, res.id)}
                  defaultChecked={customerService.includes(res.id)}
                  checked={customerService.includes(res.id)}
                >
                  {index+1}:{res.name}
                </Checkbox>
              </div>
            );
          })}
      </Modal>
    </div>
  );
};

export default CustomerManage;
