import {
  Button,
  Table,
  Modal,
  Switch,
  Form,
  message,
  Upload,
  Image,
  Input,
  Tag,
  QRCode,
} from "antd";
import { API_getCooperativeContractList, API_addContractByCooperative, API_editContract, API_deleteContract } from "@/service/common";
import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import FileContent from "@/components/fileContent";
import { uploadHeader } from "../../../utils/utils";

/**
 * @fileName 合同管理
 * @param {*} props 
 * @returns 
 */

export default function ContractManage(props){
  const [form] = Form.useForm();
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(6);
  const [applyList, setApplyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [total, setTotal] = useState(0);
  const [type, setType] = useState(3);
  const [fileData, setFileData] = useState(false);
  const [invoiceShow, setInvoiceShow] = useState(false);
  const [editID, setEditID] = useState(0)
  const [checked,setChecked]=useState(false)
  const getList = () => {
    API_getCooperativeContractList({
      pageindex: pageIndex,
      pagesize: pageSize,
      accept: type,
    }).then((res) => {
      setApplyList(res.data);
      setLoading(false);
      setTotal(res.total);
    });
  };
  const onFinish = (values) => {
    let reqData = { ...values };
    console.log(reqData)
    reqData.conteactTextLink = fileData;
    reqData.expiration='2050-01-01'
    if (editID > 0) {
      reqData.contractId = editID;
      API_editContract(reqData).then((res) => {
        message.success("修改成功!");
        setInvoiceShow(false);
        getList();
      });
    } else {
      API_addContractByCooperative(reqData).then((res) => {
        message.success("添加成功!");
        setInvoiceShow(false);
        getList();
      });
    }

  };




  const deleteRecord = (item) => {
    Modal.confirm({
      content: "是否确认删除?", onOk: e => {
        API_deleteContract({ contractId: item.contractId }).then(res => {
          message.success("删除成功!");
          Modal.destroyAll()
          getList();
        })
      }
    })

  }

  const onUpload2 = (e, record) => {
    let response = e.file.response
    if (response) {
      if (response.status == 'OK') {
        setFileData(response.data)
      } else {
        message.error("上传失败!")
      }
    }
  };
  useEffect(
    (e) => {
      setApplyList([]);
      setLoading(true);
      getList();
    },
    [pageIndex, type]
  );
  const onChange = (e) => {
    setPageIndex(e);
  };



  const columns = [
    {
      title: "合同文件",
      align: "center",  
      dataIndex: "conteactTextLink",
      render: (text, record) => <FileContent downLoad={true} url={text}></FileContent>,
    },
    {
      title: "合同名称",
      align: "center",  
      dataIndex: "header",
      key: "header",
    },
    {
      title: "签名",
      dataIndex: "signature",
      align: "center",  
      key: "signature",
      render: (text, record) => (text ? <Image src={text} width='60px' height='60px'></Image> : <Tag color="#f50">无</Tag>),
    },
    {
      title: "签名地址",
      dataIndex: "contractId",
      key: "contractId",
      align: "center",  
      render: (text, record) =>(
        <QRCode
          errorLevel="H"
          size="120"
          bordered={false}
          iconSize='30'
          value={`http://zjzlsq.cn/project/zoneMobile/#/signToImage/contract/`+text}
          icon={require('../../../imgs/logo2.png')}
        />
      ),
    },
    {
      title: "是否公开",
      dataIndex: "isCanSeekContract",
      align: "center",  
      key: "isCanSeekContract",
      render: (text, record) => (text ? "是" : "否"),
    },
    {
      title: "添加时间",
      dataIndex: "createTime",
      align: "center",  
      key: "createTime",
    },
    {
      title: "操作",
      align: "center",  
      dataIndex: "handleResult",
      render: (text, record) => (
        props.choose ? (<div>
          <Button
            style={{ marginLeft: "10px" }}
            danger
            type="primary"
            onClick={(e) => props.chooseFun(record)}
          >
            选择
          </Button>
        </div>) : (
          <div>
            <Button
              type="primary"
              onClick={(e) => {
                setInvoiceShow(true);
                setEditID(record.contractId)
                console.log(record)
                form.setFieldsValue(JSON.parse(JSON.stringify(record)));
                setChecked(record.isCanSeekContract)
                setFileData(record.conteactTextLink);
              }}
            >
              编辑
            </Button>
            <Button
              style={{ marginLeft: "10px" }}
              danger
              type="primary"
              onClick={(e) => deleteRecord(record)}
            >
              删除
            </Button>
          </div>
        )

      ),
    },
  ];

  return (
    <div className="custom_auto">
      <div className="custom_pack custom_filter">
        <Button onClick={(e) => { setInvoiceShow(true); form.resetFields(); setFileData(''); setEditID(0);setChecked(false) }} type="primary">
          新增
        </Button>
      </div>
      <div className="custom_pack">
        <Table
          dataSource={applyList}
          columns={columns}
          loading={loading}
          rowKey={(columns) => columns.id}
          scroll={{
              y: `calc(100vh - 396px)`,
              scrollToFirstRowOnChange: true,
          }}
          pagination={{
            pageSize: pageSize,
            total: total,
            onChange: onChange,
            pageSizeOptions: [],
          }}
        />
      </div>
      <Modal
        open={invoiceShow}
        footer={null}
        onCancel={(e) => {
          setInvoiceShow(false);
        }}
        title="合同编辑"
        wrapClassName="myInvoice-modal"
        width="60%"
        maskClosable={false}
        destroyOnClose={true}
      >
        <Form
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          size="large"
          form={form}
        >
          <Form.Item
            label="文件名称"
            name="header"
            required
            rules={[{ required: true, message: "请输入文件名称" }]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label="文件图片"
            required
            rules={[{ required: true, message: "请输入地址电话" }]}
          >
            <Upload
              maxCount={1}
              headers={uploadHeader()}
              defaultFileList={fileData ? [{url:fileData}] : []}
              accept="image/*"
              onChange={(e) => onUpload2(e)}
              action={process.env.REACT_APP_API+"Upload/FileUpload"}
              listType="picture-card"
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>点击上传</div>
              </div>
            </Upload>
          </Form.Item>
          <Form.Item label="是否公开" name="isCanSeekContract" >
            <Switch defaultChecked={checked}></Switch>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <Button
              loading={loadingBtn}
              style={{ width: "150px" }}
              type="primary"
              htmlType="submit"
            >
              提交
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
