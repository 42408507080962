import { useState } from "react";
import Product from "../../components/shoppingMall/product";
import StoreTop from "../../components/shoppingMall/storeTop";
import "./storeDetail.less";
import { Button, Empty } from "antd";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { API_geCategoryList, API_getProductList, API_getStoreClasses } from "../../service/zlsq-api";
import { getQuery } from "../../config/utils";
const StoreDetail = (props) => {
  const params = useParams();
  const [storeID, setStoreID] = useState("");
  const [productList, setProductList] = useState([]);
  const [category, setCategory] = useState([]);
  const [categoryID, setCategoryID] = useState("");
  const [hasMore,setHasMore]=useState(true);
  const [loading,setLoading]=useState(false);
  const [pageIndex,setPageIndex]=useState(1);
  useEffect(e=>{
    if(params.storeID>0){
      setStoreID(params.storeID)
      API_getStoreClasses({start:1,count:99,busnessId:params.storeID}).then(res=>{
        setCategory(res.data)
      })
    }else{
      API_geCategoryList({id:'936'}).then(res=>{
        res.data.subCategoryList.map(data=>{
           data.Name=data.name;
           data.Id=data.ID
        })
        setCategory(res.data.subCategoryList)
     })
    }

      // setProductList(props.productList)

  },[params.storeID])

  useEffect(e=>{
    getList(true)
  },[categoryID])

  const changeCategory = (id) => {
    setCategoryID(id);
  }

  const getList =(first=false)=>{
    if(loading){return}
    let reqData={
      uid:params.storeID||"",
      start:first?1:pageIndex,
      count:20,
      isSoldOut:false,
      orderBy:'asc',
      categoryID:categoryID||'936',
      categoryClass:categoryID?2:1
    }
    setLoading(true)
    API_getProductList(reqData).then(res=>{
      
      setLoading(false)
      if(first){
        setPageIndex(2)
        setProductList(res.data)
        setHasMore(true)
      }else{
        setPageIndex(pageIndex+1)
        setProductList([...productList,...res.data])
      }
      
      if(res.data.length<20){
        setHasMore(false)
      }
    })
  }


  return (
    <div className="storeDetail_page">
      {storeID>0 && <StoreTop id={storeID}></StoreTop>}
      <div className="storeDetail_content">
        <div className="category_content">
          <div className="title">分类:</div>
          <div className="list flex-start">
            <div className={`list_item ${categoryID==''?'active':''}`} onClick={e=>changeCategory('')}>全部</div>
            {category.map((data) => (
                  <div className={`list_item ${categoryID==data.Id?'active':''}`} onClick={e=>changeCategory(data.Id)}>{data.Name}</div>
            ))}
       
          </div>
        </div>
        <div className="product_content2 flex-start">
          {productList.map((data) => (
            <Product itemData={data}></Product>
          ))}
          {productList.length == 0 && (
            <div
              className="flex-center"
              style={{ marginTop: "30px", width: "100%" }}
            >
              <Empty description="暂无商品" />
            </div>
          )}

          
        </div>
        <div style={{textAlign:"center",marginTop:"50px"}}><Button disabled={!hasMore} onClick={e=>getList()}  loading={loading}>{hasMore?'查看更多':'没有更多了'}</Button></div>
      </div>
    </div>
  );
};

export default StoreDetail;
