/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  API_GetStaffs,
  API_AddStaff,
  API_UpdateStaff,
  API_RemoveStaff,
} from "@/service/common";
import { Button, Form, Image, Input, message, Modal, QRCode, Table,Tag,Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { uploadHeader } from "../../../utils/utils";

export default function StaffManage() {
  const [staffList, setStaffList] = useState([]);
  const [reqStaffInfo, setReqStaffInfo] = useState({
    accept: 0,
    pageIndex: 1,
    pageSize: 10,
  });
  const [staffLoading, setStaffLoading] = useState(false);
  const [staffTotal, setStaffTotal] = useState(0);
  const [showStaff, setShowStaff] = useState(false);
  const [staffInfo] = Form.useForm();
  const [staffId, setStaffId] = useState();
  const [qrCodeList, setQrCodeList] = useState([]);
  const [contract, setContract] = useState([]);
  useEffect(() => {
    onGetStaffList();
  }, [
    reqStaffInfo,
    reqStaffInfo.accept,
    reqStaffInfo.pageIndex,
    reqStaffInfo.pageSize,
  ]);
  const onGetStaffList = () => {
    setStaffLoading(true);
    API_GetStaffs(reqStaffInfo).then((res) => {
      setStaffTotal(res.total);
      setStaffList(res.data);
      setStaffLoading(false);
    });
  };
  const columns = [
    {
      title: "员工名称",
      align: "center",
      dataIndex: "staffName",
      fixed: "left",
    },
    { title: "联系方式", align: "center", dataIndex: "staffPhone" },
    { title: "添加时间", align: "center", dataIndex: "createTime" },
    {
      title: "合同签名",
      dataIndex: "eSignature",
      align: "center",  
      key: "eSignature",
      render: (text, record) => (text ? <Image src={text} width='60px' height='60px'></Image> : <Tag color="#f50">无</Tag>),
    },
    {
      title: "签名地址", 
      align: "center",  
      dataIndex: "staffId",
      key: "staffId",
      render: (text, record) =>(
         record.contract ? <QRCode
         errorLevel="H"
         size="120"
         bordered={false}
         iconSize='30'
         value={`http://zjzlsq.cn/project/zoneMobile/#/sc/`+text}
         icon={require('../../../imgs/logo2.png')}
       /> : <Tag color="#f50">未上传合同</Tag>
      ),
    },
    {
      width: 240,
      title: "操作",
      align: "center",
      fixed: "right",
      dataIndex: "operation",
      render: (text, row) => (
        <div className="custom_func_area">
          <Button onClick={() => handleEditStaff(row)} type="primary">
            编辑
          </Button>
          <Button onClick={() => handleDeleteStaff(row)} danger>
            删除
          </Button>
        </div>
      ),
    },
  ];
  const handleEditStaff = (row) => {
    staffInfo.setFieldsValue(row);
    if(row.staffPaymentCode){
        setQrCodeList([{url:row.staffPaymentCode,uid:row.staffId}])
    }else{
        setQrCodeList([])
    }

    if(row.contract){
      setContract([{url:row.contract,uid:row.staffId}])
    }else{
      setContract([])
    }

    setStaffId(row.staffId);
    setShowStaff(true);
  };
  const handleDeleteStaff = (row) => {
    Modal.confirm({
      content: "是否删除此数据?",
      cancelText: "取消",
      okText: "确认",
      centered: true,
      onOk: () => {
        API_RemoveStaff({ staffId: row.staffId }).then((res) => {
          message.success("删除成功");
          onGetStaffList();
        });
      },
    });
  };
  const handleEditRemark = async (form) => {
    form.staffPaymentCode=qrCodeList[0].url
    form.contract=contract[0].url
    if (staffId) {
      form.staffId = staffId;

      await API_UpdateStaff(form).then((res) => {
        message.success("修改完成");
      });
    } else {
      await API_AddStaff(form).then((res) => {
        message.success("添加完成");
      });
    }
    setShowStaff(false);
    onGetStaffList();
  };
  return (
    <div className="custom_auto">
      <div className="custom_pack custom_filter">
        <Button
          type="primary"
          onClick={(e) => {
            setShowStaff(true);
            setQrCodeList([]);
            setContract([]);
          }}
        >
          添加员工
        </Button>
      </div>
      <div className="custom_pack">
        <Table
          dataSource={staffList}
          columns={columns}
          loading={staffLoading}
          rowKey={(columns) => columns.id}
          pagination={{
            showTotal: () => `共 ${staffTotal} 条`,
            pageSizeOptions: [10, 20, 50, 100],
            showSizeChanger: true,
            pageSize: reqStaffInfo.pageSize,
            total: staffTotal,
            onChange(pageIndex, pageSize) {
              console.log(pageIndex, pageSize);
              setReqStaffInfo({ ...reqStaffInfo, pageIndex, pageSize });
            },
          }}
          scroll={{
            x: 1000,
            y: `calc(100vh - 396px)`,
            scrollToFirstRowOnChange: true,
          }}
        />
      </div>
      <Modal
        title="编辑员工"
        visible={showStaff}
        onOk={() => staffInfo.submit()}
        onCancel={() => setShowStaff(false)}
        afterClose={() => staffInfo.resetFields()}
      >
        <Form
          form={staffInfo}
          onFinish={handleEditRemark}
          initialValues={{}}
          labelCol={{ span: 4, offset: 0 }}
        >
          <Form.Item
            label="员工名称"
            name="staffName"
            rules={[{ required: true, message: "请输入员工名称!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="联系方式"
            name="staffPhone"
            rules={[{ required: true, message: "请输入联系方式!" }]}
          >
            <Input type="tel" />
          </Form.Item>
          <Form.Item
            label="电子合同"
          >
            <div className="custom_upload_height">
              <Upload
              headers={uploadHeader()}
                action={process.env.REACT_APP_API+"Upload/FileUpload"}
                accept="image/*"
                listType="picture-card"
                fileList={contract}
                maxCount={1}
                onChange={({ fileList }) => {
                    if(fileList[0].status=='done'){
                        fileList[0].url=fileList[0].response.data
                    }
                  setContract(fileList);
                }}
              >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>点击上传</div>
                </div>
              </Upload>
            </div>
          </Form.Item>
          <Form.Item
            label="收款二维码"
          >
            <div className="custom_upload_height">
              <Upload
              headers={uploadHeader()}
                action={process.env.REACT_APP_API+"Upload/FileUpload"}
                accept="image/*"
                listType="picture-card"
                fileList={qrCodeList}
                maxCount={1}
                onChange={({ fileList }) => {
                    if(fileList[0].status=='done'){
                        fileList[0].url=fileList[0].response.data
                    }
                  setQrCodeList(fileList);
                }}
              >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>点击上传</div>
                </div>
              </Upload>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
