/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Table,
  Select,
  Modal,
  message,
  Tag,
  Empty,
  DatePicker,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  API_getInvoiceList,
  API_invoiceApply,
  API_getContractList,
} from "@/service/common";

import InvoiceTemplate from "@/components/invoiceTemplate/invoiceTemplate";
import dayjs from 'dayjs';
import FileContent from "@/components/fileContent";
import { API_addBusinessList, API_cancelInvoiceApply, API_discardInvoiceApply, API_getCompanyList, API_getTransactionList, API_updateInvoiceApply } from "../../../service/common";
import { useLocation } from "react-router-dom";
const { Option } = Select;
export default function InvoiceManage() {
  const location=useLocation()
  const { RangePicker } = DatePicker;
  const [invoiceList, setInvoiceList] = useState([]);
  const [contractId, setContractId] = useState(0);
  const [reqInvoiceInfo, setReqInvoiceInfo] = useState({
    accept: 0,
    pageIndex: 1,
    pageSize: 10,
  });
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [invoiceTotal, setInvoiceTotal] = useState(0);
  const [contractList, setContractList] = useState([]);
  const [fileContentShow,setFileContentShow]=useState(false)
  const [fileTitle,setFileTitle]=useState('');
  const [fileContent,setFileContent]=useState([]);
  const [rangeTime,setRangeTime]=useState([])
  const [unitList, setUnitList] = useState([])
  const [unitId,setUnitId]=useState(0)
  useEffect(() => {
    onGetInvoiceList();
  }, [
    reqInvoiceInfo.pageIndex,
    reqInvoiceInfo.pageSize,
  ]);
  useEffect((e) => {
    API_getContractList({
      pageindex: 1,
      pagesize: 99,
      Onlynormal: true,
    }).then((res) => {
      res.data.unshift({header:'(暂不绑定合同)',contractId:0})
      setContractList(res.data);
    });
    API_getCompanyList({isSeller:false,pageSize:999}).then(res => {
        setUnitList(res.data)
    })
  }, []);
  const onGetInvoiceList = () => {
    setInvoiceLoading(true);
    let reqData={
      contractid: contractId
    }
    if(rangeTime.length==2){
      reqData.startTime=rangeTime[0]+' 00:00:00';
      reqData.endTime=rangeTime[1]+' 23:59:59'
    }
    if(unitId){
      reqData.buyerId=unitId
    }
    API_getInvoiceList({ ...reqInvoiceInfo,...reqData }).then(
      (res) => {
        setInvoiceTotal(res.total);
        setInvoiceList(res.data);
        setInvoiceLoading(false);
      }
    );
  };
  //取消申请
  const cancelApply = (id) => {
    Modal.confirm({
      content: "是否取消申请?",
      cancelText: "取消",
      okText: "确认",
      centered: true,
      onOk: () => {
        API_cancelInvoiceApply({  orderId: id }).then((res) => {
          message.success("取消成功");
          onGetInvoiceList();
        });
      },
    });
  }

  //作废合同
  const discardApply = (id) => {
    Modal.confirm({
      content: "是否作废发票?",
      cancelText: "取消",
      okText: "确认",
      centered: true,
      onOk: () => {
        API_discardInvoiceApply({  orderId: id }).then((res) => {
          message.success("已提交作废申请");
          onGetInvoiceList();
        });
      },
    });
  }


  const showContract =(row)=>{
    if(row.contractlist.conteactTextLink=='限制查看'){
      message.error('合同被限制为不公开,无法查看,如已设置为公开,请重新绑定合同解决')
      return
    }
    setFileTitle('合同列表');
    setFileContent(row.contractlist.conteactTextLink.split(','));
    setFileContentShow(true)
  }
  const [invoiceInfo, setInvoiceInfo] = useState({});
  const invoiceRef = useRef();
  const [showInvoice, setShowInvoice] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [showBill, setShowBill] = useState(false);
  const [invoiceId, setInvoiceId] = useState();
  const columns = [
    {
      title: "编号",
      width: 135,
      align: "center",
      dataIndex: "id",
      fixed: "left",
    },
    { title: "购货单位", width: 180, align: "center", dataIndex: "header" },
    { title: "开票金额", width: 100, align: "center", dataIndex: "totalMoney" },
    { title: "开票时间", width: 180, align: "center", dataIndex: "invoiceNotice",render:(e)=>{return e.time.split(' ')[0]} },
    {
      title: "受理状态",
      align: "center",
      width: 100,
      dataIndex: "orderState",
      render: (data, row) => {
        let tag = null;
        switch (row.orderState) {
          case 1:
            tag = <Tag color="default">未受理</Tag>;
            break;
          case 2:
            tag = <Tag color="processing">已受理</Tag>;
            break;
          case 3:
            tag = <Tag color="success">已处置</Tag>;
            break;
          case 4:
            tag = <Tag color="error">已拒绝</Tag>;
          case 5:
            tag = <Tag color="success">已拒绝</Tag>;
            break;
          case 6:
            tag = <Tag>已取消</Tag>;
            break;
          case 7:
            tag = <Tag color="success">已支付</Tag>;
            break;
          case 8:
            tag = <Tag color="error">作废申请中</Tag>;
            break;
           case 9:
              tag = <Tag color="error">已作废</Tag>;
           break;
          default:
            tag = <Tag>无效状态</Tag>;
            break;
        }
        return tag;
      },
    },
    {
      title: "发票详情",
      width: 120,
      dataIndex: "data",
      align: "center",
      fixed: "right",
      render: (text, record) => {
        if(record.orderState==3||record.orderState==8){
          return <div className="link" onClick={e=>{setFileTitle('发票详情');setFileContent(record.images||[]);setFileContentShow(true)}}>发票详情</div>
        }else if(record.orderState==9){
          return <div className="link" onClick={e=>{setFileTitle('作废详情');setFileContent([record.discardNote]);setFileContentShow(true)}}>作废详情</div>
        }else{
          return   <Button onClick={(e) => onShowInvoiceInfo(record)} type="link">
          查看详情
        </Button>
        }
      },
    },
    {
      title: "绑定状态",
      width: 220,
      align: "center",
      fixed: "right",
      render: (text, row) => (
        <>
          {!row.contractId ? (
            <div style={{ marginBottom: "10px" }}><Tag color="error" >
            无合同
          </Tag></div>
          ) : (
            <div style={{ marginBottom: "10px" }}>
              <Tag style={{cursor:'pointer'}} color="processing" onClick={e=>{showContract(row)}}>合同:{row.contractlist.header}</Tag>
            </div>
          )}
          {!row.cTransactionId ? (
            <Tag color="error">无业务</Tag>
          ) : (
            <Tag style={{cursor:'pointer'}} color="processing" onClick={e=>{setFileTitle('业务列表');setFileContent(row.businesslist);setFileContentShow(true)}}>业务:{row.transactionDescribe}</Tag>
          )}
        </>
      ),
    },
    {
      title: "合同/业务绑定",
      width: 120,
      align: "center",
      fixed: "right",
      render: (text, row) => (
        <Button onClick={(e) => onShowBillInfo(row)} type="primary">
          修改绑定
        </Button>
      ),
    },
    {
      width: 120,
      title: "操作",
      align: "center",
      fixed: "right",
      dataIndex: "operation",
      render: (text, row) =>{
        if(row.orderState==4){
          return <Button
          onClick={(e) => {
            onReapplyInvoice(row);
          }}
          type="primary"
        >
          重新申请
        </Button>
        }else if(row.orderState==1){
          return <div>
            <Button
          onClick={(e) => {
            setInvoiceInfo(
              Object.assign(row.invoiceNotice, {
                type: row.eType,
                category: row.eCategory,
                id:row.id
              })
            );
            setDisabled(false);
            setShowInvoice(true);
          }}
          type="primary"
        >
          修改申请
        </Button>
        <Button
        style={{marginTop:"10px"}}
          onClick={(e) => {
             cancelApply(row.id)
          }}
          type="primary"
          danger
        >
          取消申请
        </Button>
          </div>
        }else if(row.orderState==3){
           return    <Button
             onClick={(e) => {
                discardApply(row.id)
             }}
             type="primary"
             danger
           >
             作废发票
           </Button>
        }
      }
    },
  ];

  // 发票详情
  const onShowInvoiceInfo = (row) => {
  
    let info=Object.assign(row.invoiceNotice, {
      type: row.eType,
      category: row.eCategory,
      orderState:row.orderState,
      notes:[row.discardNote],
      handleResult:{
        images: row.images || [],
        tax: row.tax,
        totalMoney: row.totalMoney,
      }
    })
    setInvoiceInfo(info);
    setDisabled(true);
    setShowInvoice(true);
  };

  const [billId, setBillId] = useState();
  const [invoiceContractId, setInvoiceContractId] = useState();
  // 账单详情
  const onShowBillInfo = (row) => {
    console.log(row.id,row.cTransactionId)
    setShowBill(true);
    setInvoiceId(row.id);
    setBillId(row.cTransactionId);
    setInvoiceContractId(row.contractId);
  };



  //获取合下业务
  const [transList, setTransList] = useState([]);
  useEffect(
    (e) => {
      if (invoiceContractId) {
        API_getTransactionList({
          ConId: invoiceContractId,
          pageIndex: 1,
          pageSize: 99,
        }).then((res) => {
          res.data.unshift({transactionDescribe:'(暂不绑定业务)',id:0})
          setTransList(res.data);
        });
      } else {
        setTransList([{transactionDescribe:'(暂不绑定业务)',id:0}]);
        setBillId(0)
      }
    },
    [invoiceContractId]
  );

  //绑定合同业务
  const bindBill =()=>{
    message.loading("正在提交");
    let reqData={
        applyId:invoiceId,
        contractId:invoiceContractId,
        cTransactionId:(invoiceContractId==0||billId==0)?'':billId
    }
    API_addBusinessList(reqData).then(res=>{
        message.success('绑定成功');
        onGetInvoiceList();
        setShowBill(false)
    })
}
  const onReapplyInvoice = (row) => {
    setInvoiceInfo(
      Object.assign(row.invoiceNotice, {
        type: row.eType,
        category: row.eCategory,
      })
    );
    setShowInvoice(true);
  };

  const handleEditInvoice = async () => {
    if (disabled) {
      setShowInvoice(false);
      return;
    }
    let invoiceData = invoiceRef.current.getInvoiceData();
    if (!invoiceData.buyer.name) {
      message.warning("未选择购货单位");
      return;
    }
    if (!invoiceData.goodsList.length) {
      message.warning("未填写报税项目");
      return;
    }
    if (!invoiceData.seller.name) {
      message.warning("未选择销货单位");
      return;
    }
    let validList = [];
    invoiceData.goodsList.forEach((data, index) => {
      data.price=data.price||0;
      data.amount=data.amount||0
      if (data.priceNum > 0) {
        data.money=data.priceNum
        validList.push(data);
      }
    });
    const reqData = {
      header: invoiceData.buyer.name,
      category: invoiceData.category,
      isCanSeekContract: true,
      type: invoiceData.type,
      money: invoiceData.money,
      invoiceNotice: {
        remarks: invoiceData.remarks,
        seller: invoiceData.seller,
        buyer: invoiceData.buyer,
        time: invoiceData.time,
        goodsList: validList,
      },
    };
    setConfirmLoading(true);
    if(invoiceData.id){
      reqData.invoicesId=invoiceData.id;
      API_updateInvoiceApply(reqData).then((res) => {
        message.success("修改成功!");
        setShowInvoice(false);
        setConfirmLoading(false);
        onGetInvoiceList();
      }).catch(e=>{
        setConfirmLoading(false);
      });
    }else{
      API_invoiceApply(reqData).then((res) => {
        message.success("申请成功!");
        setShowInvoice(false);
        setConfirmLoading(false);
        onGetInvoiceList();
      }).catch(e=>{
        setConfirmLoading(false);
      });
    }

  };

  const formateRange = (e)=>{
    if(!e){setRangeTime([])}
    let list=[];
    e.map(row=>{
      list.push(dayjs(row).format('YYYY-MM-DD'))
    })
    setRangeTime(list)
  }

  //

  return (
    <div className="custom_auto">

      <div className="custom_pack custom_filter">

        <div className="flex-start">
          <div className="flex-text">购货单位:</div>
          <div>
            <Select
              style={{ minWidth: 220 }}
              value={unitId}
              showSearch
              optionFilterProp="children"
              onChange={(e) => setUnitId(e)}
            >
              <Option value={0}>全部</Option>
              {unitList.length > 0 &&
                unitList.map((data, index) => {
                  return <Option label={data.name} value={data.id}>{data.name}</Option>;
                })}
            </Select>
          </div>
        </div>
        <div className="flex-start">
          <div className="flex-text">发票状态:</div>
          <div>
            <Select
              style={{ width: 120 }}
              value={reqInvoiceInfo.accept}
              onChange={(e) =>
                setReqInvoiceInfo({ ...reqInvoiceInfo, accept: e })
              }
            >
              <Option value={0}>全部</Option>
              <Option value={1}>未受理</Option>
              <Option value={2}>已受理</Option>
              <Option value={3}>已处置</Option>
              <Option value={4}>已拒绝</Option>
              <Option value={6}>已取消</Option>
              <Option value={8}>作废申请中</Option>
              <Option value={9}>已作废</Option>
            </Select>
          </div>
        </div>
        <div className="flex-start">
          <div className="flex-text">合同筛选:</div>
          <div>
            <Select
              style={{ width: 120 }}
              value={contractId}
              onChange={(e) => setContractId(e)}
            >
              <Option value={0}>全部</Option>
              {contractList.length > 0 &&
                contractList.map((data, index) => {
                  return data.contractId&&<Option value={data.contractId}>{data.header}</Option>;
                })}
            </Select>
          </div>
        </div>
        {location.pathname=='/taxpayer/invoiceManage'&&<Button
          type="primary"
          onClick={(e) => {
            setShowInvoice(true);
          }}
        >
          申请发票
        </Button>}
       
        <div className="flex-start" style={{width:'100%'}}>
          <div className="flex-text">开票时间:</div>
          <div>
             <RangePicker   onChange={e=>formateRange(e)}   format="YYYY-MM-DD"> </RangePicker>
          </div>
          <Button
            type="primary"
            onClick={(e) => {
              onGetInvoiceList(true);
            }}
            style={{backgroundColor:'#0bb90b',marginLeft:'20px'}}
          >
            搜索
          </Button>
        </div>

      </div>

      <div className="custom_pack">
        <Table
          dataSource={invoiceList}
          columns={columns}
          loading={invoiceLoading}
          rowKey={(columns) => columns.id}
          pagination={{
            showTotal: () => `共 ${invoiceTotal} 条`,
            pageSizeOptions: [10, 20, 50, 100],
            showSizeChanger: true,
            pageSize: reqInvoiceInfo.pageSize,
            total: invoiceTotal,
            onChange(pageIndex, pageSize) {
              setReqInvoiceInfo({ ...reqInvoiceInfo, pageIndex, pageSize });
            },
          }}
          scroll={{
            x: 1400,
            y: `calc(100vh - 396px)`,
            scrollToFirstRowOnChange: true,
          }}
        />
      </div>
      <Modal
        width="80%"
        style={{ top: 20 }}
        open={showInvoice}
        title="增值税发票开票通知单"
        onCancel={() => {
          setShowInvoice(false);
        }}
        afterClose={() => {
          setInvoiceInfo({});
          setDisabled(false);
        }}
        confirmLoading={confirmLoading}
        onOk={() => handleEditInvoice()}
        maskClosable={false}
        destroyOnClose={true}
      >
        <InvoiceTemplate
          invoiceDetail={invoiceInfo}
          disabled={disabled}
          ref={invoiceRef}
        ></InvoiceTemplate>
      </Modal>
      <Modal
        width={600}
        open={showBill}
        title="合同/业务绑定"
        onCancel={() => setShowBill(false)}
        onOk={(e) => {bindBill()}}
      >
        <div className="flex-start" style={{ margin: "40px 0" }}>
          <div className="flex-text">合同:</div>
          <div>
            <Select
              style={{ width: 320 }}
              value={invoiceContractId}
              onChange={(e) => setInvoiceContractId(e)}
            >
              {contractList.length > 0 &&
                contractList.map((data, index) => {
                  return <Option value={data.contractId}>{data.header}</Option>;
                })}
            </Select>
          </div>
        </div>

        <div className="flex-start">
          <div className="flex-text">业务:</div>
          <div>
            <Select
              style={{ width: 320 }}
              value={billId}
              onChange={(e) => setBillId(e)}
            >
              {transList.length > 0 &&
                transList.map((data, index) => {
                  return <Option value={data.id}>{data.transactionDescribe}</Option>;
                })}
            </Select>
          </div>
        </div>
      </Modal>
      <Modal
        open={fileContentShow}
        title={fileTitle}
        footer={null}
        onCancel={(e) => {
          setFileContentShow(false);
        }}
        wrapClassName="myInvoice-modal"
        width="80%"
        maskClosable={false}
        destroyOnClose={true}
      >
         {fileContent.map(res=>(
          <FileContent width='200px' url={res}></FileContent>
         ))}
        {fileContent.length==0&&<Empty></Empty>}
      </Modal>
    </div>
  );
}
