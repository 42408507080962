import './index.css';
import App from './App';
import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider, message } from 'antd';
import zhCN from "antd/lib/locale/zh_CN";
import { config as AmapReactConfig } from '@amap/amap-react';
message.config({ top: 70, duration: 2, maxCount: 1 }); // messsage配置
AmapReactConfig.version = "2.0"; // 默认2.0，这里可以不修改
AmapReactConfig.key = "030324c1d7cbaa237cbd0c4069f33a13";
AmapReactConfig.plugins = [
  "AMap.ToolBar",
  "AMap.MoveAnimation",
  "AMap.Geocoder",
  "AMap.AutoComplete",
  // 在此配置你需要预加载的插件，如果不配置，在使用到的时候会自动异步加载
];

createRoot(document.getElementById('root'))
  .render(
    <ConfigProvider locale={zhCN}>
      <App />
    </ConfigProvider>
  )

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
