import { useEffect, useState } from "react";
import { Badge, Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { organMenu, companyMenu } from "../config/menuConfig";
import { API_getInvoiceStatusTotal } from "../service/common";
import { cloneDeep } from "lodash";

export default function MenuPage(props) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [openKeys, setOpenKeys] = useState([]);
  const [items, setItems] = useState([]);

  useEffect(() => {
    let menuInter = null;
    if (props.type === "taxpayer") {
      setItems(companyMenu);
    } else {
      //获取发票状态数量
      // menuInter = setInterval(() => {
      //   updateMenu()
      // }, 10000);
      updateMenu()
    }
    // 页面刷新等情况打开菜单
    let rank = pathname.split("/");
    setOpenKeys([rank[2]]);
    return () => {
      clearInterval(menuInter);
    };
  }, []);

  const updateMenu= ()=>{
    API_getInvoiceStatusTotal()
    .then((res) => {
      let countNum = res.data;
      let newMenu = cloneDeep(organMenu);
      let menu = newMenu.find((item) => item.key === "invoiceManage");
      let totalNum=0;
      menu.children.map((res) => {
        if (res.code) {
          let itemCode = countNum.find(
            (item) => item.stateCode === res.code
          );
          if (itemCode) {
            res.unreadNum = itemCode.total;
            totalNum+=itemCode.total;
            res.label = (
              <div className="flex-start">
                {res.label}
                <Badge
                  style={{ marginLeft: "5px" }}
                  count={itemCode.total}
                ></Badge>
              </div>
            );
          }
        }
      });
      menu.label=(
        <div className="flex-start">
          {menu.label}
          <Badge
            style={{ marginLeft: "5px" }}
            count={totalNum}
          ></Badge>
        </div>
      );
      setItems(newMenu);
    })
    .catch((e) => {
      setItems(organMenu);
    });
  }
  // 打开submenu
  const onOpenChange = (keys) => {
    // 取消注释为单一展开submenu
    // const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);  // 有效submenu的key
    // if (subMenuKeys.indexOf(latestOpenKey) === -1) {
    setOpenKeys(keys);
    // } else {
    //   setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    // }
  };
  return (
    <Menu
      onClick={(e) => navigate(e.key)}
      onOpenChange={onOpenChange}
      openKeys={openKeys}
      selectedKeys={[pathname]}
      mode="inline"
      theme="dark"
      items={items}
    ></Menu>
  );
}
