/* eslint-disable react-hooks/exhaustive-deps */
import "./invoiceTemplate.less";
import { DatePicker, Form, Select, Input, Button, Modal, Divider, message, Space,Switch, Upload } from "antd";
import { PlusCircleOutlined ,DeleteOutlined} from "@ant-design/icons";
import React, { forwardRef, useEffect, useState } from "react";
import { API_getCompanyList, API_editCompanyList, API_addCompanyList, API_deleteCompanyList } from "../../service/common";
import moment from "moment";
import AntdCell from "../antdCell";
import { Link } from "react-router-dom";
import BigNumber from "bignumber.js";
import { componyCategories, invoiceTypes } from "../../utils/utils";
import { calculateParams, extractInvoiceInfo } from "../../config/utils";
import {ExcelRenderer} from 'react-excel-renderer';
import dayjs from 'dayjs';
const { TextArea } = Input;
const { Option } = Select;
const InvoiceTemplate = (props, ref) => {
  const dateFormat = 'YYYY-MM-DD';
  const { disabled, invoiceDetail } = props;
  const [time, setTime] = useState(dayjs(new Date()).format(dateFormat)); // 申请日期
  const [type, setType] = useState(1) // 发票类型
  const [category, setCategory] = useState(1) // 企业类别
  const keyList = [ // 表格参数
    { title: "项目名称", key: "name" },
    { title: "规格型号", key: "size" },
    { title: "计量单位", key: "unit" },
    { title: "数量", key: "amount" },
    { title: "单价(含税)", key: "price" },
    { title: "税率%", key: "rate" },
    { title: "税额", key: "rateNum" },
    { title: "含税总额", key: "priceNum" },
  ];
  const rateList = [0, 1, 3, 6, 9, 13, 17] // 税率
  const [rate, setRate] = useState(0); // 发票税率
  const [remarks, setRemark] = useState(''); // 备注
  const [buyer, setBuyer] = useState({}); // 购货单位信息
  const [seller, setSeller] = useState({}); // 销货单位信息
  const [company, setCompany] = useState([]);
  const [showCompany, setShowCompany] = useState(false);
  const [hasTax,setHasTax]=useState(true)
  const countGoodList=(goodsList)=>{
    let amountTotal=0;
    goodsList.map(row=>{
       
      if(row.amount&&row.price&&!row.money){
         row.money= BigNumber(row.amount).times(row.price).decimalPlaces(2).toNumber();
      }
      if(!row.priceWithTax){
        row.priceNum=row.money;
        row.priceNumWithTax=BigNumber(row.priceNum).times((row.rate/100)+1).decimalPlaces(2).toNumber()
        row.priceWithTax=BigNumber(row.price).times((row.rate/100)+1).decimalPlaces(10).toNumber()
      }else{
        row.priceNum=BigNumber(row.priceNumWithTax).dividedBy(row.rate/100+1).decimalPlaces(2).toNumber();
        row.price=BigNumber(row.priceWithTax).times(row.rate/100+1).decimalPlaces(10).toNumber()
      }
      row.rateNum = BigNumber(row.priceNum*row.rate/100).decimalPlaces(2).toNumber()||0;
      amountTotal+=row.priceNumWithTax;
      row.amount=row.amount||'';
      row.price=row.price||'';
    })
    setAmountTotal(BigNumber(amountTotal).decimalPlaces(2).toNumber())
    setTableData(goodsList);
  }
  useEffect((e) => {
    if (invoiceDetail.goodsList && invoiceDetail.goodsList.length > 0) {
      setHasTax(false)
      setType(invoiceDetail.type)
      setCategory(invoiceDetail.category)
      setRate(invoiceDetail.goodsList[0].rate);
      setBuyer(invoiceDetail.buyer || {});
      countGoodList(invoiceDetail.goodsList);
      setTime(invoiceDetail.time.split(' ')[0])
    } else {
      let row = {};
      let list = [];
      keyList.forEach((data) => {
        if (data.key === 'rate') {
          row[data.key] = 0;
        } else {
          row[data.key] = "";
        }
      });
      for (let i = 0; i < 5; i++) {
        list.push({ ...row });
      }
      setTableData(list);
    }
    if (invoiceDetail?.seller?.name) {
      setSeller(invoiceDetail.seller || {})
    } else {
      API_getCompanyList({pageindex:1,pagesize:1,isseller:true}).then(res=>{
        setSeller(res.data[0]||{})
      })
    }
  }, []);
  const getUserInfo = () => {
    API_getCompanyList({
      pageindex: 1,
      pagesize: 999,
      isseller: false,
    }).then((res) => {
      setCompany(res.data);
    });
  }
  const chooseCompany = () => {
    getUserInfo();
    setShowCompany(true)
  }
  const handleKeys = [
    { title: "开票金额", key: "totalMoney", type: "text" },
    { title: "附件", key: "images", type: "fileList" },
  ];
  const discardKeys = [
    { title: "作废附件", key: "notes", type: "fileList" }
  ];
  React.useImperativeHandle(ref, (e) => ({
    getInvoiceData() {
      let data={ time, type, category, goodsList: tableData, buyer, seller, remarks };
      data.money=amountTotal;
      data.time=data.time+' 12:00:00'
      if(invoiceDetail.id){
        data.id=invoiceDetail.id
      }
      return  data;
    },
  }));

  const [tableData, setTableData] = useState([]);
  const [changeValue, setChangeValue] = useState(false); // 取反重新计算金额
  const [taxAmountTotal, setTaxAmountTotal] = useState(0) // 税额总额
  const [amountTotal, setAmountTotal] = useState(0) // 含税总额

  useEffect(() => {
    if(tableData.length==0)return
    let initTaxAmountTotal = 0
    let initAmountTotal = 0
    tableData.forEach((row) => {
      row.rate = rate;
      if(row.priceNum){
        if(hasTax){
          row.price=BigNumber(row.priceWithTax).dividedBy((rate/100+1)).decimalPlaces(10).toNumber();
          row.priceNum=BigNumber(row.priceNumWithTax).dividedBy((rate/100+1)).decimalPlaces(2).toNumber();
        }else{
          row.priceWithTax=BigNumber(row.price).times(((rate/100)+1)).decimalPlaces(10).toNumber();
          row.priceNumWithTax=BigNumber(row.priceNum).times(((rate/100)+1)).decimalPlaces(2).toNumber();
        }
        initAmountTotal += row.priceNum*1;
        row.rateNum = BigNumber(row.priceNum*row.rate/100).decimalPlaces(2).toNumber()||0;
        console.log(row.priceNum*row.rate/100,row.rateNum)
        initTaxAmountTotal += row.rateNum*1
      }else{
        row.rateNum=""
      }
      setTableData([...tableData]);
    });
    setTaxAmountTotal(BigNumber(initTaxAmountTotal).decimalPlaces(2).toNumber())
    setAmountTotal(BigNumber(initAmountTotal).plus(initTaxAmountTotal).decimalPlaces(2).toNumber())
  }, [changeValue, rate]);
  const addRow = () => {
    let row = {};
    keyList.map((data) => {
      row[data.key] = "";
    });
    tableData.push({ ...row });
    setTableData([...tableData]);
  };
  const [addShow, setAddShow] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [form] = Form.useForm();
  const [record, setRecord] = useState({});
  const handleEditCompanyInfo = (item, e) => { // 编辑购货单位信息
    e.stopPropagation();
    form.setFieldsValue(item);
    setRecord(item);
    setAddShow(true);
  }
  const onFinish = (values) => {
    values.isSeller = false;
    if (record.id) {
      values.id = record.id
      API_editCompanyList(values).then((res) => {
        message.success("修改成功!");
        getUserInfo();
        setAddShow(false);
      });
    } else {
      API_addCompanyList(values).then((res) => {
        message.success("添加成功!");
        getUserInfo()
        setAddShow(false);
      });
    }
  };

  const del = (item, e,index) => {
    Modal.confirm({
      content: "是否删除此数据?",
      cancelText: "取消",
      okText: "确认",
      centered: true,
      onOk: () => {
          API_deleteCompanyList(item.id).then((res) => {
              message.success("删除成功");
              company.splice(index,1);
              setCompany([...company])
          });
      },
  });
    e.stopPropagation();
  }

  //自动识别  格式化 

  const formatText = (e)=>{
    let info= extractInvoiceInfo(e.target.value);
    console.log(info)
    form.setFieldsValue({
      name:info.companyName||'',
      addressPhone:(info.address||"")+(info.phone||''),
      taxAccount:info.taxpayerId||"",
      bankAccount:(info.bank||"")+(info.account||"")
    })
  }
  const delRow=(index)=>{
    tableData.splice(index,1);
    setTableData([...tableData])
  }
  /**
   * 
   * @param {*} value 传入值
   * @param {number} index 下标 
   * @param {string} key 修改的key
   * @param {boolean} changeTax 是否需要更改税额
   */
  const onChangeTableDate = (value, index, key, changeTax) => {

    tableData[index][key] = value;

    console.log(key)
    //清除总价

    if (changeTax){
    
      let result=calculateParams(tableData[index],key);
      tableData[index]=Object.assign(tableData[index],result);
      setChangeValue(!changeValue)
    }else{
         setTableData([...tableData])
    }
  }

  const importExcel = (e)=>{
    const file = e.file;
     ExcelRenderer(file, (err, resp) => {
       if (err) {
         console.log(err);
       } else {
        let list =resp.rows;
        let newList=[]
    
        for(let i=3;i<list.length;i++){
           let item={
            name:list[i][0],
            size:list[i][2],
            unit:list[i][3],
            amount:list[i][4],
            priceWithTax:list[i][5],
            rate:list[i][7]*100,
            priceNumWithTax:list[i][6]
           }
           newList.push(item)
        }
        countGoodList(newList)
        setRate(list[3][7]*100);
        setHasTax(true)
       }
     });
  }
  return (
    <div className="invo_main">
      <Space size={20}>
        <div className="invo_main_lit">
          <div>申请日期：</div>
          <DatePicker   defaultValue={dayjs(invoiceDetail?.time)} format={dateFormat} onChange={(e) => setTime(dayjs(e).format(dateFormat))} disabled={disabled}/>
        </div>
        <div className="invo_main_lit">
          <div>发票类型：</div>
          <Select defaultValue={1} value={type} options={invoiceTypes} onChange={(e) => { setType(e) }} style={{ width: '180px' }} disabled={disabled}></Select>
        </div>
        <div className="invo_main_lit">
          <div>企业类别：</div>
          <Select defaultValue={1} value={category} options={componyCategories} onChange={(e) => { setCategory(e) }} style={{ width: '180px' }} disabled={disabled}></Select>
        </div>
        {!disabled&&<div className="invo_main_lit">
          <div>是否含税：</div>
          <Switch  checked={hasTax} disabled={disabled} onChange={(e) => { setHasTax(e) }}  />
        </div>}
      </Space>
      <table cellPadding={0}  className="invo_table" border={1} cellSpacing={0}>
      <thead>
        <tr>
          <th rowSpan={2} colSpan={2}>
            <div>购货单位</div>
            {!disabled && (
              <Button onClick={chooseCompany} type="primary">
                选择购货单位
              </Button>
            )}
          </th>
          <th className="invo_gray">名称</th>
          <th colSpan={2}>{buyer.name || ''}</th>
          <th className="invo_gray">税务登记号</th>
          <th colSpan={2}>{buyer.taxAccount || ''}</th>
        </tr>
        <tr>
          <th className="invo_gray">地址电话</th>
          <th colSpan={2}>{buyer.addressPhone || ''}</th>
          <th className="invo_gray">开户银行及帐号</th>
          <th colSpan={2}>{buyer.bankAccount || ''}</th>
        </tr>
        <tr>
          <td className="invo_gray">项目名称</td>
          <td className="invo_gray">规格型号</td>
          <td className="invo_gray">计量单位</td>
          <td className="invo_gray">数量</td>
          {hasTax?<td className="invo_gray">单价(含税)</td>:<td className="invo_gray">单价(不含税)</td>}
          {hasTax?<td className="invo_gray">金额(含税)</td>:<td className="invo_gray">金额(不含税)</td>}
          <td className="invo_gray">税率%</td>
          <td className="invo_gray">税额</td>
  
        </tr>
        </thead>
        <tbody className="tbody">
        {
           tableData.map((data, index) => {
             return (
               <tr key={index}>
                 <td className={`invo_light ${!disabled&&'flex-start'}`} >
                   {index+1}.
                   {!disabled&&<div className="del-row" onClick={e=>delRow(index)}><DeleteOutlined style={{color:'red'}} /></div>}
                   {disabled ? data.name : <Input value={data.name} onChange={(e) => onChangeTableDate(e.target.value, index, 'name')}></Input>}
                 </td>
                 <td className="invo_light">
                   {disabled ? data.size : <Input value={data.size} onChange={(e) => onChangeTableDate(e.target.value, index, 'size')}></Input>}
                 </td>
                 <td className="invo_light">
                   {disabled ? data.unit : <Input value={data.unit} onChange={(e) => onChangeTableDate(e.target.value, index, 'unit')}></Input>}
                 </td>
                 <td className="invo_light">
                   {disabled ? data.amount : <Input value={data.amount} onChange={(e) => onChangeTableDate(e.target.value, index, 'amount', true)} type="number"></Input>}
                 </td>
                 <td className="invo_light">
                   {disabled ? (hasTax?data.priceWithTax:data.price) : (<>{!hasTax?<Input value={data.price} onChange={(e) => onChangeTableDate(e.target.value, index, 'price', true)} type="number"></Input>:<Input value={data.priceWithTax} onChange={(e) => onChangeTableDate(e.target.value, index, 'priceWithTax', true)} type="number"></Input>}</>)}
                 </td>
                 <td className="invo_light">
                 {disabled ? (hasTax?data.priceNumWithTax:data.priceNum) : (<>{!hasTax?<Input value={data.priceNum} onChange={(e) => onChangeTableDate(e.target.value, index, 'priceNum', true)} type="number"></Input>:<Input value={data.priceNumWithTax} onChange={(e) => onChangeTableDate(e.target.value, index, 'priceNumWithTax', true)} type="number"></Input>}</>)}
                 </td>
                 <td className="invo_light">
                   {disabled ? data.rate :
                     <Select
                       value={rate}
                       size="large"
                       bordered={false}
                       onChange={(e) => setRate(e)}>
                       {rateList.map((data) => (
                         <Option value={data}>{data}</Option>
                       ))}
                     </Select>}
                 </td>
                 <td className="invo_light">
                   {data.rateNum}
                 </td>

               </tr>
             )
           })}
        </tbody>
        <tfoot>
        {!disabled && (
          <tr>
            <td colSpan={8} className="invo_pad5">
              <div className="flex-center">
              <Button style={{marginRight:'200px'}} type="primary" className="row_btn" onClick={addRow}>
                  插入行
                </Button>
                <div className="flex">
                <Upload  className="row_btn"  customRequest={importExcel} itemRender={e=><></>}>
                    <Button type="primary" style={{width:'100%'}}  danger className="row_btn"  >
                        导入表格
                      </Button>
                  </Upload>
                  <a style={{marginLeft:'20px'}} download href="https://zjzlsq.cn/file/发票开具项目信息导入模板(含税).xlsx">下载模板</a>
                </div>
              </div>
          
            </td>
          </tr>
        )}
        <tr className="invo_total">
          <td className="invo_gray">价税合计</td>
          <td colSpan={6}></td>
          <td>¥{amountTotal}</td>
        </tr>
        <tr>
          <td rowSpan={4} colSpan={2}>
            <div>销货单位</div>
            {!disabled && (
              <Link to="/taxpayer/infoManage/taxpayerInfo">
                <Button type="primary">编辑销货单位</Button>
              </Link>
            )}
          </td>
          <td className="invo_gray">名称</td>
          <td colSpan={2}>{seller.name || ''}</td>
          <td rowSpan={4} colSpan={3} className="invo_pad5">
            <TextArea
              rows={10}
              className="invo_remark"
              defaultValue={invoiceDetail?.remarks}
              disabled={disabled}
              placeholder="备注"
              onChange={(e) => setRemark(e.target.value)}>
            </TextArea>
          </td>
        </tr>
        <tr>
          <td className="invo_gray">税务登记号</td>
          <td colSpan={2}>{seller.taxAccount || ''}</td>
        </tr>
        <tr>
          <td className="invo_gray">地址电话</td>
          <td colSpan={2}>{seller.addressPhone || ''}</td>
        </tr>
        <tr>
          <td className="invo_gray">开户银行及帐号</td>
          <td colSpan={2}>{seller.bankAccount || ''}</td>
        </tr>
        </tfoot>
      </table>
      {invoiceDetail?.orderState == 3 && (
        <>
          <Divider>处置反馈</Divider>
          <div style={{ width: "50%" }}>
            <AntdCell
              columns={handleKeys}
              dataSource={invoiceDetail.handleResult}
            ></AntdCell>
          </div>
        </>
      )}
       {invoiceDetail?.orderState==9 && (
        <>
          <Divider>作废反馈</Divider>
          <div style={{ width: "50%" }}>
            <AntdCell
              columns={discardKeys}
              dataSource={invoiceDetail}
            ></AntdCell>
          </div>
        </>
      )}
      <Modal open={showCompany} onCancel={(e) => { setShowCompany(false) }} title="选择购货单位" width="70%" footer={null} wrapClassName="invoice-company">
        <div className="company_list">
          <div className="company_item company_add" onClick={() => setAddShow(true)}>
            <PlusCircleOutlined style={{ fontSize: 45, color: '#1677ff' }} />
            <div style={{ color: '#909090', paddingTop: '14px' }}>新增购货单位</div>
          </div>
          {company.map((data,index) => (
            <div className="company_item" onClick={() => { setBuyer(data); setShowCompany(false) }}>
              <div className="company_item_cell">
                <div className="company_item_label">单位名称</div>
                <div className="company_item_value">{data.name}</div>
              </div>
              <div className="company_item_cell">
                <div className="company_item_label">地址电话</div>
                <div className="company_item_value">{data.addressPhone}</div>
              </div>
              <div className="company_item_cell">
                <div className="company_item_label">税务登记号</div>
                <div className="company_item_value">{data.taxAccount}</div>
              </div>
              <div className="company_item_cell">
                <div className="company_item_label">开户银行及账号</div>
                <div className="company_item_value">{data.bankAccount}</div>
              </div>
              <div className="company_item_func">
                <Button onClick={e => handleEditCompanyInfo(data, e,index)} type="primary">编辑</Button>
                <Button onClick={e => { del(data, e,index) }} type="primary" danger>删除</Button>
              </div>
            </div>
          ))}
        </div>
      </Modal>
      <Modal
        open={addShow}
        footer={null}
        onCancel={(e) => {
          setAddShow(false);
        }}
        wrapClassName="myInvoice-modal"
        maskClosable={false}
        destroyOnClose={true}
        title="信息编辑"
      >
        <Form
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          size="large"
          form={form}
        >
          <Form.Item
            label="单位名称"
            name="name"
            required
            rules={[{ required: true, message: "请输入销货单位" }]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label="地址电话"
            name="addressPhone"
            required
            rules={[{ required: true, message: "请输入地址电话" }]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label="税务登记号"
            name="taxAccount"
            required
            rules={[{ required: true, message: "请输入税务登记号" }]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label="开户银行及账号"
            name="bankAccount"
            required
            rules={[{ required: true, message: "请输入开户银行及账号" }]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label="信息粘贴板"
          >
            <TextArea style={{fontSize:"14px"}} autoSize={{minRows:3}} onChange={formatText} placeholder="试试粘贴按格式排列的单位名称、地址电话、税务登记号、开户银行及账号,可快速识别信息并自动填入"></TextArea>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6, span: 14 }}>
            <Button
              loading={loadingBtn}
              style={{ width: "150px" }}
              type="primary"
              htmlType="submit"
            >
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default forwardRef(InvoiceTemplate);
