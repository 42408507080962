import ajax from "../config/ajax";

//登陆
export const API_accountLogin = (data) =>
  ajax.post("/UserLogin/UserLogin", data);

//
export const API_accountLoginMsg = (data) =>
  ajax.post("/UserLogin/ValidCodeLogin", data);

//注册
export const API_register = (data) =>
  ajax.post("/UserLogin/UserRegister", data);

//获取验证码
export const API_getMsgCode = (data) =>
  ajax.post("/UserLogin/GetValidCode", data);

//验证手机号
export const API_checkMsgCode = (data) =>
  ajax.post("/UserLogin/ValidPhone", data);

//获取用户信息

export const API_getUserInfo = () => ajax.get("/UserLogin/UserGetMess");

//机构申请

export const API_organRegister = (data) =>
  ajax.post("/UserLogin/OrganRegister", data);

//公司申请

export const API_companyRegister = (data) =>
  ajax.post("/CompanyRegistration/CompanyRegistrationApply", data);

//审核

export const API_companyVerify = (data) => ajax.post("ServiceSign/Acceptance", data);

export const API_companyVerify2 = (data) => ajax.post("ServiceSign/Handle", data);

//获取公司列表

export const API_companyApplyList = (data) =>
  ajax.get("CompanyRegistration/GetOrgans", data);

//申请发票
export const API_invoiceApply = (data) => ajax.post("ManagementOfInvoices/InvoicesApply", data);

//修改发票
export const API_updateInvoiceApply = (data) => ajax.post("ManagementOfInvoices/InvoicesUpdate", data);

//驳回发票
export const API_rejectInvoiceApply = (data) => ajax.put("ManagementOfInvoices/orderstatusmodify", data);

//取消申请
export const API_cancelInvoiceApply = (data) => ajax.post("ManagementOfInvoices/InvoicesCancel", data);

//作废发票
export const API_discardInvoiceApply = (data) => ajax.post("ManagementOfInvoices/InvoicesDiscardApply", data);

//作废确认
export const API_discardInvoiceApplyHandle = (data) => ajax.post("ManagementOfInvoices/InvoicesDiscardHandle", data);

//合作社申请发票
export const API_CooperativeInvoicesApply = (data) => ajax.post("ManagementOfInvoices/CooperativeInvoicesApply", data);

//用户获取发票列表
export const API_getInvoiceList = (data) =>
  ajax.get("ManagementOfInvoices/UserGetApplys", data);

//获取机构列表

export const API_organList = (data) => ajax.get("Organ/GetOrgans", data);

//获取发票申请列表

export const API_getInvoiceApplyList = (data) =>
  ajax.get("ManagementOfInvoices/GetApplys", data);

// 发票详情
export const API_getInvoiceApplyDetail = (data) => ajax.get("ManagementOfInvoices/GetDetail", data);

//发票受理

export const API_acceptInvoiceApply = (data) =>
  ajax.post("ManagementOfInvoices/Acceptance", data);

//发票拒绝受理

export const API_refuseInvoiceApply = (data) =>
  ajax.post("ManagementOfInvoices/Refuse", data);

//发票处置
export const API_handleInvoiceApply1 = (data) =>
  ajax.post("ManagementOfInvoices/Handle", data);

export const API_handleInvoiceApply = (data) =>
  ajax.post("ManagementOfInvoices/ReHandle", data);

//申请报账

export const API_reimbursementApply = (data) =>
  ajax.post("Bookkeeping/Apply", data);

//用户获取报账列表

export const API_getReimbursementList = (data) =>
  ajax.get("Bookkeeping/UserGetApplys", data);

//获取报账申请列表

export const API_getReimbursementApplyList = (data) =>
  ajax.get("Bookkeeping/GetApplys", data);

//报账受理

export const API_acceptReimbursementApply = (data) =>
  ajax.post("Bookkeeping/Acceptance", data);

//报账处置

export const API_handleReimbursementApply = (data) =>
  ajax.post("Bookkeeping/Handle", data);

//获取新闻列表

export const API_getNews = (data) => ajax.get("NationalPolicy/Gets", data);

//获取消息列表

export const API_getMsgs = (data) => ajax.get("SMS", data);

//消息已读

export const API_readMsgs = (data) =>
  ajax.post("SMS", data);

  export const API_readSingleMsgs = (data) =>
  ajax.post("SMS/MarkSingleMessagesRead", data);

//预填公司

export const API_getCompanyList = (data) =>
  ajax.get("UserLogin/UserGetRemarkCompanys", data);

export const API_addCompanyList = (data) =>
  ajax.post("UserLogin/AddCompany", data);

export const API_editCompanyList = (data) =>
  ajax.post("UserLogin/UpdateCompany", data);

export const API_deleteCompanyList = (data) =>
  ajax.post("UserLogin/RemoveCompany", data);

//获取自己的机构信息
export const API_getMyOrgan = (data) =>
  ajax.get("Organ/UserGetOrgan", data);

//更新机构信息

export const API_updateMyOrgan = (data) =>
  ajax.post("UserLogin/OrganUpdate", data);

//修改机构电话

export const API_updateMyOrganPhone = (data) =>
  ajax.post("UserLogin/OrganUpdatePhone", data);

//获取绑定机构
export const API_getMyBindOrgan = (data) => ajax.get("ServiceSign/UserGetApplys", data);

//解除绑定机构
export const API_CancelBindOrgan = (data) => ajax.post(`ServiceSign/ApplyCancel?organid=${data.organid}&type=${data.type}`);

// 绑定机构
export const API_bindMyBindOrgan = (data) => ajax.post("ServiceSign/Apply?organid=" + data.organid + "&type=" + data.type, {});

// 重新绑定机构
export const API_reBindMyBindOrgan = (data) => ajax.post("ServiceSign/ReApply", data);

//获取申请公司

export const API_getApplyCompany = (data) =>
  ajax.get("ServiceSign/GetApplys", data);

//拒绝公司申请

export const API_refuseCompanyApply = (data) => ajax.post("ServiceSign/Refuse", data);

export const API_invoiceAddList = (data) => ajax.post("ManagementOfInvoices/AddList", data);

export const API_getContractList = (data) => ajax.get("contract/user", data);

export const API_getCooperativeContractList = (data) => ajax.get("ManagementOfInvoices/CooperativeGetContracts", data);

export const API_addContract = (data) => ajax.post("contract", data)

export const API_addContractByCooperative = (data) => ajax.post("contract/cooperative", data)

export const API_editContract = (data) => ajax.put("contract", data)

export const API_deleteContract = (data) => ajax.delete("contract/"+data.id, data)

//激活合同
export const API_activateContract = (data) => ajax.post("contract/cancel/"+data.id, data)

export const API_addContractToInvoice = (data) => ajax.post("ManagementOfInvoices/AddContract", data)

export const API_getContractByInvoice = (data) => ajax.get("ManagementOfInvoices/GetContractsByID", data)

export const API_getCompanyDetail = (data) => ajax.get("UserLogin/GetUserCompanys", data)

//获取个体户审核
export const API_getInviteList = (data) => ajax.get('CompanyRegistration/GetGroupList', data)

export const API_getBalance = (data) => ajax.get('Wallet/Get', data)

//钱包充值
export const API_walletRecharge = (data) => ajax.post("Wallet/PayRequest", data)

// 获取员工列表
export const API_GetStaffs = (data) => ajax.get("Wages/GetStaffs", data);
// 获取员工列表
export const API_AddStaff = (data) => ajax.post("Wages/AddStaff", data);
// 获取员工列表
export const API_RemoveStaff = (data) => ajax.post("Wages/RemoveStaff", data);
// 获取员工列表
export const API_UpdateStaff = (data) => ajax.post("Wages/UpdateStaff", data);

export const API_getTransactionList = (data) => ajax.get("transaction", data);

export const API_addTransaction = (data) => ajax.post("transaction", data);

export const API_updateTransaction = (data) => ajax.put("transaction", data);

export const API_removeTransaction = (data) => ajax.delete("transaction/"+data.id, {});

// 获取现金流
export const API_GetCashFlowList = (data) => ajax.get("cash", data);

//删除现金流

export const API_RemoveCashFlow = (data) => ajax.delete("cash/"+data.id,{});

// 添加现金流
export const API_AddCashFlow = (data) => ajax.post("cash", data);


//获取掌联token

export const API_getZlsqToken = (data) => ajax.get("UserLogin/GetZLToken", data);



export const API_addBusinessList = (data) => ajax.post("ManagementOfInvoices/AddList", data);

// 绑定合同
export const API_AddContract = (data) => ajax.post("ManagementOfInvoices/AddContract", data);

//获取员工列表

export const API_getStaffList = (data) => ajax.get("Wages/GetStaffs", data);


//机构获取统计

export const API_getStatistics = (data) => ajax.post("ManagementOfInvoices/GetSumByTime", data);

//获取发票状态统计

export const API_getInvoiceStatusTotal= (data) => ajax.get("ManagementOfInvoices/GetApplysTotal", data);


//获取客户列表

export const API_getCustomerList = (data) => ajax.get("OrganCustom", data);

//添加客户

export const API_addCustomer = (data) => ajax.post("OrganCustom", data);

//更新客户

export const API_updateCustomer = (data) => ajax.put("OrganCustom", data);

//删除客户

export const API_deleteCustomer = (data) => ajax.delete("OrganCustom/"+data.id, {});

//修改客户服务

export const API_updateCustomerService = (data) => ajax.put("OrganCustom/service", data);


//添加服务类别

export const API_addServiceType = (data) => ajax.post("Organservice/classify", data);

//获取服务类别

export const API_getServiceType = (data) => ajax.get("Organservice/tree", data);

//修改服务类别

export const API_updateServiceType = (data) => ajax.put("Organservice/classify", data);

//删除服务类别

export const API_deleteServiceType = (data) => ajax.delete("Organservice/classify/"+data.id, data);


//添加服务

export const API_addService = (data) => ajax.post("OrganService", data);

//编辑服务

export const API_updateService = (data) => ajax.put("OrganService", data);

//删除服务  

export const API_deleteService = (data) => ajax.delete("OrganService/"+data.id, data);


//机构添加员工
export const API_organAddStaff = (data) => ajax.post("OrganStaff", data);

//机构获取员工
export const API_organGetStaff = (data) => ajax.get("OrganStaff", data);

//机构删除员工
export const API_organDeleteStaff = (data) => ajax.delete("OrganStaff/"+data.id, data);

//机构编辑员工
export const API_organUpdateStaff = (data) => ajax.put("OrganStaff", data);

//获取账号下子账户
export const API_getAccountSubAccount = (data) => ajax.get("UserLogin/GetSubUsers", data);

//子账号登录

export const API_subAccountLogin = (data) => ajax.post("UserLogin/SubUserLogin", data);
