import { AppstoreOutlined ,BarChartOutlined} from "@ant-design/icons";
import InvoiceStatistics from "../views/organ/statistics/invoiceStatistics";

/**
 * 需匹配的subMenu列表,无对应key则不展开菜单
 */
export const subMenuKeys = ["invoiceManage", "contractManage"];

// 机构菜单
export const organMenu = [
  {
    key: "invoiceStatistics",
    label: "开票统计",
    icon: <BarChartOutlined />,
  },
  {
    key: "companyManage",
    label: "公司管理",
    icon: <AppstoreOutlined />,
    children: [
      {
        key: "/organ/companyManage/1",
        label: "申请列表"
      },
      {
        key: "/organ/companyManage/2",
        label: "待签约列表",
      },
      {
        key: "/organ/companyManage/3",
        label: "已签约列表",
      },
      {
        key: "/organ/companyManage/4",
        label: "已拒绝列表",
      },
    ],
  },
  {
    key: "invoiceManage",
    label: "发票管理",
    icon: <AppstoreOutlined />,
    children: [
      {
        key: "/organ/invoiceManage/1",
        label: "申请列表",
        code:1
      },
      {
        key: "/organ/invoiceManage/2",
        label: "待处置列表",
        code:2
      },
      {
        key: "/organ/invoiceManage/3",
        label: "已处置列表"
      },
      {
        key: "/organ/invoiceManage/8",
        label: "待作废列表",
        code:8
      },
      {
        key: "/organ/invoiceManage/9",
        label: "已作废列表",
      },
      {
        key: "/organ/invoiceManage/4",
        label: "已拒绝列表",
      },
      {
        key: "/organ/invoiceManage/6",
        label: "已取消列表",
      },
    ],
  },
  {
    key: "cooperativeManage",
    label: "合作社管理",
    icon: <AppstoreOutlined />,
    children: [
      {
        key: "/organ/cooperativeManage/1",
        label: "申请列表",
      },
      {
        key: "/organ/cooperativeManage/3",
        label: "合作社列表",
      },
      {
        key: "/organ/cooperativeManage/0",
        label: "申请记录",
      },
    ],
  },
  {
    key: "customerManage",
    label: "客户管理",
    icon: <AppstoreOutlined />,
    children: [
      {
        key: "/organ/serviceManage",
        label: "服务项目管理",
      },
      {
        key: "/organ/customerManage",
        label: "客户列表",
      }
    ],
  },{
    key: "staffManage",
    label: "员工管理",
    icon: <AppstoreOutlined />,
    children: [
      {
        key: "/organ/staffManage",
        label: "员工管理",
      }
    ],
  }
];

// 企业/个人菜单
export const companyMenu = [
  {
    key: "dashboard",
    label: "首页",
    icon: <AppstoreOutlined />,
  },
  {
    key: "infoManage",
    label: "信息管理",
    icon: <AppstoreOutlined />,
    children: [
      {
        key: "/taxpayer/infoManage/taxpayerInfo",
        label: "我的信息",
      },
      {
        key: "/taxpayer/infoManage/unitInfo",
        label: "单位信息",
      },
    ],
  },
  {
    key: "invoiceManage",
    label: "发票申请",
    icon: <AppstoreOutlined />,
    children: [
      {
        key: "/taxpayer/invoiceManage",
        label: "发票申请",
      },
      {
        key: "/taxpayer/invoiceManage/invoiceDownload",
        label: "发票下载",
      },
    ],
  },
  {
    key: "fourStreamManage",
    label: "四流管理",
    icon: <AppstoreOutlined />,
    children: [
      {
        key: "/taxpayer/fourStreamManage/contractManage",
        label: "合同管理",
      },
      {
        key: "/taxpayer/fourStreamManage/transactionManage",
        label: "业务管理",
      },
      {
        key: "/taxpayer/fourStreamManage/invoiceManage",
        label: "票务管理",
      },
      {
        key: "/taxpayer/fourStreamManage/cashflowManage",
        label: "现金流管理",
      },
    ],
  },
  {
    key: "staffManage",
    label: "员工管理",
    icon: <AppstoreOutlined />,
    children: [{ key: "/taxpayer/staffManage/staffManage", label: "员工管理" }],
  },
  {
    key: "cooperativeManage",
    label: "合作社管理",
    icon: <AppstoreOutlined />,
    children: [
      {
        key: "/taxpayer/cooperative/cooperativeManage/1",
        label: "个体户邀请",
      },
      {
        key: "/taxpayer/cooperative/cooperativeManage/3",
        label: "个体户列表",
      },
      {
        key: "/taxpayer/cooperative/cooperativeManage/0",
        label: "邀请记录",
      },
      {
        key: "/taxpayer/cooperative/contractManage",
        label: "个体户合同",
      },
    ],
  },
];
