import { Button, message } from "antd";
import { useEffect, useState } from "react";
import { API_organList, API_bindMyBindOrgan } from "../../service/common";
import './chooseOrgan.less'
const ChooseOrgan = (props) => {
  const [organList, setOrganList] = useState([]);
  const [loading, setLoading] = useState(false)
  const { type } = props;
  useEffect(e => {
    API_organList({ onlyvalid: true }).then((res) => {
      setOrganList(res.data);
    });
  }, []);
  const choose = (data) => {
    setLoading(true)
    API_bindMyBindOrgan({ organid: data.organid, type: type }).then(
      (res) => {
        message.success("已申请");
        props.setShowChoose(false);
        setLoading(false);
      }
    ).catch(e => {
      setLoading(false);
    });
  }
  return (
    <div className="organList-content flex-start">
      {organList.map(data => {
        return (
          <div className="organ-item">
            <div className="organ-title">{data.organName}</div>
            <div className="organ-phone">联系电话:{data.phone}</div>
            <div className="organ-btn">
              <Button loading={loading} onClick={e => choose(data)} type="primary">
                申请
              </Button>
            </div>
          </div>
        );
      })}
    </div>
  )
}

export default ChooseOrgan;