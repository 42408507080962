import ajax from "../config/ajax";

//获取申请列表
export const API_getCooperList = (data) => ajax.get("/Cooperative/GetInvitations", data);


//获取合作社信息

export const API_getCooperative = (data) => ajax.get("Cooperative/GetCooperative", data)

//获取自己合作社信息

export const API_getMyCooperative = (data) => ajax.get("Cooperative/GetOneselfCooperative", data)

//创建合作社

export const API_createCooperative = (data) => ajax.post("Cooperative/ApplyInput", data)


//手机号搜索用户

export const API_searchUserByPhone = (data) => ajax.get("UserLogin/GetUserByPhone", data)


//邀请个体户

export const API_invitePersonToCooperative = (data) => ajax.post("Cooperative/MembersInvitation", data)

//绑定机构申请

export const API_approveBindOrgan = (data) => ajax.post("Cooperative/ApplyRequest", data)


//机构获取合作社申请

export const API_organGetCooperativeApply = (data) => ajax.get("Cooperative/GetCooperatives", data)

//合作社申请确认

export const API_organApproveCooperativeApply = (data) => ajax.post("Cooperative/ApplyHandle", data)


