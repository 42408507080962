import './fileContent.less'
import { useEffect, useState } from "react"
import { getFileType } from "../config/utils"
import { FilePdfFilled, FileTextFilled,FileZipFilled, FileWordFilled, FileExcelFilled, DeleteOutlined, CloudDownloadOutlined,FileUnknownFilled } from '@ant-design/icons';
import { Image } from 'antd';
const FileContent = (props) => {
    const [FileType, setFileType] = useState("");
    useEffect(e => {
        setFileType(getFileType(props.url))
    }, [props.url])
    return <div className="file_content" style={{ width: props.width || '50px', height: props.width || '50px' }}>
        {FileType === 'pdf' && <FilePdfFilled style={{ fontSize: "30px", color: 'orange' }} />}
        {FileType === 'txt' && <FileTextFilled style={{ fontSize: "30px" }} />}
        {FileType === 'word' && <FileWordFilled style={{ fontSize: "30px", color: 'blue' }} />}
        {FileType === 'excel' && <FileExcelFilled style={{ fontSize: "30px", color: 'green' }} />}
        {FileType === 'rar' && <FileZipFilled style={{ fontSize: "30px", color: 'green' }} />}
        {FileType === 'other' && <FileUnknownFilled style={{ fontSize: "30px", color: 'green' }} />}
        {FileType === 'image' ? <Image className='file_image' src={props.url} alt="" /> : <div className='file_name line_2'>{props.name}</div>}
        <div className='file_mask'>
            {props.onRemove&&<DeleteOutlined onClick={() => props.onRemove(props.index)} />}
            <CloudDownloadOutlined onClick={() => window.open(props.url)} />
        </div>
    </div>
}

export default FileContent